import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 920.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,920.000000) scale(0.100000,-0.100000)">
 
<path d="M5195 7639 c-226 -23 -499 -81 -457 -96 6 -3 10 -9 7 -14 -4 -5 1 -6
9 -3 9 3 13 10 10 15 -3 5 4 9 15 9 12 0 21 4 21 8 0 5 15 9 33 10 19 1 46 6
61 12 37 14 69 13 64 -2 -3 -7 -28 -16 -57 -19 -95 -12 -121 -19 -121 -34 0
-12 17 -15 73 -17 76 -2 77 -2 172 6 l60 6 -69 -19 c-49 -14 -76 -17 -93 -10
-34 13 -106 12 -98 -2 4 -6 19 -10 33 -9 49 4 48 -1 -3 -15 -27 -7 -52 -11
-54 -9 -2 2 1 4 7 4 7 0 12 5 12 10 0 6 -10 8 -23 5 -16 -4 -29 0 -41 14 -11
12 -29 21 -42 21 -13 0 -26 5 -29 10 -3 6 3 10 14 10 12 0 21 5 21 11 0 6 -6
9 -14 6 -8 -3 -32 -11 -53 -16 -44 -12 -150 -55 -160 -65 -12 -12 22 -5 47 9
14 8 33 14 43 15 10 0 26 7 35 16 15 15 17 15 30 -5 8 -11 21 -21 28 -21 8 0
14 -4 14 -10 0 -5 -9 -10 -21 -10 -14 0 -19 -5 -16 -14 4 -10 -6 -16 -35 -20
-22 -4 -43 -4 -46 -1 -3 3 5 5 18 5 30 0 15 15 -22 24 -16 4 -28 2 -28 -3 0
-6 -24 -8 -57 -6 -54 5 -64 1 -180 -57 -68 -34 -123 -65 -123 -69 0 -9 48 8
65 23 5 4 15 8 22 8 8 0 23 9 35 20 23 21 44 27 33 10 -3 -6 4 -10 17 -10 18
0 20 2 8 10 -28 17 -1 23 34 6 19 -9 42 -16 53 -17 17 0 17 -1 -1 -8 -14 -5
-16 -10 -8 -15 7 -4 20 -2 29 3 10 6 14 11 8 11 -12 2 87 38 135 50 19 5 46
12 59 16 15 4 22 3 18 -3 -3 -5 -31 -16 -62 -23 -31 -7 -58 -17 -61 -22 -3 -4
-13 -8 -23 -8 -10 0 -24 -7 -31 -15 -7 -8 -26 -19 -44 -24 -17 -5 -40 -12 -51
-17 -17 -7 -18 -5 -8 8 15 17 7 29 -13 21 -8 -3 -21 0 -29 7 -8 6 -22 10 -32
7 -14 -4 -13 -5 5 -6 12 -1 22 -7 22 -16 0 -18 -37 -20 -42 -3 -4 10 -15 9
-49 -2 -24 -7 -49 -18 -56 -23 -7 -5 -22 -6 -34 -2 -19 6 -83 -17 -99 -36 -3
-3 -30 -23 -60 -44 -30 -20 -47 -35 -38 -32 12 4 15 2 11 -8 -3 -10 -13 -13
-27 -9 -16 4 -34 -6 -66 -35 -25 -23 -51 -41 -59 -41 -8 0 -11 -6 -7 -16 3 -9
6 -20 6 -25 0 -15 42 -10 62 8 10 9 18 11 18 5 0 -6 -5 -14 -10 -17 -6 -4 -8
-11 -5 -16 3 -5 -1 -6 -10 -3 -9 4 -23 -3 -36 -17 l-21 -24 5 23 c6 24 1 23
-50 -15 -18 -13 -33 -21 -33 -17 0 3 -7 1 -15 -6 -9 -7 -15 -8 -15 -2 0 6 24
31 53 56 28 25 48 46 42 46 -5 0 -37 -25 -69 -54 -54 -49 -57 -54 -38 -61 22
-6 31 -25 12 -25 -5 0 -10 4 -10 9 0 12 -39 21 -45 11 -8 -12 6 -41 16 -34 5
3 9 0 9 -5 0 -6 -6 -11 -14 -11 -21 0 -31 -21 -16 -30 7 -5 20 -6 29 -3 10 4
7 -3 -8 -18 -16 -16 -25 -20 -28 -11 -8 21 -43 14 -43 -8 0 -11 6 -20 13 -20
9 0 9 -2 -1 -9 -18 -11 -17 -22 3 -34 14 -8 12 -14 -22 -48 -44 -45 -63 -49
-63 -13 0 21 4 24 20 19 19 -6 50 13 50 31 0 12 -34 33 -53 34 -17 0 -17 1 -2
17 14 14 18 14 27 2 7 -10 8 -8 3 8 -5 18 3 31 37 62 23 22 40 43 36 46 -9 10
-153 -138 -150 -153 2 -8 -1 -11 -6 -8 -19 11 -156 -165 -158 -203 -1 -14 -5
-37 -8 -51 -6 -22 -5 -23 4 -5 l10 20 -4 -20 c-10 -42 -32 -92 -53 -119 l-23
-29 0 28 c0 15 11 40 25 57 25 30 32 48 17 48 -4 0 -13 -5 -20 -12 -9 -9 -12
-9 -12 0 0 7 4 12 9 12 10 0 54 70 48 76 -8 9 -127 -165 -127 -185 0 -6 -11
-22 -24 -36 -14 -15 -40 -57 -58 -93 -19 -37 -48 -94 -65 -127 -17 -33 -42
-88 -56 -122 l-26 -63 45 -20 c25 -12 53 -18 63 -15 10 3 40 53 73 123 51 108
55 119 43 147 -17 43 -7 64 15 31 l17 -27 42 73 c24 40 45 90 48 111 3 20 10
37 14 37 5 0 9 8 9 19 0 17 59 85 67 77 2 -2 -10 -19 -26 -37 -17 -18 -31 -41
-31 -50 0 -10 -6 -19 -12 -22 -7 -2 -10 -7 -7 -11 4 -4 27 21 52 54 39 53 44
66 41 101 -2 26 0 39 9 39 6 0 17 11 24 25 14 31 7 32 -25 3 -20 -18 -24 -19
-19 -6 3 10 2 20 -3 23 -16 10 -1 48 32 84 18 20 34 38 36 40 1 3 8 -1 14 -7
10 -10 7 -17 -13 -35 -14 -12 -33 -34 -42 -49 l-17 -28 32 6 c18 3 38 7 45 9
6 2 14 -1 16 -8 3 -7 20 5 42 28 149 161 309 309 390 361 17 10 32 21 35 24
13 16 121 90 175 120 33 19 62 36 65 39 6 7 105 55 205 100 39 18 78 36 87 41
35 19 249 83 388 116 188 44 471 62 699 45 89 -7 147 -8 150 -2 3 5 27 8 54 6
26 -1 47 1 47 5 0 9 45 17 145 24 l70 5 -46 -13 c-25 -7 -54 -18 -65 -24 -18
-11 -18 -11 4 -12 12 0 22 5 22 10 0 6 12 10 26 10 14 0 33 4 43 10 21 11 93
2 85 -11 -3 -5 1 -9 9 -9 8 0 20 -6 26 -14 9 -11 6 -15 -14 -18 -14 -3 -25 -2
-25 2 0 18 -56 30 -100 22 -45 -9 -45 -9 -17 -20 28 -12 37 -32 13 -32 -7 0
-28 3 -45 6 -29 6 -30 8 -14 20 16 12 13 13 -24 14 -24 0 -43 5 -43 10 0 6 -9
10 -21 10 -12 0 -17 -4 -13 -11 4 -7 -12 -9 -52 -5 -68 6 -54 1 109 -37 102
-25 144 -26 131 -6 -7 13 63 11 87 -2 11 -5 17 -15 13 -21 -5 -7 5 -9 32 -5
21 4 33 4 27 1 -28 -12 -12 -26 23 -21 42 7 78 -8 70 -28 -6 -18 17 -25 72
-21 23 1 42 -1 42 -6 0 -4 -9 -8 -20 -8 -11 0 -20 -4 -20 -10 0 -12 7 -12 48
-1 33 10 41 4 20 -17 -9 -9 -9 -12 0 -12 7 0 12 5 12 11 0 5 5 7 10 4 15 -9
12 -35 -4 -35 -7 0 -19 7 -26 15 -7 8 -21 15 -30 15 -10 0 -31 7 -47 15 -88
46 -225 96 -278 101 -47 4 -43 3 55 -27 41 -13 77 -26 80 -29 3 -4 18 -10 35
-14 41 -10 301 -131 315 -147 3 -3 31 -20 64 -38 32 -18 64 -37 70 -42 6 -5
50 -37 99 -71 48 -33 99 -72 113 -85 16 -14 30 -21 35 -16 10 10 -134 123
-157 123 -8 0 -14 4 -14 9 0 15 54 7 67 -10 7 -9 24 -20 39 -23 14 -4 24 -9
21 -12 -8 -8 34 -54 50 -54 7 0 18 -9 25 -19 6 -10 16 -17 22 -15 6 2 12 -7
12 -22 l2 -26 -24 23 c-13 12 -27 19 -31 15 -4 -4 14 -25 40 -47 101 -86 272
-270 334 -358 1 -2 9 2 18 9 12 10 18 10 31 -1 13 -11 17 -10 26 4 5 10 13 15
19 12 5 -3 6 1 3 9 -3 9 -2 16 4 16 10 0 22 -23 22 -41 0 -5 -8 -9 -18 -9 -28
0 -53 -60 -38 -88 7 -13 14 -20 17 -17 3 3 14 -4 24 -15 11 -12 13 -20 6 -20
-12 0 14 -51 52 -101 18 -24 66 -114 108 -199 48 -100 79 -170 79 -178 0 -4 6
-16 13 -26 12 -16 15 -16 60 3 26 11 47 25 47 30 0 12 -24 4 -57 -17 -24 -15
-43 -9 -43 13 0 8 -7 30 -16 49 -10 19 -14 42 -10 53 7 16 9 16 26 -7 11 -14
20 -36 20 -50 0 -14 5 -32 11 -40 8 -11 9 -7 5 18 -3 18 -1 32 4 32 6 0 10 -9
10 -20 0 -11 5 -20 10 -20 17 0 11 35 -10 58 -21 22 -26 42 -11 42 13 0 32
-26 40 -56 4 -15 12 -29 19 -32 9 -3 -64 195 -78 208 -3 3 -18 30 -34 60 -16
30 -36 63 -44 73 -9 10 -17 24 -18 31 -3 20 -52 102 -69 116 -8 7 -15 17 -15
22 0 5 -11 24 -25 42 -14 18 -25 38 -25 44 0 7 -3 12 -7 12 -5 0 -17 14 -28
30 -24 37 -28 37 -59 4 -13 -15 -29 -23 -35 -19 -13 7 31 55 50 55 14 0 -41
70 -138 178 -18 19 -23 21 -23 8 0 -9 5 -16 10 -16 6 0 13 -8 17 -17 3 -10 14
-27 25 -39 18 -20 18 -21 -12 -21 -32 0 -37 6 -20 23 8 8 6 15 -5 24 -11 9
-15 9 -15 1 0 -9 -5 -9 -22 0 l-21 12 21 -36 c11 -20 15 -35 9 -33 -7 1 -9 1
-4 -1 10 -7 9 -33 -3 -33 -12 0 -33 38 -34 65 -1 11 -7 19 -14 17 -7 -1 -12 9
-12 23 0 14 5 25 10 25 6 0 10 -4 10 -10 0 -5 5 -10 10 -10 6 0 10 7 10 15 0
19 -28 31 -41 18 -7 -7 -21 -1 -42 18 -28 26 -32 27 -39 12 -6 -17 -7 -17 -7
0 -1 10 -9 17 -23 18 l-23 1 23 6 23 7 -29 32 c-17 18 -26 36 -22 43 9 14 47
17 75 5 12 -4 6 4 -14 21 -18 16 -52 45 -75 64 -65 55 -159 126 -178 133 -10
3 -18 11 -18 17 0 5 -5 10 -11 10 -6 0 -25 11 -43 25 -33 25 -56 32 -56 17 0
-7 28 -24 60 -37 8 -3 23 -20 33 -36 12 -23 22 -29 36 -24 24 7 35 -9 17 -24
-10 -8 -16 -9 -21 -1 -10 16 -29 12 -53 -10 -19 -18 -22 -18 -36 -4 -23 23
-20 27 14 21 22 -5 30 -3 30 8 0 24 -33 55 -60 56 -22 0 -23 1 -6 8 15 6 16
10 7 15 -7 5 -18 3 -24 -3 -18 -18 -37 -13 -49 14 -11 24 -48 35 -48 14 0 -5
-4 -7 -10 -4 -17 10 -11 21 15 33 33 15 35 15 35 -3 0 -16 27 -20 49 -6 14 9
8 13 -191 113 -102 51 -184 88 -194 88 -13 0 -1 -19 14 -23 9 -2 1 -4 -19 -3
-32 0 -35 2 -22 15 14 13 13 16 -7 23 -13 5 -37 15 -54 22 -17 8 -71 26 -119
41 -72 21 -94 24 -120 16 -21 -7 -54 -7 -96 -1 -95 13 -88 23 14 17 100 -7 82
2 -60 28 -219 41 -486 54 -680 34z m175 -8 c-41 -3 -68 -9 -60 -12 8 -3 60 -3
115 1 55 4 91 8 81 8 -11 1 -17 5 -13 8 3 3 15 3 28 -1 17 -6 19 -9 8 -16 -10
-6 -5 -9 16 -10 l30 -1 -30 -8 c-16 -4 -48 -7 -70 -7 l-40 0 45 8 c28 4 -11 6
-100 5 -85 -2 -135 0 -120 5 37 12 -31 4 -97 -12 -37 -9 -58 -10 -75 -2 l-23
11 25 5 c51 12 197 24 275 24 l80 -1 -75 -5z m357 -17 c53 -4 86 -11 94 -21
11 -12 4 -14 -45 -12 -38 2 -60 7 -65 17 -5 8 -15 13 -23 10 -8 -3 -43 0 -79
7 -60 13 -61 14 -14 10 28 -2 87 -7 132 -11z m-607 -53 c0 -4 -30 -11 -67 -15
-39 -4 -75 -14 -85 -23 -12 -11 -25 -13 -45 -8 l-28 8 33 9 c17 5 32 13 32 18
0 5 33 11 73 13 39 1 75 4 80 5 4 1 7 -2 7 -7z m315 -11 c4 -6 -23 -10 -74
-10 -47 0 -81 4 -81 10 0 6 31 10 74 10 41 0 78 -4 81 -10z m287 -16 c15 -3
31 -10 34 -15 5 -9 -76 -5 -136 7 -8 1 -35 3 -60 3 -34 1 -41 4 -30 11 14 10
128 6 192 -6z m-857 -4 c3 -5 -3 -10 -15 -10 -12 0 -18 5 -15 10 3 6 10 10 15
10 5 0 12 -4 15 -10z m305 0 c-20 -5 -39 -5 -44 1 -6 5 7 9 35 8 l44 -1 -35
-8z m765 -50 c13 -6 15 -9 5 -9 -8 0 -22 4 -30 9 -18 12 -2 12 25 0z m334 4
c32 -7 43 -24 15 -24 -14 0 -14 -3 -4 -15 11 -13 10 -14 -11 -6 -13 5 -23 15
-21 24 1 9 -5 18 -15 20 -28 7 1 8 36 1z m-1536 -51 c-7 -2 -19 -2 -25 0 -7 3
-2 5 12 5 14 0 19 -2 13 -5z m-308 -10 c-11 -3 -23 -10 -27 -17 -5 -7 -8 -7
-8 0 0 16 11 24 34 23 20 -1 20 -1 1 -6z m75 -9 c0 -8 -5 -12 -10 -9 -6 4 -8
11 -5 16 9 14 15 11 15 -7z m1925 6 c3 -5 18 -10 33 -11 l27 -1 -29 -8 c-16
-5 -33 -14 -37 -21 -5 -8 -13 -6 -29 9 -21 19 -21 21 -3 31 23 13 30 13 38 1z
m88 -44 c22 -17 22 -33 -1 -45 -14 -7 -21 -5 -30 9 -9 14 -8 20 1 23 7 3 -1 6
-18 7 -30 2 -30 2 -5 10 34 11 33 11 53 -4z m105 -52 c40 -19 49 -34 23 -34
-12 0 -21 -4 -21 -10 0 -5 -7 -10 -15 -10 -21 0 -19 19 3 28 13 5 11 8 -12 12
-16 3 -31 11 -34 18 -5 17 19 15 56 -4z m-2294 -30 c26 -10 19 -22 -20 -34
-20 -7 -61 -29 -92 -50 -32 -20 -68 -43 -82 -50 -14 -7 -29 -19 -33 -27 -8
-12 -12 -12 -27 2 -15 14 -22 14 -40 5 -14 -7 -26 -8 -31 -3 -11 11 -1 45 10
39 4 -3 13 0 21 5 12 8 12 12 2 16 -14 6 16 32 61 51 34 15 47 15 47 2 0 -5
-6 -10 -14 -10 -7 0 -19 -7 -26 -15 -7 -8 -16 -13 -20 -10 -5 3 -11 1 -15 -5
-3 -5 -1 -10 5 -10 6 0 8 -6 4 -12 -6 -10 -4 -10 7 0 7 6 22 11 34 11 16 -1
17 -2 3 -6 -10 -2 -18 -9 -18 -14 0 -17 21 -9 44 16 13 14 28 25 33 25 6 0 16
7 23 15 7 8 23 15 36 15 25 0 33 15 12 23 -7 3 3 4 22 1 19 -2 29 -1 21 2 -11
5 -11 8 0 15 17 11 14 10 33 3z m2256 -9 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-2392 -13 c3 -9 -3 -13 -19 -10 -12 1
-24 9 -27 16 -3 9 3 13 19 10 12 -1 24 -9 27 -16z m2429 -34 c43 -23 88 -47
100 -52 13 -6 23 -17 23 -23 0 -17 -2 -17 -56 12 -26 14 -53 25 -60 25 -8 0
-14 6 -14 14 0 7 -15 19 -32 27 -18 7 -42 19 -53 26 -16 11 -17 13 -3 13 10 0
52 -19 95 -42z m-2667 -93 c-10 -12 -10 -15 4 -15 9 0 16 -4 16 -10 0 -5 -12
-10 -26 -10 -14 0 -23 4 -19 10 3 6 0 10 -7 10 -8 1 -6 6 6 15 26 19 42 19 26
0z m2980 -25 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-3030 -35 c-7 -8 -22 -15 -33 -15 -19 0 -19 1 4 23 26 25 51 18
29 -8z m167 29 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-37
-19 c0 -8 -7 -15 -16 -15 -14 0 -14 3 -4 15 7 8 14 15 16 15 2 0 4 -7 4 -15z
m2994 -42 l21 -13 -22 0 c-13 0 -32 9 -43 20 -26 26 -25 34 1 18 12 -7 31 -19
43 -25z m-3084 -28 c-7 -8 -25 -21 -41 -29 -25 -13 -29 -13 -29 0 0 8 8 17 18
20 9 3 24 9 32 14 24 14 34 11 20 -5z m3132 -19 c17 -23 17 -24 -4 -30 -26 -6
-38 2 -19 13 9 6 10 11 2 15 -12 8 -15 26 -3 26 4 0 15 -11 24 -24z m-3219
-41 c-10 -16 -161 -155 -169 -155 -4 0 5 15 20 33 15 17 33 46 41 63 12 26 19
31 39 27 21 -4 29 1 43 25 10 17 21 28 25 26 5 -3 5 -11 1 -19z m-46 -11 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-270 -191 c-3 -10 -5 -4
-5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m133 27 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m3520 -5 c0 -8 -4 -15 -10 -15
-5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m203 -106 c22 -39 18 -45
-11 -19 -19 18 -22 18 -36 4 -20 -19 -20 -23 0 -45 9 -10 14 -26 12 -36 -2
-13 -11 -6 -33 29 -17 26 -36 47 -42 48 -7 0 -13 3 -13 8 1 19 18 29 48 27 18
-1 35 2 38 7 9 14 19 9 37 -23z m-3843 -21 c-1 -8 -6 -6 -15 6 -20 26 -19 42
0 22 8 -8 15 -21 15 -28z m-132 -56 c2 -7 -5 -12 -17 -12 -14 0 -19 5 -14 15
6 17 25 15 31 -3z m7 -32 c3 -5 -3 -10 -15 -10 -12 0 -18 5 -15 10 3 6 10 10
15 10 5 0 12 -4 15 -10z m-48 -47 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m4171 -53 c8 -19 23 -47 33 -62 11 -14 19 -34 18 -45 0 -15 -1 -15
-9 3 -5 12 -13 25 -19 29 -6 3 -20 26 -31 51 -25 55 -45 59 -37 7 5 -32 4 -35
-5 -18 -14 26 -28 34 -44 28 -6 -2 -15 0 -19 6 -3 6 0 11 8 11 8 0 20 6 26 14
6 8 23 13 38 12 20 -1 30 -9 41 -36z m-4281 -67 c1 -21 -4 -44 -9 -51 -8 -9
-7 -11 6 -6 12 4 16 1 16 -15 0 -15 5 -19 16 -14 14 5 14 3 3 -16 -8 -11 -17
-21 -21 -21 -5 0 -8 -6 -8 -12 0 -7 -8 -22 -17 -33 -15 -19 -15 -19 -9 5 15
54 17 82 7 94 -15 18 -5 106 13 106 1 0 3 -17 3 -37z m4254 -6 c-10 -9 -11 -8
-5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-26 4 c-3 -5 -1 -12 5 -16 5 -3 10
-11 10 -18 0 -7 -7 -5 -16 5 -14 14 -13 38 2 38 3 0 3 -4 -1 -9z m-4179 -45
c-8 -16 -17 -23 -20 -17 -4 6 1 22 11 35 22 30 28 19 9 -18z m4324 -28 c0 -4
-6 -5 -14 -2 -9 3 -19 -5 -28 -22 -14 -29 -38 -44 -38 -23 0 6 8 18 18 25 16
12 15 13 -5 14 -12 0 -23 -5 -25 -12 -3 -8 -6 -9 -11 -1 -3 5 0 19 8 29 12 16
17 17 35 6 19 -12 22 -11 27 9 6 21 7 21 20 4 7 -11 13 -22 13 -27z m-4410 7
c0 -6 -12 -21 -38 -51 -12 -12 -12 -17 -1 -31 12 -14 11 -15 -6 -10 -21 7 -25
-4 -26 -80 0 -37 -6 -41 -25 -15 -12 15 -15 15 -24 2 -5 -8 -10 -22 -10 -30 0
-13 1 -13 11 0 8 11 9 8 4 -13 -3 -18 -2 -26 4 -23 5 4 13 1 16 -5 4 -5 13 -8
20 -5 9 3 11 -2 8 -15 -7 -27 -18 -31 -33 -14 -18 21 -67 19 -73 -2 -3 -12 -5
-9 -6 8 0 15 6 32 14 39 8 7 15 21 15 31 0 9 7 25 16 34 9 8 29 41 44 73 15
32 35 63 43 71 9 7 13 19 10 27 -3 9 2 14 16 14 12 0 21 -2 21 -5z m4405 -36
c0 -8 10 -18 23 -24 12 -5 27 -24 33 -42 6 -18 18 -40 26 -49 8 -8 13 -18 10
-21 -9 -8 -37 23 -37 41 0 8 -5 18 -10 21 -6 4 -8 11 -5 16 4 5 -2 9 -12 9
-27 0 -41 12 -49 44 -5 22 -4 28 7 24 8 -3 14 -11 14 -19z m-30 -59 c-19 -7
-19 -7 1 -18 16 -8 20 -18 17 -37 -5 -24 -6 -24 -24 12 -14 27 -16 40 -8 50 6
7 16 10 22 6 6 -4 3 -9 -8 -13z m62 -88 c-10 -11 -15 -26 -11 -36 10 -25 22
-19 16 9 -3 18 0 25 12 25 25 0 22 -66 -3 -73 -15 -4 -24 4 -36 29 -15 32 -15
36 2 58 11 15 22 21 27 15 6 -5 3 -16 -7 -27z m-4457 -7 c0 -5 -5 -3 -10 5 -5
8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m4560 -69 c0 -9 -6 -13
-14 -10 -17 7 -28 32 -20 45 7 12 34 -15 34 -35z m-4655 -60 c22 -20 23 -25
10 -36 -8 -7 -15 -20 -15 -30 0 -25 -10 -31 -33 -18 -20 10 -20 11 2 28 22 16
22 18 6 34 -9 9 -14 23 -11 31 7 20 11 19 41 -9z m-62 -10 c8 -20 -3 -56 -17
-56 -21 0 -25 11 -14 41 11 31 23 37 31 15z"/>
<path d="M6400 7400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5948 7523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4580 7470 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M4465 7460 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5087 7225 c-88 -12 -164 -28 -170 -34 -6 -6 -23 -11 -38 -11 -36 0
-203 -56 -207 -69 -2 -6 -14 -11 -27 -11 -30 0 -78 -20 -202 -82 -62 -32 -98
-55 -90 -60 6 -4 14 -2 18 4 4 6 12 7 19 3 9 -5 11 -4 6 3 -4 7 -1 12 9 12 26
0 12 -23 -19 -31 -15 -4 -42 -19 -61 -33 -19 -14 -37 -26 -42 -27 -4 0 -28
-16 -53 -35 -81 -61 -89 -66 -107 -61 -17 4 -17 4 1 12 11 5 28 16 38 24 10 9
26 23 37 32 10 9 22 18 27 20 42 18 112 64 108 70 -5 9 -136 -68 -142 -83 -2
-4 -9 -8 -17 -8 -7 0 -19 -7 -26 -16 -8 -9 -30 -27 -49 -40 -19 -13 -46 -32
-60 -44 -23 -19 -24 -21 -7 -31 16 -9 14 -13 -20 -40 -21 -17 -43 -37 -50 -46
-6 -8 -18 -13 -25 -11 -8 3 3 19 30 43 43 40 51 55 30 55 -6 0 -8 -3 -5 -7 4
-3 -4 -14 -18 -23 -50 -33 -275 -264 -275 -282 0 -4 5 -8 10 -8 6 0 10 4 10 9
0 14 188 201 201 201 8 0 7 -5 -1 -15 -25 -30 -4 -24 30 9 19 18 58 50 85 70
28 21 53 43 56 48 12 19 204 148 283 191 43 23 91 48 105 56 14 8 49 24 76 36
28 11 51 26 53 33 3 17 -13 15 -28 -3 -7 -8 -19 -15 -27 -15 -8 0 -34 -11 -59
-25 -48 -27 -64 -31 -64 -15 0 6 4 10 9 10 5 0 35 15 68 33 67 37 122 58 134
51 4 -2 23 4 42 15 19 11 39 16 47 11 8 -5 -1 -12 -26 -20 -22 -7 -44 -16 -50
-21 -6 -5 -15 -5 -19 -1 -5 4 -5 2 -2 -4 5 -8 35 -3 102 19 76 26 90 33 73 40
-14 5 -24 3 -29 -4 -4 -8 -17 -9 -35 -4 -24 7 -26 9 -9 12 11 2 40 11 64 20
23 8 57 17 75 18 17 2 37 3 44 4 7 1 10 6 7 11 -3 6 1 10 9 10 21 0 9 -28 -15
-35 -10 -4 -28 -4 -38 0 -11 3 -22 1 -26 -5 -3 -5 -18 -10 -33 -11 -25 -1 -24
-2 7 -11 23 -6 42 -6 55 0 12 6 68 20 126 31 272 53 606 48 880 -15 41 -10 87
-17 102 -17 15 0 25 -4 22 -8 -3 -5 6 -9 19 -9 14 0 28 7 31 15 5 14 11 13 45
-5 22 -11 48 -20 58 -20 17 -1 17 -1 -1 -11 -11 -7 -21 -8 -25 -2 -8 13 -61
26 -61 15 0 -5 51 -29 112 -53 62 -24 117 -46 123 -48 5 -2 4 4 -4 13 -13 16
-12 17 13 10 43 -10 126 -53 126 -64 0 -15 -16 -12 -30 5 -11 13 -15 13 -21 3
-7 -10 -9 -9 -9 3 0 10 -11 20 -26 23 -31 8 -45 0 -18 -10 10 -4 26 -13 34
-20 8 -7 17 -12 20 -11 9 2 160 -87 160 -95 0 -4 5 -8 11 -8 6 0 26 -12 45
-26 19 -15 34 -22 34 -17 0 10 -46 53 -56 53 -17 0 -132 85 -127 94 4 6 8 6
10 -1 3 -7 21 -17 41 -24 41 -13 74 -38 67 -50 -3 -4 8 -10 24 -14 16 -3 39
-17 51 -31 13 -13 33 -24 44 -24 17 0 18 -2 6 -10 -8 -5 -24 -7 -35 -3 -11 3
5 -15 35 -40 30 -26 58 -47 61 -47 4 0 21 -13 39 -30 18 -16 42 -30 54 -29 14
0 16 2 6 6 -8 3 -25 15 -37 26 -13 11 -23 16 -23 11 0 -19 -20 22 -20 42 0 20
-30 52 -85 90 -109 75 -163 111 -175 117 -8 4 -46 25 -85 47 -38 23 -86 48
-105 56 -19 8 -44 19 -55 24 -61 28 -229 87 -325 114 -52 14 -191 39 -320 56
-122 16 -390 9 -553 -15z m273 -4 c0 -13 -138 -21 -190 -11 -37 7 -31 8 35 4
54 -3 74 -1 60 5 -15 7 -6 10 38 10 31 1 57 -3 57 -8z m143 2 c-13 -2 -33 -2
-45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m199 -5 c82 -9 97 -13 74 -19 -16 -5
-34 -5 -40 -1 -6 4 -45 8 -86 8 -82 2 -159 22 -88 23 21 1 84 -4 140 -11z
m-623 -24 c8 -7 -52 -24 -87 -24 l-37 0 35 19 c30 15 74 18 89 5z m64 -1 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m707 -4 c-12 -8 -9 -9 12
-5 15 3 30 1 33 -4 4 -6 15 -8 25 -5 11 3 30 0 42 -7 13 -7 34 -14 47 -15 13
-1 29 -7 35 -14 8 -9 5 -10 -14 -5 -14 3 -35 6 -48 6 -13 0 -20 4 -17 10 3 6
-10 9 -32 9 -21 0 -65 6 -98 14 -58 14 -59 15 -25 19 19 3 40 5 45 6 6 1 3 -3
-5 -9z m-973 -55 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1396
-75 c37 -11 67 -22 67 -24 0 -3 -62 2 -97 7 -7 1 -10 5 -7 10 3 4 -6 8 -20 8
-14 0 -28 5 -31 10 -9 15 15 12 88 -11z m475 -289 c17 -11 32 -24 32 -30 0
-16 -13 -11 -50 20 -40 34 -30 39 18 10z m-2656 -10 c-13 -11 -21 -24 -17 -30
7 -12 3 -12 -20 -4 -14 6 -13 9 6 30 12 13 30 24 38 24 12 -1 10 -5 -7 -20z"/>
<path d="M9280 6893 c-30 -47 -60 -87 -67 -90 -7 -3 -13 -10 -13 -15 0 -6 -12
-25 -27 -43 -16 -18 -41 -50 -58 -71 -92 -117 -268 -302 -393 -413 -60 -53
-209 -181 -227 -194 -5 -4 -28 -21 -50 -37 -22 -16 -55 -42 -74 -57 -18 -16
-41 -32 -50 -38 -42 -25 -130 -98 -135 -111 -3 -9 -12 -14 -19 -11 -13 5 -67
-23 -67 -35 0 -13 17 -9 30 7 17 20 31 19 23 -2 -5 -12 -3 -14 8 -8 12 8 12 6
0 -8 -7 -9 -22 -17 -32 -17 -10 0 -25 -13 -34 -28 -14 -23 -23 -27 -46 -24
-17 3 -29 0 -29 -7 0 -6 -12 -11 -27 -10 l-28 0 25 10 c13 6 39 21 57 34 17
14 37 25 44 25 9 0 10 3 2 11 -7 7 -28 -2 -69 -30 -33 -22 -66 -46 -74 -53 -8
-7 -26 -19 -40 -27 -56 -31 -67 -37 -100 -62 -19 -14 -55 -37 -80 -50 -25 -13
-51 -29 -57 -36 -7 -6 -28 -21 -48 -33 -19 -12 -35 -23 -35 -25 0 -8 47 9 63
23 9 8 20 12 24 8 8 -8 -67 -72 -80 -68 -5 1 -6 -8 -2 -20 4 -17 4 -19 -3 -9
-6 9 -17 11 -28 6 -18 -7 -18 -8 1 -16 15 -7 11 -7 -12 -3 -29 5 -33 3 -33
-16 0 -12 -4 -18 -10 -15 -5 3 -10 -3 -10 -14 0 -11 5 -23 10 -26 15 -9 12
-35 -4 -35 -18 0 -26 46 -14 81 5 15 27 39 50 54 22 15 38 29 35 32 -9 8 -107
-60 -107 -74 0 -7 -7 -13 -16 -13 -14 0 -74 -41 -110 -75 -6 -5 -27 -24 -47
-42 -42 -36 -77 -89 -77 -115 0 -10 -5 -18 -10 -18 -11 0 -7 -94 8 -215 6 -52
10 -60 30 -63 28 -4 37 -24 24 -49 -9 -16 -11 -15 -17 10 -4 15 -11 27 -16 27
-5 0 -9 -2 -9 -4 0 -10 54 -151 59 -156 3 -3 19 -30 35 -60 16 -30 49 -78 73
-107 23 -28 43 -56 43 -62 0 -6 4 -11 9 -11 5 0 18 -22 30 -50 12 -27 24 -50
26 -50 8 0 -6 53 -16 65 -12 12 -12 35 0 35 13 0 24 -40 25 -98 1 -29 6 -56
12 -60 5 -4 25 -32 44 -62 39 -62 133 -160 154 -160 8 0 16 -5 18 -11 3 -10
108 -23 108 -13 0 3 -9 15 -20 26 -14 16 -17 25 -9 32 7 8 13 6 20 -6 13 -24
40 -50 46 -44 3 2 -18 33 -47 67 -29 34 -57 73 -63 86 -8 17 -13 20 -19 11 -5
-9 -8 -6 -8 9 0 12 -10 43 -21 70 -12 26 -27 72 -34 101 -17 71 3 70 44 -1 30
-53 71 -99 71 -81 0 6 -6 16 -12 23 -11 10 -10 14 4 22 14 7 15 15 6 45 -5 20
-7 38 -4 42 3 3 6 -2 6 -11 0 -9 6 -20 13 -24 16 -10 47 -67 47 -86 0 -8 -7
-17 -15 -21 -15 -5 -19 8 -15 48 1 11 -3 16 -9 11 -7 -4 -9 -20 -5 -42 5 -27
4 -34 -5 -28 -10 5 -9 2 0 -13 7 -12 19 -18 27 -14 10 3 13 1 8 -6 -8 -13 48
-75 77 -85 9 -4 17 -11 17 -17 0 -5 11 -10 25 -10 14 0 25 -6 25 -14 0 -38
202 -63 271 -33 l34 15 -32 1 c-18 1 -33 -3 -33 -9 0 -5 -22 -10 -50 -10 -59
0 -152 19 -145 29 2 4 -9 17 -25 29 -17 13 -31 18 -35 12 -4 -7 -14 -6 -28 2
-21 11 -21 12 11 20 25 7 32 14 32 33 0 28 -36 75 -58 75 -27 0 -5 25 25 28
48 5 49 29 3 73 -22 21 -40 41 -40 44 0 3 -8 13 -17 23 -10 9 -28 31 -41 47
-13 17 -20 23 -16 15 4 -8 3 -12 -1 -7 -11 9 -18 84 -10 105 5 13 8 13 23 -2
10 -9 25 -16 33 -16 12 0 11 -2 -1 -10 -13 -9 -13 -11 0 -19 8 -6 11 -13 7
-17 -4 -4 -15 0 -25 9 -21 19 -32 22 -32 7 0 -5 6 -10 14 -10 8 0 24 -12 36
-27 19 -25 20 -26 15 -5 -5 18 -2 22 14 22 22 0 29 -26 10 -32 -7 -2 -2 -14
12 -29 40 -42 243 -188 262 -189 9 0 17 -4 17 -9 0 -9 36 -28 163 -85 21 -10
45 -15 55 -13 12 3 8 8 -15 17 -18 6 -33 16 -33 21 0 5 -13 9 -28 9 -16 0 -37
7 -48 15 -10 8 -27 15 -36 15 -10 0 -18 5 -18 10 0 6 -7 10 -15 10 -8 0 -15 7
-15 16 0 14 3 14 15 4 32 -27 107 -59 122 -53 14 6 14 7 -3 14 -17 7 -17 8 4
8 12 1 22 -5 22 -12 0 -14 40 -30 120 -47 58 -12 56 -13 74 6 26 25 19 34 -29
34 -25 0 -45 5 -45 10 0 6 9 10 19 10 11 0 23 5 26 10 10 16 35 12 42 -7 11
-29 25 -32 44 -10 14 18 20 20 30 10 11 -11 8 -13 -11 -13 -17 0 -21 -3 -13
-11 18 -18 52 6 45 33 -4 17 -2 20 12 15 10 -4 20 -7 22 -7 2 0 4 -5 4 -11 0
-5 -5 -7 -10 -4 -6 4 -9 1 -8 -6 5 -19 -73 -68 -109 -69 -18 0 -33 -5 -33 -11
0 -8 -16 -10 -50 -6 -31 4 -52 2 -56 -5 -5 -8 18 -9 81 -5 72 5 95 11 125 32
40 28 96 83 89 88 -7 5 -115 37 -125 37 -5 -1 4 -8 21 -17 27 -15 28 -17 10
-23 -12 -4 -25 -1 -33 7 -7 7 -22 13 -32 13 -24 0 -35 17 -21 34 16 19 -28 30
-64 16 -15 -6 -37 -7 -51 -4 -14 4 -24 2 -24 -5 0 -6 5 -11 11 -11 6 0 9 -6 7
-12 -5 -14 -20 -15 -60 -4 -26 8 -38 30 -38 72 0 12 -10 25 -22 31 -22 10 -22
11 -4 18 12 5 21 3 27 -6 4 -8 15 -23 23 -34 13 -16 15 -17 10 -2 -6 18 10 24
21 7 3 -5 18 -10 33 -11 23 -1 22 -2 -10 -11 -21 -6 -38 -14 -38 -19 0 -13 21
-11 51 5 16 8 32 10 40 5 19 -12 29 -11 29 1 0 6 -5 10 -11 10 -12 0 -221 101
-229 110 -3 4 -33 22 -67 41 -35 18 -63 37 -63 41 0 5 -6 8 -12 8 -7 0 -24 9
-38 20 -33 26 -48 26 -25 0 24 -27 3 -25 -49 3 -39 22 -59 47 -36 47 6 0 9 -4
6 -9 -6 -9 43 -26 51 -18 5 6 -56 57 -68 57 -5 0 -9 -5 -9 -10 0 -6 -15 -3
-32 6 -22 12 -28 19 -18 22 7 3 16 0 18 -6 2 -7 11 -12 19 -12 14 0 13 3 -2
20 -10 12 -25 18 -33 15 -8 -3 -12 0 -10 7 6 16 107 21 124 7 8 -7 14 -6 19 1
9 15 25 12 25 -5 0 -8 7 -15 15 -15 8 0 15 7 15 15 0 17 45 21 55 5 3 -5 -3
-10 -14 -10 -14 0 -19 -5 -15 -15 5 -11 -3 -14 -34 -12 -30 1 -38 5 -34 16 4
9 1 12 -7 7 -7 -5 -26 -2 -42 4 -47 20 -45 4 3 -18 24 -11 99 -47 168 -80 171
-83 308 -135 336 -128 12 3 30 9 40 13 12 5 15 3 9 -6 -6 -10 0 -12 25 -6 20
4 30 3 27 -2 -4 -6 4 -8 18 -5 14 2 41 1 60 -2 27 -5 35 -12 35 -28 0 -14 -5
-18 -16 -14 -9 3 -25 6 -35 6 -11 0 -18 4 -14 9 8 14 -15 25 -27 13 -7 -7 -25
-6 -57 4 -53 16 -69 18 -56 6 6 -4 37 -16 70 -26 33 -9 74 -22 91 -27 49 -15
227 -16 257 -1 15 6 37 17 50 23 32 15 58 38 90 82 31 40 33 72 4 43 -9 -10
-17 -13 -17 -7 0 6 -12 12 -27 12 -25 2 -24 2 7 9 28 6 21 8 -37 9 -39 0 -75
6 -78 11 -3 6 -24 10 -46 10 -22 0 -38 4 -35 9 7 10 127 3 139 -9 5 -4 28 -5
53 -2 25 2 50 0 57 -7 13 -10 27 -6 27 9 0 4 -26 8 -57 9 -32 0 -71 4 -87 7
-16 3 -40 8 -55 9 -51 6 -215 36 -223 41 -4 3 -8 1 -8 -5 0 -5 21 -14 48 -20
26 -5 58 -15 72 -20 20 -9 11 -10 -39 -5 -41 4 -69 12 -81 24 -10 10 -16 20
-14 23 2 2 -31 12 -73 21 -93 20 -138 33 -301 86 -75 25 -132 38 -146 34 -13
-3 -27 -1 -30 5 -5 7 0 8 11 4 12 -4 15 -3 8 4 -5 6 -35 19 -65 28 -30 10 -65
25 -77 32 -12 8 -28 11 -34 7 -8 -4 -9 -3 -5 4 4 8 0 12 -11 12 -17 1 -63 32
-63 44 0 11 27 6 56 -9 35 -18 83 -19 106 -1 16 11 12 15 -34 39 -56 29 -56
30 15 27 28 -1 47 3 47 9 0 6 11 11 25 11 14 0 25 -4 25 -10 0 -5 -11 -10 -24
-10 -19 0 -24 -5 -22 -25 1 -17 6 -23 15 -20 20 8 112 -6 106 -16 -3 -4 -28
-5 -55 -2 -34 4 -50 2 -50 -6 0 -6 -7 -11 -17 -11 -9 0 -14 -3 -10 -6 8 -8
155 -36 190 -37 17 0 24 -3 17 -7 -7 -5 11 -11 41 -15 30 -4 79 -13 109 -21
30 -8 71 -14 92 -14 20 0 40 -4 43 -10 8 -13 25 -13 25 1 0 6 -9 13 -20 16
-34 9 -23 25 13 18 17 -4 36 -10 41 -13 5 -3 40 -4 78 -3 58 2 68 0 68 -14 0
-15 -10 -16 -82 -11 -65 4 -77 3 -53 -5 46 -16 368 -23 454 -10 91 14 215 74
265 128 19 21 36 42 36 47 0 5 7 17 16 27 9 10 20 32 24 51 5 18 12 43 15 56
4 12 3 22 -3 22 -5 0 -13 -17 -18 -37 -4 -23 -9 -31 -11 -20 -3 9 -9 17 -14
17 -6 0 -7 5 -4 10 4 6 11 7 17 4 7 -5 9 1 5 15 -3 13 -3 20 0 17 3 -3 14 -1
24 5 19 10 28 69 11 69 -4 0 -8 -12 -7 -27 0 -19 -6 -27 -20 -29 -11 -1 -30
-6 -42 -9 -14 -4 -23 -3 -23 3 0 12 24 22 51 22 10 0 19 5 19 10 0 14 -21 12
-71 -6 -70 -24 -214 -54 -344 -70 -93 -12 -188 -14 -422 -11 -202 4 -303 2
-303 -5 0 -6 19 -8 53 -4 42 6 48 5 33 -5 -12 -7 -38 -9 -72 -5 -34 4 -54 2
-54 -4 0 -5 -23 -10 -52 -10 -48 0 -50 1 -32 15 10 8 39 15 64 15 33 0 41 3
30 10 -8 5 -59 10 -112 10 -77 0 -98 3 -98 14 0 21 32 26 175 27 90 1 121 4
100 10 -21 6 -13 7 30 3 82 -7 241 7 200 18 -21 5 -15 6 22 3 53 -4 216 16
283 34 19 5 58 15 85 22 65 15 175 47 200 58 104 45 133 59 157 79 15 13 28
21 28 17 0 -3 14 4 31 16 24 16 28 24 17 30 -9 6 -5 8 12 7 21 -3 25 1 23 20
-2 19 33 78 79 132 14 16 12 60 -3 60 -5 0 -9 8 -9 18 0 16 29 28 31 13 5 -44
16 -71 27 -71 5 0 19 33 31 73 22 74 27 164 14 265 -5 46 -9 53 -26 50 -14 -2
-24 -17 -35 -50 -15 -47 -20 -51 -89 -64 -23 -4 -14 22 13 36 15 8 28 26 31
43 4 20 8 25 14 15 6 -9 9 -9 9 -1 0 6 -5 15 -11 19 -7 4 -1 15 17 29 31 24
47 24 37 0 -3 -9 1 -15 11 -15 18 0 22 25 6 35 -5 3 -10 15 -10 27 0 17 -6 14
-42 -21 -24 -22 -47 -41 -52 -41 -4 0 -3 -7 4 -16 11 -12 10 -18 -3 -31 -15
-15 -16 -15 -10 5 7 29 -3 28 -45 -4 -26 -21 -31 -29 -21 -36 10 -7 8 -8 -7
-3 -13 3 -28 0 -35 -8 -23 -21 -125 -95 -162 -116 -19 -10 -45 -25 -58 -33
-13 -7 -26 -16 -29 -19 -3 -3 -21 -14 -40 -25 -27 -14 -31 -19 -17 -22 10 -2
27 4 38 14 17 16 19 16 19 1 0 -10 -5 -17 -11 -17 -5 0 -8 -4 -5 -9 7 -10 -52
-24 -65 -15 -5 3 -6 10 -3 16 5 8 1 9 -10 5 -10 -4 -19 -7 -22 -7 -2 0 -1 -6
2 -14 7 -19 -29 -39 -43 -25 -13 13 -77 -17 -68 -31 4 -6 1 -10 -5 -10 -7 0
-10 -6 -7 -14 3 -8 -1 -17 -9 -20 -23 -9 -34 -7 -34 4 0 6 7 10 16 10 8 0 12
5 9 10 -3 6 -1 10 4 10 6 0 11 4 11 9 0 6 -12 3 -27 -5 -16 -7 -33 -14 -40
-14 -6 0 -13 -3 -15 -7 -1 -5 -25 -16 -53 -27 -27 -10 -72 -27 -100 -38 -27
-11 -88 -33 -135 -50 -96 -34 -99 -36 -81 -47 19 -12 -8 -20 -34 -10 l-20 8
20 1 c12 1 15 4 7 7 -7 3 -25 1 -40 -5 -15 -5 -46 -15 -70 -22 l-42 -13 32
-16 c30 -15 31 -16 10 -19 -12 -2 -29 4 -38 12 -17 18 -27 18 -64 5 -21 -7
-19 -8 13 -4 20 2 37 0 37 -6 0 -5 -34 -8 -75 -7 -41 0 -80 -4 -87 -9 -7 -6
-28 -14 -45 -18 -21 -4 -33 -13 -33 -23 -1 -14 -5 -12 -20 7 -25 29 -25 41 -1
41 10 0 32 7 47 15 16 8 37 15 46 15 10 0 43 10 75 21 57 21 77 30 198 84 33
15 75 34 93 41 18 8 30 19 27 25 -4 6 -22 1 -45 -12 -21 -12 -44 -19 -51 -16
-10 4 -8 6 4 6 23 1 45 22 39 39 -3 10 38 18 70 13 4 0 24 10 44 24 20 14 41
25 47 25 5 0 16 8 23 18 12 16 14 16 28 -4 l15 -21 53 26 c34 17 46 27 35 29
-24 5 -20 72 4 72 6 0 5 -9 -1 -21 -7 -11 -9 -23 -6 -27 3 -3 6 -1 6 5 0 8 41
33 55 33 17 0 0 -22 -22 -31 -16 -6 -23 -13 -16 -16 6 -2 33 10 61 26 27 17
52 31 56 31 4 0 12 5 19 10 7 6 55 42 107 80 52 38 100 72 105 77 150 119 245
224 338 372 l49 76 -5 140 c-5 126 -22 231 -47 290 -40 94 -60 135 -66 135 -3
0 -4 -9 -1 -21 4 -15 2 -19 -8 -15 -8 3 -17 1 -20 -4 -3 -4 1 -10 7 -12 7 -3
-2 -5 -19 -5 l-33 0 35 42 c30 37 35 56 16 55 -3 -1 -31 -39 -61 -87z m120
-49 c0 -7 3 -21 6 -31 6 -16 5 -16 -9 2 -9 11 -17 30 -17 43 0 15 -4 21 -12
16 -7 -5 -8 -3 -3 6 6 10 11 9 22 -5 7 -11 13 -24 13 -31z m-110 -1 c0 -9 -3
-14 -6 -10 -4 3 -15 -4 -25 -16 -10 -12 -18 -17 -19 -11 0 6 7 17 15 24 8 7
15 19 15 27 0 8 5 11 10 8 6 -3 10 -13 10 -22z m70 -24 c0 -5 5 -7 10 -4 6 3
10 1 10 -4 0 -6 -13 -11 -30 -11 -20 0 -30 5 -30 15 0 8 9 15 20 15 11 0 20
-5 20 -11z m-80 -51 c0 -27 -38 -108 -51 -108 -15 0 -10 31 14 81 24 52 37 62
37 27z m-83 -38 c-1 -20 -9 -25 -20 -13 -4 3 -2 12 4 20 15 18 18 17 16 -7z
m-58 -81 c-32 -31 -40 -45 -36 -65 4 -21 1 -24 -16 -18 -11 3 -17 2 -14 -3 3
-5 -3 -14 -14 -21 -10 -7 -19 -9 -19 -6 0 13 72 115 98 139 44 41 45 16 1 -26z
m48 -5 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m282 -169 c0
-38 -7 -86 -15 -105 -14 -33 -14 -32 -7 35 4 39 7 104 7 145 -1 63 0 68 7 35
4 -22 8 -71 8 -110z m-446 48 c-10 -28 -24 -43 -41 -43 -11 0 -8 8 13 30 33
35 37 36 28 13z m77 -33 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m-143 -53 c-2 -27 -6 -33 -19 -29 -10 2 -18 6 -18 9 0 4
33 52 37 53 1 0 1 -15 0 -33z m-50 -33 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-38 -55 c-25 -16 -32 -9 -13 12 12 13 20 16 22 9 3 -7 -2 -16
-9 -21z m91 11 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0
10 -9 10 -20z m-133 -36 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m513 -19 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15
-15z m70 5 c0 -21 -39 -85 -64 -107 -14 -13 -26 -30 -26 -38 0 -8 -7 -15 -15
-15 -8 0 -15 -6 -15 -13 0 -8 -7 -14 -15 -14 -8 0 -15 9 -15 19 0 17 10 22 38
19 7 -1 12 9 12 24 0 14 5 25 10 25 17 0 11 18 -7 23 -14 4 -13 5 4 6 20 1 73
53 73 72 0 5 5 9 10 9 6 0 10 -5 10 -10z m-613 -30 c0 -22 -4 -29 -14 -26 -7
3 -13 -1 -13 -9 0 -8 -7 -15 -15 -15 -9 0 -20 -12 -26 -27 -10 -28 -26 -43
-44 -43 -5 0 -3 5 5 10 13 8 13 10 -1 10 -9 0 -28 -11 -43 -25 -26 -24 -34
-45 -16 -45 5 0 9 -10 8 -22 0 -13 0 -27 2 -33 7 -29 -1 -55 -18 -55 -15 0
-19 7 -17 36 1 20 9 40 16 45 11 8 11 12 -5 24 -18 13 -18 16 -2 40 9 14 22
25 29 25 14 0 137 117 137 131 0 5 4 9 8 9 5 0 9 -14 9 -30z m3 -80 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m500 6 c0 -3
-4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-160 -56
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m92
-21 c15 -25 16 -34 6 -52 -13 -22 -13 -22 -16 6 -3 22 -8 28 -25 25 -32 -4
-35 -43 -5 -52 20 -6 21 -7 6 -16 -12 -7 -25 -5 -47 7 -17 10 -31 21 -31 26 0
10 73 85 85 86 6 1 18 -13 27 -30z m-632 -45 c0 -8 -2 -14 -4 -14 -2 0 -11 -3
-19 -6 -9 -3 -16 3 -20 15 -3 12 -1 19 3 16 5 -3 11 -1 15 5 8 13 25 3 25 -16z
m-46 -26 c9 -34 7 -38 -9 -32 -19 8 -77 -15 -69 -27 6 -11 1 -11 -20 -3 -12 5
-7 13 24 35 22 16 40 34 40 39 0 20 28 9 34 -12z m600 -47 c3 -4 -3 -16 -14
-26 -12 -10 -20 -13 -20 -7 0 18 -41 14 -63 -5 -19 -17 -19 -17 -15 2 3 16 11
20 44 20 29 0 40 4 37 13 -6 13 23 17 31 3z m-730 -43 c-17 -31 -39 -48 -61
-48 -12 0 -23 5 -23 11 0 7 10 9 28 5 19 -5 23 -4 12 3 -16 11 -15 13 15 29
27 15 38 15 29 0z m562 -27 c-22 -21 -46 0 -26 24 10 12 16 13 27 3 12 -10 12
-13 -1 -27z m124 26 c0 -2 -9 -12 -20 -22 -19 -18 -20 -17 -20 3 0 15 6 22 20
22 11 0 20 -2 20 -3z m-60 -32 c0 -8 -8 -15 -17 -16 -12 0 -13 -2 -5 -6 10 -4
10 -8 1 -19 -15 -18 -33 -18 -26 0 4 9 -1 17 -11 19 -13 4 -12 5 4 6 12 0 27
8 34 16 7 8 14 15 16 15 2 0 4 -7 4 -15z m-577 -54 c-4 -4 -20 -12 -35 -19
-41 -17 -37 13 5 38 28 18 32 18 35 4 2 -9 0 -19 -5 -23z m-150 22 c-8 -3 -11
-14 -7 -29 8 -29 -14 -40 -53 -26 l-28 9 32 19 c18 11 33 22 33 27 0 4 8 7 18
6 10 0 12 -3 5 -6z m1075 -5 c-8 -7 -20 -22 -26 -33 -6 -12 -22 -20 -40 -21
-28 -1 -29 -1 -14 17 20 24 29 24 35 2 4 -15 5 -14 6 3 1 24 19 44 40 44 13 0
13 -2 -1 -12z m210 -70 c-1 -45 -3 -58 -5 -34 -3 22 -12 45 -20 51 -14 11 -14
14 3 33 10 11 20 21 22 21 1 1 1 -32 0 -71z m-1368 2 c0 -5 -2 -10 -4 -10 -3
0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m430 0 c0 -5 -7 -10 -15 -10
-8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m264 0 c-11 -4 -26 -15
-33 -24 -17 -20 -31 -21 -31 -1 0 10 -11 15 -35 15 -19 0 -35 4 -35 10 0 5 34
9 77 8 46 -1 69 -4 57 -8z m326 -30 c-12 -11 -31 -22 -41 -25 -12 -4 -19 -16
-19 -31 0 -25 -14 -30 -39 -15 -18 11 -5 26 54 60 22 13 44 30 49 39 7 12 11
13 14 4 2 -7 -6 -22 -18 -32z m-463 4 c2 -6 -7 -9 -25 -6 -23 3 -34 -2 -51
-24 -28 -36 -71 -59 -71 -38 0 8 7 17 15 20 8 3 17 14 20 23 3 10 15 23 28 29
27 14 79 11 84 -4z m-558 -18 c-2 -2 -17 -6 -34 -10 l-30 -7 30 19 c17 10 32
15 34 10 1 -5 1 -11 0 -12z m599 -22 c-9 -9 -21 -13 -27 -9 -16 10 -3 25 22
25 20 0 20 -1 5 -16z m437 6 c-3 -5 -14 -10 -23 -9 -14 0 -13 2 3 9 27 11 27
11 20 0z m-1115 -46 c0 -16 -5 -24 -12 -21 -16 5 -18 29 -3 39 15 11 15 11 15
-18z m1112 -2 c-7 -2 -12 -12 -12 -23 0 -24 -12 -24 -35 1 -17 19 -17 20 1 26
11 3 26 9 34 13 9 5 17 4 19 -2 2 -6 -1 -13 -7 -15z m-1152 -32 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1020 0 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-472 -25
c-13 -6 -25 -10 -28 -7 -3 3 1 7 8 9 6 3 10 8 7 13 -3 4 4 6 16 3 20 -5 20 -6
-3 -18z m-318 5 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0
15 -4 15 -10z m225 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m1005 1 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16
6 0 10 -4 10 -9z m-1117 -18 c13 -12 -15 -33 -44 -33 -27 0 -39 17 -22 33 8 9
58 9 66 0z m-476 -28 c10 -24 1 -36 -12 -15 -3 5 -26 8 -51 5 -38 -3 -44 -2
-34 10 6 8 26 16 43 18 18 2 34 4 37 5 3 1 10 -9 17 -23z m973 5 c0 -5 -7 -10
-16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m530 -15 c0 -8 -7
-15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-1626 -20
c-3 -8 -21 -24 -40 -36 -40 -25 -43 -33 -9 -27 14 3 25 2 25 -3 0 -4 -22 -7
-50 -7 -27 1 -50 2 -50 4 0 1 20 14 45 28 25 15 45 31 45 37 0 6 5 7 10 4 6
-3 10 -1 10 4 0 6 4 11 10 11 5 0 7 -7 4 -15z m1079 8 c-7 -2 -19 -2 -25 0 -7
3 -2 5 12 5 14 0 19 -2 13 -5z m94 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-1070 -41 c3 -7 -8 -15 -24 -18 -15 -4 -36 -10 -45 -16 -23 -12
-48 -12 -48 1 0 6 8 10 18 10 11 0 27 9 37 20 20 22 56 23 62 3z m789 -19
c-45 -22 -88 -33 -74 -18 7 7 100 42 113 43 6 0 -12 -11 -39 -25z m204 6 c0
-22 -7 -25 -29 -11 -16 10 -6 31 15 31 8 0 14 -9 14 -20z m-956 -15 c3 -8 12
-15 21 -15 8 0 15 -4 15 -10 0 -5 -8 -10 -17 -10 -25 1 -56 26 -48 39 9 15 22
14 29 -4z m781 -5 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6
0 7 -4 4 -10z m522 -20 c6 -24 48 -27 79 -5 34 23 35 6 2 -26 -36 -34 -68 -38
-75 -9 -3 11 -11 20 -19 20 -16 0 -27 17 -19 30 10 16 27 11 32 -10z m-1067 1
c0 -6 -21 -13 -47 -16 -85 -11 -102 -16 -138 -41 -44 -30 -57 -30 -53 -3 2 17
14 25 51 34 27 7 53 17 60 24 13 13 127 15 127 2z m40 5 c0 -2 -7 -6 -15 -10
-8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m56 -11 c-36 -19 -56 -19
-30 0 10 8 28 14 39 15 16 0 14 -3 -9 -15z m-61 -25 c-3 -5 -11 -10 -17 -10
-5 0 -31 -11 -57 -23 -29 -15 -54 -21 -66 -17 -19 7 -19 7 2 18 12 7 31 12 42
12 12 0 21 4 21 9 0 6 15 12 33 14 17 2 36 5 40 5 5 1 5 -2 2 -8z m355 0 c0
-5 -9 -10 -19 -10 -11 0 -21 -4 -23 -9 -5 -15 -108 -26 -108 -12 0 7 4 10 9 7
10 -7 71 16 71 26 0 4 16 8 35 8 19 0 35 -4 35 -10z m-163 -36 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m838 -14 c-3 -5 -10 -10 -16 -10 -5 0
-9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m82 -14 c-2 -6 -18 -12 -36 -14
-22 -2 -35 -10 -38 -22 -5 -18 -90 -60 -121 -60 -9 0 -24 -7 -34 -17 -10 -9
-21 -14 -24 -10 -12 12 9 27 47 33 50 8 51 24 2 26 -38 1 -38 1 7 7 26 4 51 2
59 -4 11 -7 13 -7 8 1 -3 6 3 10 16 10 17 -1 21 4 20 21 -2 19 3 24 37 29 22
4 37 10 34 15 -3 5 2 6 11 3 9 -4 14 -12 12 -18z m-1293 -1 c-4 -8 -19 -15
-34 -15 -16 0 -32 -4 -35 -10 -9 -15 -25 -12 -25 4 0 11 41 26 92 35 4 0 5 -6
2 -14z m-414 -19 c0 -24 -16 -36 -51 -36 -18 0 -18 3 8 30 34 36 43 37 43 6z
m960 4 c0 -23 -25 -26 -65 -8 -24 11 -25 13 -8 19 10 4 30 8 46 8 21 1 27 -4
27 -19z m-260 2 c0 -5 -13 -7 -30 -4 -18 3 -30 0 -30 -7 0 -6 14 -11 30 -11
17 0 30 -5 30 -11 0 -13 -48 -3 -68 13 -11 9 -11 13 0 19 17 11 68 11 68 1z
m105 -12 c4 -6 -5 -10 -19 -10 -14 0 -26 5 -26 10 0 6 9 10 19 10 11 0 23 -4
26 -10z m-58 -26 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-267
-44 c0 -26 -57 -42 -105 -29 -19 6 -35 15 -35 21 0 5 7 8 15 5 8 -4 17 -2 20
3 4 6 12 10 18 9 9 0 9 -2 0 -6 -19 -7 -15 -23 5 -23 11 0 27 9 37 20 23 25
45 26 45 0z m694 -6 c-24 -8 -42 -10 -48 -4 -10 10 3 17 44 23 42 5 45 -5 4
-19z m156 -4 c7 -13 21 -20 40 -20 16 0 32 -4 35 -10 4 -6 -5 -10 -19 -10 -14
0 -26 -4 -26 -8 0 -4 -35 -13 -77 -19 -43 -7 -86 -15 -95 -18 -13 -5 -18 -2
-18 9 0 17 5 18 55 18 20 -1 24 1 10 5 -30 8 -43 23 -19 23 10 0 30 12 43 25
28 30 57 32 71 5z m-1546 1 c8 -12 -13 -28 -47 -36 -34 -9 -55 -42 -32 -51 23
-9 18 -24 -10 -24 -18 0 -23 4 -18 16 5 14 3 14 -15 3 -20 -13 -20 -15 -6 -37
21 -32 12 -49 -34 -62 -35 -9 -41 -15 -46 -45 -10 -50 -28 -44 -24 8 4 46 22
86 34 75 4 -4 1 -15 -7 -24 -12 -15 -12 -17 1 -12 8 2 18 19 22 36 5 18 13 32
19 32 6 0 8 3 6 8 -3 4 11 21 30 37 19 17 49 42 66 58 32 28 52 34 61 18z
m463 -23 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m107 -7 c3 -5
0 -11 -8 -14 -8 -3 -17 1 -20 9 -6 15 19 20 28 5z m-554 -36 c0 -8 -6 -15 -14
-15 -17 0 -28 14 -19 24 12 12 33 6 33 -9z m680 -36 c0 -5 28 -9 63 -10 46 -1
56 -3 37 -9 -14 -4 -43 -9 -65 -10 -35 -2 -37 -3 -15 -10 14 -4 52 -9 85 -11
50 -2 53 -3 20 -7 -56 -5 -205 8 -205 19 0 5 15 9 33 10 23 0 27 2 14 8 -22 8
-24 21 -6 31 14 9 39 2 39 -11z m589 -15 c2 -2 -11 -4 -30 -4 -19 0 -44 -3
-56 -6 -17 -5 -23 -2 -23 10 0 14 8 16 53 10 28 -4 54 -8 56 -10z m-235 -14
c16 0 27 -2 24 -5 -9 -9 -138 -11 -138 -2 0 6 -31 7 -72 4 -64 -5 -67 -4 -28
4 25 5 65 7 90 5 25 -3 52 -1 60 4 9 6 18 6 24 0 6 -6 23 -10 40 -10z m9 13
c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m65 0 c-10 -2 -26 -2
-35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m-551 -39 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m1343 -24 c0 -13 -43 -30 -76 -30 -13 0 -24 -4
-24 -10 0 -6 -28 -10 -62 -10 -72 0 -120 -19 -53 -21 32 0 36 -2 20 -9 -11 -5
-38 -8 -60 -8 -22 0 -32 2 -22 5 31 8 18 23 -20 24 l-38 1 35 8 c19 5 71 13
115 19 43 6 82 15 85 19 3 4 21 9 39 10 19 2 37 5 40 7 8 9 21 5 21 -5z
m-1343 -46 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m122 -10 c2
-2 -14 -4 -37 -4 -23 0 -42 4 -42 9 0 9 69 4 79 -5z m881 -4 c0 -5 -18 -10
-40 -10 -22 0 -40 -4 -40 -10 0 -15 -26 -12 -40 5 -10 12 -2 15 52 18 35 2 64
4 66 5 1 1 2 -2 2 -8z m-1763 -22 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m63 1 c0 -11 -27 -12 -34 0 -3 4 -3 11 0 14 8 8 34 -3 34 -14z
m1228 12 c8 -5 -8 -7 -40 -5 -49 2 -50 2 -13 -5 l40 -8 -35 -2 c-20 0 -40 3
-45 8 -6 5 -19 11 -30 14 -11 3 9 5 45 6 36 0 71 -3 78 -8z m292 -1 c15 -5
-25 -8 -100 -7 -121 1 -122 1 -40 7 47 3 92 7 100 7 8 1 26 -3 40 -7z m529
-14 c9 -10 8 -16 -4 -26 -8 -7 -15 -22 -15 -34 0 -19 3 -17 26 12 14 18 30 30
35 27 14 -9 11 -25 -6 -25 -8 0 -17 -11 -21 -24 -7 -31 -39 -61 -49 -47 -3 6
-17 11 -31 11 -30 0 -32 29 -3 56 14 12 18 25 14 41 -4 19 -1 23 19 23 13 0
29 -6 35 -14z m-1276 -3 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m314 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m610 0 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1093 -10 c21 -8 20 -14 -9
-48 -28 -33 -30 -33 -38 10 -8 42 6 54 47 38z m-707 -126 c-2 -13 -4 -5 -4 17
-1 22 1 32 4 23 2 -10 2 -28 0 -40z m36 -70 c-7 -13 -30 -9 -36 5 -3 7 3 36
12 64 l16 51 6 -56 c4 -32 5 -60 2 -64z m782 112 c29 -1 29 -2 7 -12 -18 -9
-30 -8 -55 5 -28 14 -29 16 -7 12 14 -2 39 -4 55 -5z m179 -39 c5 -8 -84 -3
-93 5 -2 2 16 4 42 4 25 0 48 -4 51 -9z m-294 -11 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m37 -16 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m1323 -3 c0 -16 -25 -30 -81 -45 -30 -8 -64
-18 -76 -22 -13 -3 -23 -4 -23 -1 0 12 94 61 129 68 52 10 51 10 51 0z m-780
-13 c0 -5 10 -13 23 -19 32 -14 -82 -3 -153 15 l-55 14 93 -1 c50 0 92 -4 92
-9z m113 5 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m160 0 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m317 -9 c0 -7 -15 -15 -32
-18 -164 -26 -218 -30 -218 -17 0 7 21 11 50 11 28 0 50 5 50 10 0 6 21 10 48
10 26 0 53 4 60 8 19 13 42 10 42 -4z m-1074 -9 c5 -13 -16 -45 -30 -45 -2 0
-4 14 -4 30 1 32 24 42 34 15z m73 -29 c26 -9 31 -14 18 -19 -9 -3 -30 1 -46
9 -52 26 -33 32 28 10z m-58 -26 c-21 -5 -22 -7 -8 -19 18 -14 129 -17 150 -4
19 13 55 10 50 -3 -3 -9 7 -11 34 -9 21 1 26 0 11 -2 -28 -4 -38 -28 -15 -36
6 -2 3 -2 -8 0 -72 14 -139 21 -173 16 -24 -3 -42 -1 -46 6 -4 6 -16 11 -27
11 -30 0 -24 34 9 46 15 6 31 8 37 5 5 -3 -1 -8 -14 -11z m-691 -35 c0 -25 -4
-45 -10 -45 -11 0 -14 49 -4 74 10 26 14 18 14 -29z m-51 -45 c1 -19 8 -43 16
-53 7 -11 11 -25 8 -33 -3 -8 -1 -14 5 -14 6 0 13 6 15 13 3 6 6 2 6 -9 2 -45
-26 -32 -56 26 -28 55 -30 62 -20 107 10 41 12 44 18 23 4 -14 8 -41 8 -60z
m1714 73 c-34 -2 -93 -2 -130 0 -37 2 -9 3 62 3 72 0 102 -1 68 -3z m-613 -64
c13 -6 12 -9 -4 -15 -10 -4 -21 -2 -25 5 -4 6 -21 13 -38 14 -17 1 -29 6 -26
10 5 8 69 -1 93 -14z m-90 -48 c0 -11 -9 -23 -21 -26 -17 -6 -19 -9 -8 -16 10
-6 8 -9 -9 -9 -23 0 -23 0 5 -24 15 -13 24 -28 20 -32 -4 -5 -11 -3 -15 3 -4
6 -21 15 -37 19 -43 11 -60 30 -44 50 15 18 78 53 97 54 6 0 12 -9 12 -19z
m230 4 c8 -1 24 -4 35 -7 11 -4 37 -9 58 -13 41 -7 51 -24 13 -21 -13 0 -22 4
-19 9 6 10 -12 9 -41 -4 -34 -16 -25 -27 32 -35 59 -8 82 -20 81 -42 0 -9 -3
-12 -5 -6 -3 7 -22 9 -48 7 -35 -4 -48 -1 -65 16 -17 17 -29 20 -60 15 -51 -8
-65 12 -19 29 l33 12 -35 6 c-31 6 -32 7 -7 8 34 1 36 21 2 21 -14 0 -25 5
-25 11 0 7 10 9 28 4 15 -4 34 -9 42 -10z m-613 -51 c0 -8 -9 -14 -19 -14 -11
0 -18 7 -18 19 0 14 5 18 18 14 10 -2 19 -11 19 -19z m223 11 c11 -13 10 -14
-4 -9 -9 3 -16 10 -16 15 0 13 6 11 20 -6z m795 -69 c43 -12 46 -14 19 -15
-43 -1 -94 10 -94 20 0 12 16 11 75 -5z m-420 -6 c6 -1 1 -5 -10 -9 -22 -9
-45 -4 -45 10 0 5 10 7 23 5 12 -3 27 -5 32 -6z m249 -2 c3 3 6 0 6 -6 0 -15
-8 -19 -52 -27 -37 -7 -37 -7 -25 18 9 21 15 23 38 17 15 -5 30 -6 33 -2z
m-1394 -14 c0 -2 -9 -4 -20 -4 -11 0 -20 4 -20 9 0 5 9 7 20 4 11 -3 20 -7 20
-9z m630 12 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m-539
-15 c12 -1 19 -15 24 -48 3 -27 9 -58 12 -70 3 -14 1 -23 -6 -23 -6 0 -11 13
-11 30 0 28 -45 88 -62 82 -4 -1 -12 6 -17 16 -10 18 -9 19 60 13z m930 -4
c-6 -8 -7 -18 -3 -22 4 -5 2 -5 -5 -1 -14 8 -6 36 9 36 7 0 6 -5 -1 -13z m54
-7 c-8 -12 18 -29 61 -39 l39 -9 -33 -1 c-18 -1 -40 3 -50 8 -9 5 -25 12 -35
15 -15 5 -16 9 -7 26 6 11 15 18 21 15 5 -4 7 -10 4 -15z m590 8 c28 -13 32
-23 11 -31 -8 -3 -16 -16 -18 -28 -4 -31 -66 -68 -79 -48 -13 21 -5 34 24 37
14 2 31 10 37 17 12 15 7 46 -7 44 -17 -3 -24 1 -18 11 8 13 18 13 50 -2z
m-411 -17 c3 -5 16 -7 29 -5 12 3 5 -3 -17 -12 -31 -14 -39 -15 -46 -3 -5 7
-6 17 -4 21 7 11 32 10 38 -1z m-1228 -26 c3 -14 9 -23 13 -21 4 3 8 -5 8 -16
2 -19 1 -19 -18 -3 -10 10 -19 24 -19 32 0 7 -10 18 -22 23 -22 8 -22 9 4 9
21 1 29 -5 34 -24z m813 -15 c6 0 11 -4 11 -10 0 -5 3 -9 8 -7 4 1 8 -2 10 -8
6 -19 -27 -16 -47 4 -12 12 -28 18 -38 15 -15 -5 -16 -4 -3 5 13 10 8 15 -12
12 -4 0 -5 6 -2 14 5 13 10 12 34 -5 15 -11 33 -20 39 -20z m562 -13 c-13 -13
-26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z m-821 -7 c0 -5 7 -10 15 -10
8 0 15 -7 15 -16 0 -15 -2 -15 -22 -2 -25 17 -35 38 -19 38 6 0 11 -4 11 -10z
m-86 -45 c6 -10 17 -25 24 -33 12 -14 15 -23 14 -57 -1 -17 0 -17 4 -2 7 21
20 22 28 1 10 -26 7 -34 -13 -34 -19 0 -31 18 -36 57 -4 27 -45 84 -57 77 -4
-3 -8 0 -8 6 0 18 32 7 44 -15z m371 6 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9
0 18 6 21 15 7z m63 -14 c5 -23 -10 -21 -26 4 -12 18 -11 20 5 17 10 -2 19
-11 21 -21z m-28 -11 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10
8 0 15 -2 15 -4z m-535 -27 c-4 -13 -3 -19 3 -15 13 8 46 -30 37 -43 -11 -19
-21 -12 -43 28 -21 37 -20 44 6 50 2 1 0 -9 -3 -20z m403 -1 c2 -10 -1 -18 -7
-18 -6 0 -11 7 -11 16 0 13 -7 15 -35 11 -19 -3 -37 -1 -41 4 -4 6 12 9 42 7
37 -2 50 -7 52 -20z m110 -10 c-5 -16 -48 -24 -48 -10 0 11 17 20 38 21 8 1
12 -5 10 -11z m-120 -31 c12 -25 18 -28 46 -23 28 5 32 3 34 -18 2 -24 0 -24
-28 -13 -16 7 -48 30 -72 51 -39 34 -40 37 -18 34 16 -2 30 -14 38 -31z m170
-25 c4 -25 -13 -30 -22 -6 -9 25 -7 36 7 32 6 -3 13 -14 15 -26z m-619 -42 c4
0 15 -25 26 -56 10 -31 25 -58 32 -61 7 -3 13 -11 13 -18 0 -7 7 -18 15 -25
18 -15 20 -33 3 -27 -7 2 -17 -2 -23 -9 -7 -10 -14 -11 -25 -3 -13 9 -12 10 3
5 24 -8 23 40 -2 53 -10 6 -22 27 -27 48 l-7 38 -7 -30 c-4 -16 -8 -22 -9 -13
-1 11 -6 15 -16 12 -10 -4 -15 0 -15 10 0 9 -7 19 -15 22 -8 4 -15 10 -15 15
0 15 28 10 37 -6 5 -9 13 -11 22 -6 10 7 10 13 2 29 -6 11 -10 36 -9 54 l1 33
4 -32 c2 -18 8 -33 12 -33z m810 11 c9 -6 10 -10 2 -16 -15 -8 -41 1 -41 15 0
12 20 13 39 1z m-167 -26 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15
11 13 6 -2 11 -8 11 -13z m-767 -23 c21 -14 55 -65 49 -73 -3 -5 2 -6 10 -3
11 4 16 0 16 -15 0 -23 15 -28 24 -8 3 6 3 1 0 -12 -4 -15 -2 -21 4 -17 12 7
42 -3 42 -14 0 -5 -9 -12 -21 -15 -14 -5 -26 0 -39 17 -11 12 -28 32 -38 43
-27 29 -74 105 -65 105 5 0 13 -4 18 -8z m905 -2 c0 -5 -6 -10 -14 -10 -8 0
-18 5 -21 10 -3 6 3 10 14 10 12 0 21 -4 21 -10z m-574 -21 c3 -6 18 -9 32 -6
25 5 25 4 8 -8 -11 -8 -16 -21 -13 -30 4 -8 1 -15 -6 -15 -14 0 -47 20 -33 20
4 0 1 11 -8 25 -14 21 -14 25 -2 25 8 0 18 -5 22 -11z m-173 -161 c12 -7 30
-13 41 -15 10 -2 26 -8 35 -13 12 -8 8 -9 -20 -8 -21 1 -49 12 -65 25 -31 25
-26 30 9 11z"/>
<path d="M8705 6200 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8275 5860 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
<path d="M8880 5819 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8957 5283 c-13 -12 -7 -23 14 -23 11 0 18 4 15 9 -4 5 0 12 6 14 7
3 4 6 -8 6 -11 1 -24 -2 -27 -6z"/>
<path d="M9348 4994 c-5 -4 -8 -16 -8 -28 0 -14 3 -17 11 -9 6 6 9 19 7 28 -1
9 -6 13 -10 9z"/>
<path d="M9238 4863 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M8560 4660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8980 4460 c0 -13 11 -13 30 0 12 8 11 10 -7 10 -13 0 -23 -4 -23
-10z"/>
<path d="M1451 6897 c-48 -99 -67 -154 -74 -207 -6 -45 -6 -45 8 -15 8 17 14
40 15 52 0 12 5 25 10 28 13 8 13 6 -7 -95 -9 -47 -19 -110 -23 -140 -7 -49
-8 -45 -6 33 0 48 -2 87 -6 87 -20 0 -21 -304 0 -319 4 -4 18 -23 32 -43 13
-20 29 -34 34 -31 5 3 6 1 2 -5 -4 -7 11 -34 36 -64 66 -80 170 -188 180 -188
6 0 12 8 15 19 4 17 8 16 36 -10 17 -16 35 -29 39 -29 5 0 8 -6 8 -14 0 -8 8
-17 17 -20 14 -6 15 -9 4 -16 -17 -10 -41 -2 -41 14 -1 23 -45 65 -59 57 -10
-7 -5 -16 23 -41 44 -41 82 -67 72 -52 -4 7 -2 12 6 12 7 0 23 3 36 6 13 4 22
3 21 -2 -1 -5 -1 -18 0 -29 1 -15 3 -17 10 -7 6 11 10 10 15 -4 4 -11 2 -20
-3 -22 -24 -8 -9 -22 17 -16 43 10 47 10 45 -3 -2 -8 7 -13 21 -13 40 0 56
-11 56 -36 0 -13 -4 -24 -10 -24 -5 0 -10 6 -10 14 0 15 -21 26 -51 26 -11 0
-22 7 -25 15 -4 8 -14 15 -23 15 -12 0 -10 -5 9 -20 14 -11 29 -20 33 -20 5 0
28 -16 51 -35 24 -19 46 -35 50 -35 5 0 20 -8 34 -19 15 -10 59 -35 97 -55 39
-20 77 -41 85 -45 90 -51 161 -76 145 -51 -3 6 -15 10 -27 10 -11 0 -31 9 -44
19 l-24 19 24 -2 c13 0 22 -4 19 -8 -2 -5 7 -8 21 -8 54 0 9 57 -50 62 l-39 3
40 13 c34 10 42 10 51 -3 6 -8 21 -15 33 -15 23 0 31 -35 9 -43 -10 -3 -10 -9
0 -28 7 -14 12 -31 10 -39 -2 -8 2 -15 7 -15 10 -1 43 -15 125 -53 63 -29 172
-72 182 -72 5 0 7 4 3 9 -3 5 3 12 12 14 10 2 1 3 -20 0 -25 -2 -36 0 -32 7 4
6 -6 10 -25 10 -18 0 -29 4 -25 10 7 12 75 14 75 2 0 -4 24 -8 53 -9 39 -1 46
1 27 7 l-25 8 26 1 c15 1 36 -7 48 -16 19 -16 20 -20 8 -37 -12 -17 -19 -18
-56 -9 -22 6 -43 7 -46 2 -3 -4 10 -11 30 -15 19 -3 38 -10 41 -15 3 -5 14 -9
25 -9 19 0 37 -25 26 -36 -3 -3 -33 2 -67 10 -34 9 -70 16 -81 16 -10 0 -19 5
-19 11 0 7 -10 9 -25 7 -13 -3 -23 -2 -20 2 2 3 -19 12 -48 20 -46 12 -136 39
-237 71 -150 48 -504 198 -520 221 -3 3 -18 10 -33 13 -15 4 -30 11 -33 16 -2
5 -22 16 -42 26 -20 9 -39 20 -42 23 -3 3 -32 21 -65 39 -33 19 -62 37 -65 41
-3 4 -25 19 -50 35 -25 15 -49 32 -55 39 -21 24 -95 77 -95 68 0 -5 9 -17 20
-27 24 -22 26 -35 5 -35 -8 0 -15 5 -15 11 0 5 -4 8 -9 4 -5 -3 -12 4 -16 15
-7 21 -58 54 -69 43 -14 -15 -5 -43 14 -43 11 0 20 -7 20 -15 0 -9 11 -20 25
-27 13 -6 22 -15 19 -19 -9 -16 -30 -10 -58 16 l-26 24 0 -34 c0 -28 3 -32 20
-28 11 3 20 0 20 -6 0 -6 -6 -11 -14 -11 -7 0 -18 -18 -23 -42 l-11 -43 -2 70
c-1 39 -3 94 -5 123 -2 48 3 57 27 53 4 0 4 7 1 17 -6 15 -4 15 15 -2 11 -10
27 -16 34 -13 9 4 9 6 1 6 -7 1 -28 16 -46 35 l-35 34 -17 -81 c-20 -93 -23
-286 -4 -298 7 -4 9 -11 5 -17 -22 -36 97 -218 179 -273 28 -19 52 -37 55 -40
5 -5 112 -61 155 -80 35 -16 178 -61 220 -70 22 -4 69 -15 105 -24 35 -9 80
-14 100 -11 23 4 28 3 15 -3 -14 -6 -6 -9 35 -14 72 -9 95 -9 86 0 -9 9 105
32 154 31 l35 0 -30 -7 c-70 -15 -12 -26 140 -28 88 -1 174 -6 190 -12 22 -8
-9 -9 -125 -6 -110 3 -145 2 -120 -5 19 -5 110 -13 202 -16 157 -7 167 -8 145
-23 -24 -18 -71 -22 -222 -21 -55 1 -111 -3 -125 -9 l-25 -10 30 -1 c25 -1 27
-2 10 -9 -11 -5 -72 -8 -135 -8 -87 -1 -104 1 -70 7 25 5 71 6 103 3 42 -4 57
-3 57 7 0 10 -37 11 -172 7 -232 -8 -551 14 -623 43 -33 13 -158 40 -163 35
-3 -2 4 -7 14 -11 10 -3 30 -10 44 -16 l25 -11 -30 -13 c-26 -11 -28 -13 -12
-20 10 -4 28 -8 40 -9 35 -4 37 -5 37 -14 0 -5 -18 -12 -40 -16 -22 -4 -47
-13 -55 -22 -16 -15 -20 -48 -7 -56 4 -2 1 -2 -5 -1 -7 2 -13 -1 -13 -7 0 -17
-27 15 -35 41 -4 14 -12 31 -17 38 -7 8 -7 12 0 12 6 0 14 -5 17 -10 5 -8 12
-7 23 2 14 12 15 9 10 -22 -6 -34 -6 -34 5 -7 6 15 17 27 24 27 7 0 13 6 13
13 0 16 -51 38 -81 35 -21 -3 -24 1 -23 29 1 19 -4 34 -12 37 -11 4 -13 -5
-10 -43 3 -27 9 -57 14 -67 5 -11 18 -36 28 -58 42 -85 179 -176 300 -197 69
-12 285 -18 364 -9 32 3 -33 8 -159 11 -154 4 -216 8 -219 17 -2 7 -12 12 -23
12 -10 0 -19 5 -19 11 0 5 -4 8 -10 4 -5 -3 -26 1 -47 10 -30 13 -35 18 -23
25 21 14 43 12 34 -2 -4 -6 -3 -8 3 -5 6 4 44 -7 84 -24 76 -31 74 -31 194
-13 17 3 66 4 110 2 118 -4 211 1 240 12 29 11 142 14 132 4 -3 -4 -17 -8 -29
-9 -13 -2 -43 -8 -68 -14 -25 -6 -70 -11 -100 -11 -41 0 -51 -3 -42 -12 15
-15 128 1 332 47 85 20 170 38 188 42 17 3 32 9 32 13 0 4 37 10 83 13 45 3
91 9 103 12 18 6 21 2 22 -24 0 -18 -4 -29 -9 -25 -5 3 -7 10 -4 15 14 21 -24
7 -38 -15 -18 -27 -13 -36 17 -36 21 -1 21 -1 1 -13 -11 -6 -51 -12 -88 -13
-62 -2 -67 -1 -50 12 17 14 16 15 -7 8 -29 -7 -51 -24 -32 -24 7 0 12 -4 12
-10 0 -5 -14 -10 -31 -10 -16 0 -28 4 -24 9 3 5 -53 -10 -125 -35 -188 -64
-388 -108 -635 -140 -202 -26 -201 -26 -110 -39 44 -7 67 -13 50 -14 -16 -2
-24 -5 -17 -8 14 -5 17 -33 3 -33 -5 0 -16 -2 -23 -5 -8 -3 -27 6 -41 21 -32
32 -35 24 -6 -22 11 -19 26 -31 31 -28 7 4 8 2 4 -4 -7 -12 42 -62 61 -62 6 0
13 -5 15 -11 2 -6 20 -11 41 -12 20 0 37 -5 37 -9 0 -4 12 -8 26 -8 14 0 23 4
19 10 -4 6 6 10 22 9 25 0 26 -1 8 -9 -16 -7 -13 -9 17 -9 20 -1 40 4 43 9 3
6 16 8 28 5 36 -9 346 82 331 97 -2 3 10 8 28 11 18 3 67 21 108 40 41 19 82
37 90 41 8 3 29 15 45 25 17 11 38 20 48 20 9 1 17 5 17 10 0 5 12 12 28 16
15 3 48 20 75 36 27 17 61 29 80 29 l32 0 -25 -20 c-14 -11 -30 -20 -35 -20
-6 0 -26 -13 -45 -30 -19 -16 -39 -29 -44 -30 -4 0 -25 -13 -45 -28 -37 -29
-175 -112 -185 -112 -6 0 -38 -24 -65 -50 -9 -9 -27 -16 -40 -17 -13 -1 -33
-11 -46 -23 -12 -11 -24 -18 -27 -15 -3 3 -28 -6 -56 -20 -27 -14 -53 -25 -57
-25 -3 0 -31 -11 -61 -25 -30 -14 -59 -25 -64 -25 -20 0 -60 -24 -60 -37 0 -7
-4 -13 -10 -13 -5 0 -10 5 -10 10 0 6 -5 10 -12 10 -7 0 8 -20 32 -45 71 -73
170 -92 306 -60 52 12 205 81 239 108 6 4 25 19 44 32 22 16 35 34 37 51 3 31
-20 72 -31 55 -8 -13 -35 -15 -35 -2 0 5 7 12 17 15 23 9 134 -5 127 -16 -3
-5 -14 -6 -25 -3 -10 3 -28 8 -39 11 -15 4 -18 2 -14 -11 5 -12 14 -15 33 -11
l26 6 -30 -24 c-16 -14 -26 -28 -23 -31 4 -4 52 30 108 74 56 45 98 81 94 81
-4 0 2 9 14 21 12 11 27 17 32 14 15 -9 12 -45 -4 -51 -8 -3 -35 -33 -61 -66
-34 -43 -44 -63 -36 -71 8 -8 11 -8 11 1 0 21 39 52 57 46 16 -5 16 -4 -1 10
-18 15 -18 16 -2 16 9 0 14 4 11 10 -3 5 5 15 19 21 17 8 26 23 31 47 6 31 3
39 -16 55 -14 12 -25 15 -27 8 -2 -6 -9 -11 -16 -11 -21 0 -52 -40 -39 -48 9
-5 6 -11 -7 -20 -11 -7 -20 -8 -20 -3 0 5 -6 11 -12 14 -7 2 -6 3 3 2 12 -2
15 3 12 16 -3 11 0 19 6 19 6 0 11 9 11 21 0 15 4 19 19 14 11 -3 22 -1 26 5
12 20 52 10 70 -17 31 -47 29 -78 -9 -110 -22 -18 -36 -40 -40 -65 -7 -37 -20
-48 -61 -48 -29 0 -52 -25 -33 -36 13 -9 18 -10 38 -13 6 0 10 -15 10 -32 0
-23 -4 -30 -15 -26 -8 4 -17 2 -20 -3 -11 -18 -27 -10 -22 11 3 13 0 22 -10
26 -12 5 -12 7 -2 14 8 4 10 11 7 15 -4 4 -19 -1 -32 -10 -44 -29 -42 -17 4
23 25 21 42 42 39 46 -4 3 -35 -21 -68 -54 -34 -34 -73 -69 -87 -78 -19 -12
-22 -18 -12 -24 7 -4 15 -3 19 3 4 7 9 7 13 -1 4 -6 3 -11 -3 -11 -5 0 -12 -8
-14 -17 -4 -16 -5 -16 -6 -2 -1 23 -33 34 -46 16 -5 -7 -29 -27 -52 -44 -23
-17 -39 -34 -35 -38 4 -5 -1 -5 -12 -1 -13 4 -17 2 -13 -8 4 -10 0 -12 -15 -8
-13 3 -19 1 -15 -5 3 -5 38 -16 78 -23 56 -11 83 -11 127 -2 57 12 70 18 59
28 -3 4 -22 -1 -41 -10 -41 -19 -76 -20 -135 -2 l-45 13 35 8 c19 4 42 7 52 6
10 -2 27 9 39 24 15 19 24 23 32 15 8 -8 7 -14 -4 -21 -11 -8 -1 -9 37 -5 29
3 55 1 58 -5 4 -5 1 -9 -4 -9 -6 0 -11 -4 -11 -10 0 -10 67 17 86 36 7 6 26
22 44 37 20 17 30 33 27 44 -4 15 -6 15 -12 -4 -7 -23 -16 -28 -28 -17 -11 11
13 44 31 44 9 0 23 10 31 21 11 19 11 21 -3 16 -9 -4 -16 -2 -16 3 0 6 -8 10
-17 11 -12 1 -9 4 7 8 17 6 31 3 43 -8 11 -9 14 -17 8 -19 -6 -2 -11 -10 -11
-17 0 -7 -6 -15 -12 -18 -7 -2 -10 -8 -5 -13 4 -4 14 2 22 14 9 11 21 27 29
34 7 7 25 34 41 58 70 114 85 130 85 91 0 -11 9 -23 20 -26 21 -7 28 -35 9
-35 -6 0 -9 -6 -6 -12 2 -7 -1 -27 -8 -45 -8 -17 -11 -33 -9 -36 3 -2 -2 -7
-10 -10 -9 -4 -16 2 -20 16 -5 20 -8 18 -27 -17 -11 -21 -33 -57 -47 -79 -15
-22 -31 -47 -37 -55 -5 -9 -25 -31 -44 -49 -18 -19 -30 -37 -27 -41 4 -3 25
12 46 35 26 27 46 40 57 37 13 -4 23 6 36 36 10 23 15 50 12 61 -4 10 -2 19 4
19 5 0 12 8 14 18 3 9 3 -4 1 -31 -9 -95 54 -125 113 -52 17 21 29 43 27 47
-3 5 1 10 8 13 8 3 29 36 48 73 19 37 38 69 41 72 10 8 20 60 12 60 -5 0 -13
-12 -20 -27 -53 -124 -63 -142 -82 -148 -14 -4 -21 -15 -21 -31 0 -13 -4 -24
-9 -24 -17 0 -30 26 -25 51 5 22 4 22 -10 -5 -9 -15 -16 -39 -16 -52 0 -28
-27 -33 -37 -7 -4 10 2 23 16 34 12 10 25 34 28 53 5 30 8 33 19 21 8 -7 14
-18 14 -24 0 -6 15 -2 33 9 30 18 32 24 32 73 0 50 1 52 25 50 20 -2 28 4 39
30 8 17 23 42 34 54 24 27 17 42 -8 17 -16 -16 -17 -16 -14 2 1 10 -1 27 -5
36 -8 17 19 68 35 68 12 0 11 -17 -1 -25 -14 -8 -13 -42 2 -51 7 -4 27 11 49
37 21 24 39 40 39 36 0 -13 -32 -68 -53 -92 -29 -31 -59 -75 -52 -75 9 0 65
67 99 118 16 26 33 49 36 52 13 12 60 120 66 151 4 19 11 39 16 46 13 17 38
178 38 249 0 34 -7 79 -16 100 -16 40 -165 194 -187 194 -7 0 -6 -4 3 -10 8
-5 11 -10 6 -10 -5 0 3 -9 17 -20 26 -21 26 -21 7 -40 -21 -21 -70 -28 -70
-10 0 6 -6 10 -14 10 -19 0 -27 26 -12 39 10 8 16 5 25 -15 8 -18 18 -24 34
-22 32 4 27 30 -10 49 -18 9 -30 19 -26 23 3 3 -1 6 -10 6 -9 0 -17 5 -17 10
0 14 13 13 28 -2 6 -6 15 -8 19 -4 4 4 1 11 -7 16 -8 4 -26 18 -40 30 -29 25
-148 105 -200 133 -19 11 -37 22 -40 26 -3 3 -23 18 -45 32 -132 85 -190 125
-193 132 -2 4 -8 7 -14 7 -5 0 -21 9 -35 20 -14 11 -30 17 -36 13 -8 -5 -7 -8
1 -11 17 -5 5 -22 -16 -22 -20 1 -72 44 -72 60 0 16 28 12 38 -6 5 -9 17 -14
28 -12 17 3 -24 36 -114 93 -9 6 -22 16 -30 23 -8 7 -24 10 -36 7 -15 -5 -17
-4 -7 3 10 7 5 15 -21 36 -38 28 -48 32 -48 16 0 -6 11 -16 25 -22 14 -6 25
-15 25 -19 0 -11 -53 9 -61 23 -4 6 -12 7 -19 3 -8 -5 -7 -12 6 -26 15 -17 15
-19 1 -19 -9 0 -19 5 -22 10 -3 6 -15 10 -26 10 -25 0 -25 27 0 35 11 3 22 13
26 23 5 14 7 14 15 2 5 -8 12 -11 17 -7 4 5 2 11 -4 15 -24 16 -67 48 -82 62
-10 10 -33 15 -56 15 -34 -2 -45 4 -79 39 -25 26 -46 40 -59 38 -33 -6 -67 7
-67 26 1 16 1 16 14 0 14 -19 66 -25 66 -8 0 6 -9 10 -20 10 -11 0 -20 5 -20
10 0 16 18 12 40 -10 11 -11 24 -20 29 -20 4 0 29 -19 55 -41 26 -23 50 -38
54 -34 4 3 -2 13 -13 21 -80 59 -136 104 -181 146 -21 21 -42 38 -45 38 -4 0
-54 47 -113 104 -101 100 -125 118 -90 68 25 -37 11 -35 -30 3 -21 19 -42 35
-47 35 -5 0 -9 10 -10 23 0 21 -1 21 -9 2 -10 -22 -13 -5 -4 19 3 9 12 12 22
9 14 -5 13 -2 -3 17 -57 67 -66 74 -74 62 -6 -9 -14 -10 -32 -2 -22 10 -23 10
-2 6 39 -7 36 12 -14 72 -26 33 -56 71 -67 85 -45 63 -45 63 -56 47 -8 -12
-10 -9 -8 14 2 18 -2 31 -11 34 -9 3 -11 0 -6 -8 5 -8 4 -11 -3 -6 -6 4 -9 13
-6 20 5 13 -74 146 -86 146 -3 0 -20 -33 -39 -73z m49 31 c-1 -14 -24 -48 -33
-48 -3 0 0 14 6 30 11 30 27 41 27 18z m20 -38 c0 8 11 -3 24 -25 18 -29 22
-43 14 -52 -16 -20 -30 -15 -23 6 3 11 0 23 -7 27 -7 4 -10 4 -6 -1 7 -7 -32
-45 -46 -45 -3 0 -6 9 -6 20 0 13 5 18 15 14 19 -8 31 22 16 40 -6 7 -8 16 -4
20 4 3 10 1 15 -6 6 -10 8 -9 8 2z m-84 -108 c-12 -11 -18 7 -10 30 l8 23 4
-23 c2 -13 1 -26 -2 -30z m1 -44 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m222 -29 c21 -26 30 -44 24 -50 -6 -6 -20 3 -37 26 -15 19 -24 35
-20 35 3 0 1 7 -6 15 -7 8 -8 15 -3 15 5 0 24 -18 42 -41z m-221 -154 c0 -68
-2 -74 -9 -40 -10 53 -10 193 1 150 4 -16 8 -66 8 -110z m292 65 l28 -30 -24
0 c-19 0 -24 5 -24 25 0 14 -4 25 -10 25 -5 0 -10 -7 -10 -17 0 -9 -3 -14 -6
-10 -7 7 2 37 12 37 3 0 19 -13 34 -30z m-29 -47 c13 -16 12 -17 -3 -4 -10 7
-18 15 -18 17 0 8 8 3 21 -13z m106 -59 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6
-6 8 -13 4 -17z m-20 -30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m-4 -38 c6 -16 -2 -28 -14 -20 -12 7 -11 34 0 34 5 0 11 -6 14 -14z m-383
-76 c0 -54 14 -74 42 -59 12 6 18 6 18 -1 0 -5 -4 -10 -9 -10 -5 0 -12 -10
-16 -22 -5 -18 -8 -19 -12 -8 -3 8 -9 17 -12 20 -18 15 -33 59 -38 109 -4 52
-3 55 11 37 9 -12 16 -39 16 -66z m40 30 c0 -16 -4 -30 -10 -30 -11 0 -14 43
-3 53 11 12 13 8 13 -23z m405 -30 c4 -6 -7 -15 -24 -21 -17 -6 -31 -15 -31
-20 0 -5 -7 -9 -15 -9 -21 0 -19 37 3 49 22 13 59 14 67 1z m185 -66 c0 -7 16
-24 35 -38 20 -15 33 -32 29 -37 -4 -7 -15 -3 -28 9 -11 10 -29 22 -38 25 -10
4 -18 16 -18 27 0 11 -3 20 -7 20 -5 0 -16 12 -27 26 l-18 26 36 -24 c20 -13
36 -28 36 -34z m-90 36 c0 -5 8 -10 17 -10 9 0 14 -3 10 -6 -8 -8 -47 5 -47
17 0 5 5 9 10 9 6 0 10 -4 10 -10z m-340 -30 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m348 -33 c2 -10 -3 -17 -12 -17 -10
0 -16 9 -16 21 0 24 23 21 28 -4z m-377 -34 c6 -19 9 -45 7 -57 -3 -12 2 -28
9 -35 8 -7 11 -16 8 -19 -10 -10 -45 40 -45 64 0 12 -9 33 -21 48 -23 29 -18
47 11 39 11 -3 24 -20 31 -40z m-66 -4 c19 -9 19 -9 -2 -5 -18 3 -20 1 -13
-18 4 -11 14 -20 21 -18 10 3 10 0 0 -12 -11 -13 -16 -12 -37 13 -13 16 -24
34 -24 40 0 13 27 13 55 0z m525 -48 c14 -12 20 -21 13 -21 -6 0 -15 5 -18 10
-3 6 -14 10 -23 10 -25 0 -46 16 -38 29 8 12 24 5 66 -28z m120 -10 c0 -8 3
-11 7 -8 9 9 33 -12 33 -29 0 -8 -4 -14 -10 -14 -5 0 -10 7 -10 15 0 10 -10
15 -27 15 -19 0 -24 3 -15 9 11 7 11 9 -2 14 -9 4 -16 13 -16 22 0 13 3 13 20
3 11 -7 20 -19 20 -27z m-42 -9 c2 -6 -6 -10 -16 -9 -12 1 -18 6 -15 14 6 16
25 13 31 -5z m-571 -18 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m223 -13 c0 -11 -4 -22 -9 -25 -11 -7 -23 22 -15 35 9 16 24 10 24 -10z m-185
-22 c12 -19 -5 -23 -22 -6 -16 16 -16 17 -1 17 9 0 20 -5 23 -11z m115 -55 c0
-29 -11 -30 -34 -5 -14 15 -23 18 -42 11 -27 -11 -35 -3 -23 25 6 17 8 17 8 3
1 -21 21 -24 21 -3 0 7 8 19 18 24 21 13 52 -19 52 -55z m-40 -30 c0 -14 -3
-14 -15 -4 -8 7 -15 14 -15 16 0 2 7 4 15 4 8 0 15 -7 15 -16z m-380 -4 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m490 -40 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m160 0
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m525 -36 c30 -19 32 -34 6 -34 -10 0 -21 -7 -25 -15 -8 -21 -36 -7 -36 17 0
17 5 19 28 14 22 -5 24 -4 10 4 -17 10 -25 30 -13 30 3 0 17 -7 30 -16z m-555
-9 c7 -9 10 -19 6 -22 -8 -9 -38 9 -44 25 -5 17 23 15 38 -3z m-648 -108 c1
-59 -1 -109 -5 -113 -4 -4 -7 46 -7 111 0 65 2 116 5 113 3 -3 6 -53 7 -111z
m218 43 c0 -5 12 -16 28 -24 15 -8 20 -15 12 -15 -8 0 -28 11 -45 24 -23 19
-26 25 -12 25 9 0 17 -4 17 -10z m-30 -30 c0 -5 -7 -10 -15 -10 -8 0 -15 5
-15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m570 -20 c0 -5 -4 -10 -9 -10 -6 0
-13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-535 -25 c0 -10 -6 -20 -12
-22 -8 -3 -13 5 -13 22 0 17 5 25 13 23 6 -3 12 -13 12 -23z m100 -5 c3 -5 12
-7 20 -4 8 4 18 -1 21 -10 3 -9 12 -16 20 -16 8 0 14 -4 14 -8 0 -5 18 -19 41
-31 22 -13 38 -25 35 -28 -3 -3 -21 4 -41 16 -20 11 -45 21 -56 21 -13 0 -19
6 -17 17 2 10 -5 19 -16 21 -12 3 -16 0 -11 -8 5 -7 -2 -10 -22 -8 -32 3 -57
36 -41 55 10 13 40 3 53 -17z m1015 10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m95 -20 l50 -30 -38 0 c-23 0 -36 4 -32
10 3 6 -1 10 -9 10 -8 0 -20 9 -26 20 -14 27 -4 25 55 -10z m-595 10 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-40 -49 c0
-5 9 -11 20 -14 11 -3 20 -10 20 -15 0 -6 -9 -8 -20 -5 -11 3 -20 9 -20 13 0
4 -11 11 -25 14 -16 4 -22 11 -19 21 5 11 11 12 25 4 10 -5 19 -14 19 -18z
m-774 -99 c-12 -11 -14 4 -8 53 l7 50 3 -49 c2 -26 1 -51 -2 -54z m233 74 c33
-17 40 -24 27 -29 -20 -8 -85 23 -86 41 0 16 8 15 59 -12z m1110 10 c41 -22
56 -42 35 -50 -9 -4 -24 0 -33 7 -9 8 -24 17 -33 20 -19 7 -25 37 -8 37 6 0
23 -6 39 -14z m-1014 -34 c0 -18 -20 -15 -23 4 -3 10 1 15 10 12 7 -3 13 -10
13 -16z m1133 -11 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2
15 -10 17 -17z m-856 -55 c68 -35 152 -74 188 -86 36 -12 70 -27 77 -32 7 -5
17 -7 22 -4 5 4 12 2 16 -4 3 -5 1 -10 -4 -10 -6 0 -11 -12 -11 -26 0 -22 -2
-25 -15 -14 -8 7 -15 18 -15 26 0 17 -39 39 -64 35 -30 -4 -68 8 -60 21 4 6 3
8 -4 5 -11 -7 -92 41 -92 54 0 5 -24 7 -52 6 -49 -2 -53 0 -56 22 -2 13 -12
26 -23 28 -10 3 -19 11 -19 18 0 7 -6 16 -12 18 -7 3 -10 6 -6 6 4 1 63 -28
130 -63z m-155 48 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m452
-18 c19 -11 21 -14 7 -19 -9 -4 -16 -3 -16 2 0 4 -10 13 -22 19 -14 6 -17 11
-8 11 8 0 26 -6 39 -13z m64 7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2
13 -5z m21 -45 c11 -10 16 -21 12 -25 -4 -5 -13 -1 -19 8 -7 8 -20 18 -29 22
-10 3 -18 11 -18 16 0 13 30 2 54 -21z m-977 -25 c-3 -10 -5 -4 -5 12 0 17 2
24 5 18 2 -7 2 -21 0 -30z m1633 27 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0
6 7 10 15 10 8 0 15 -4 15 -10z m50 -27 c14 -9 29 -23 33 -30 4 -7 15 -13 22
-13 8 0 24 -9 35 -20 22 -22 19 -25 -8 -10 -14 8 -64 28 -82 33 -3 0 -14 9
-24 19 -11 10 -24 18 -28 18 -5 0 -6 5 -3 10 9 15 27 12 55 -7z m-1617 -10
c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-13 -54 c0 -13 5 -19
13 -16 8 3 14 -3 14 -14 2 -24 26 -49 47 -49 23 0 20 31 -4 37 -11 3 -20 9
-20 14 0 5 9 9 20 9 11 0 20 -5 20 -11 0 -7 11 -8 32 -4 30 7 31 6 15 -9 -9
-10 -18 -20 -18 -24 -8 -47 -6 -59 8 -58 9 0 18 -5 20 -11 9 -24 -15 -13 -71
33 -31 25 -56 52 -56 60 0 7 -5 14 -10 14 -6 0 -13 8 -17 18 -3 9 -13 26 -22
36 -15 18 -15 18 7 7 12 -6 22 -21 22 -32z m27 19 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m553 -3 c0 -8 -4 -15 -9 -15 -5 0 -24 -3 -42 -7
-30 -6 -33 -5 -21 10 12 14 23 18 65 26 4 0 7 -6 7 -14z m995 4 c10 -15 -1
-23 -20 -15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z m-448 -45 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m423 -3 c0 -6 -4 -12 -9 -15 -13 -8
-42 4 -35 15 7 12 44 12 44 0z m-335 -22 c5 -5 17 -9 26 -9 9 0 22 -7 29 -15
18 -22 -6 -20 -58 5 -22 11 -45 20 -51 20 -5 0 -13 5 -17 11 -8 13 55 2 71
-12z m118 12 c10 -1 15 -5 12 -11 -8 -12 -62 -13 -69 -1 -8 12 3 20 22 16 9
-2 25 -4 35 -4z m484 -26 c21 -14 44 -29 51 -35 6 -6 12 -8 12 -4 0 4 9 -2 20
-14 12 -12 26 -22 31 -22 6 0 22 -9 35 -19 l25 -20 -30 1 c-17 1 -28 4 -26 9
3 4 -12 13 -32 19 -55 18 -104 50 -96 63 4 6 0 8 -9 4 -17 -6 -38 10 -38 30 0
19 14 16 57 -12z m-943 0 c-7 -18 0 -19 28 -4 16 9 24 7 37 -6 9 -10 11 -15 4
-11 -7 4 -24 4 -38 0 -21 -5 -24 -9 -13 -16 10 -7 8 -8 -5 -4 -10 3 -24 6 -31
6 -7 0 -12 10 -10 23 3 29 2 27 19 27 9 0 12 -6 9 -15z m170 -45 c7 -7 2 -10
-14 -10 -14 0 -43 -7 -65 -14 -24 -9 -44 -11 -49 -5 -5 5 -8 10 -5 11 51 32
68 38 94 33 16 -3 34 -10 39 -15z m-859 -1 c11 -17 2 -18 -19 -3 -17 12 -17
14 -4 14 9 0 20 -5 23 -11z m603 -12 c2 -12 -3 -17 -17 -17 -12 0 -21 6 -21
13 0 31 32 34 38 4z m329 -7 c29 0 83 -27 83 -40 0 -15 -99 -12 -114 3 -6 6
-12 22 -12 34 0 15 4 19 10 13 6 -6 21 -10 33 -10z m647 0 c25 -12 46 -27 46
-35 0 -17 -36 -20 -45 -4 -4 5 -18 14 -31 19 -13 5 -24 16 -24 25 0 19 -2 19
54 -5z m-1505 -34 c-2 -2 -15 -6 -29 -10 -25 -6 -25 -6 -2 5 12 7 22 17 23 23
0 6 3 5 6 -2 3 -7 3 -15 2 -16z m1601 9 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15
-3 8 -1 15 4 15 6 0 10 -7 10 -15z m-1670 -5 c0 -5 -7 -10 -15 -10 -8 0 -15 5
-15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m680 -10 c0 -5 -13 -10 -30 -10 -16
0 -30 5 -30 10 0 6 14 10 30 10 17 0 30 -4 30 -10z m165 0 c4 -6 -10 -10 -34
-10 -23 0 -41 5 -41 10 0 6 15 10 34 10 19 0 38 -4 41 -10z m870 0 c3 -5 -3
-10 -15 -10 -12 0 -18 5 -15 10 3 6 10 10 15 10 5 0 12 -4 15 -10z m-635 -4
c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z
m-962 -13 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z m46 -8 c-4
-8 -11 -12 -16 -9 -6 4 -5 10 3 15 19 12 19 11 13 -6z m126 -5 c0 -23 28 -40
65 -40 34 0 22 -20 -13 -21 -27 -1 -27 -1 3 -8 35 -7 41 -34 8 -39 -12 -2 -40
3 -63 11 l-42 14 31 13 c40 16 27 35 -29 44 -38 6 -57 26 -25 26 8 0 15 5 15
10 0 6 11 10 25 10 18 0 25 -5 25 -20z m-151 -24 c6 -7 29 -16 51 -20 22 -3
40 -11 40 -17 0 -5 6 -7 13 -3 9 5 9 4 0 -6 -12 -13 -58 0 -148 41 l-40 18 36
1 c20 0 42 -6 48 -14z m1094 -1 c23 -10 22 -25 -3 -25 -11 0 -20 4 -20 10 0 5
-12 7 -27 4 -17 -3 -36 1 -48 10 -19 14 -17 15 30 12 28 -2 58 -7 68 -11z
m-86 -31 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-750 -10 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m963 -28 c0 -14 -29 -26
-67 -26 -13 0 -23 6 -23 14 0 8 10 17 23 19 46 9 67 7 67 -7z m643 -43 c33
-35 64 -79 81 -116 15 -33 16 -53 10 -140 -6 -80 -9 -96 -16 -72 -4 17 -6 45
-3 63 3 17 7 48 10 67 5 27 2 35 -10 35 -11 0 -15 -10 -15 -36 0 -45 -16 -36
-22 13 -3 26 0 41 10 49 10 8 13 17 7 30 -4 10 -7 23 -6 27 1 5 -8 5 -20 2
-18 -6 -20 -4 -14 15 5 15 0 27 -16 41 -12 10 -26 19 -31 19 -5 0 -6 5 -3 10
9 14 19 12 38 -7z m-1338 -13 c-16 -4 -43 -8 -60 -8 -29 1 -29 1 5 8 19 4 46
7 60 8 22 0 22 -1 -5 -8z m280 -6 c-55 -8 -111 -13 -125 -11 -17 3 -12 5 15 7
22 1 54 6 70 10 17 4 55 8 85 8 45 0 37 -3 -45 -14z m-865 -86 c35 -13 239
-43 280 -42 37 1 41 0 21 -8 -15 -5 -53 -5 -99 2 -41 6 -103 8 -136 6 -44 -3
-50 -2 -21 3 l40 7 -35 7 c-19 4 -42 6 -50 5 -39 -4 -59 2 -54 17 6 14 20 15
54 3z m1120 -28 c0 -5 12 -10 28 -10 15 0 34 -5 42 -11 11 -8 8 -9 -15 -5 -16
3 -55 9 -85 13 -58 8 -58 7 23 22 4 0 7 -3 7 -9z m-130 -10 c0 -5 -22 -7 -52
-3 -72 8 -73 13 -5 13 32 0 57 -4 57 -10z m-472 -17 c-15 -2 -42 -2 -60 0 -18
2 -6 4 27 4 33 0 48 -2 33 -4z m762 -13 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20
10 0 6 9 10 20 10 11 0 20 -4 20 -10z m-54 -35 c10 -8 27 -15 37 -15 9 0 17
-7 17 -16 0 -11 -6 -14 -22 -8 -13 3 -28 11 -35 16 -7 5 -31 8 -54 7 -26 -2
-44 1 -46 8 -8 23 74 30 103 8z m714 -85 c3 -64 1 -72 -10 -65 -5 3 -10 34
-10 68 0 70 17 68 20 -3z m-2025 39 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6
0 14 -5 17 -11z m1200 -5 c10 -14 17 -15 47 -5 22 7 48 8 74 3 l39 -9 -40 -7
c-69 -12 -195 -15 -195 -5 0 5 11 9 24 9 38 0 41 18 4 23 l-33 4 34 1 c21 1
38 -4 46 -14z m-1231 -20 c18 -18 20 -30 6 -39 -5 -3 -10 1 -10 10 0 9 -9 19
-20 22 -11 3 -17 9 -14 14 8 13 21 11 38 -7z m106 -19 c0 -8 -6 -15 -14 -15
-7 0 -19 -7 -26 -15 -17 -21 -32 -14 -37 16 -2 17 0 20 6 10 10 -15 61 -8 61
9 0 6 2 10 5 10 3 0 5 -7 5 -15z m170 -35 c0 -5 18 -10 40 -10 22 0 40 -4 40
-8 0 -10 -97 -9 -106 1 -8 7 4 27 17 27 5 0 9 -4 9 -10z m310 -1 c0 -5 -30 -9
-66 -9 -36 0 -63 3 -61 8 7 10 127 12 127 1z m200 7 c0 -2 -7 -6 -15 -10 -8
-3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m103 -3 c-7 -2 -21 -2 -30 0
-10 3 -4 5 12 5 17 0 24 -2 18 -5z m145 -6 c-46 -17 -105 -24 -149 -17 -43 7
-43 8 21 10 51 2 58 4 35 10 -21 4 -7 7 43 6 53 0 67 -2 50 -9z m-620 -22 c3
-3 -7 -5 -21 -5 -15 0 -27 4 -27 9 0 10 38 7 48 -4z m1677 -71 c-17 -20 -21
-21 -24 -7 -2 9 4 22 13 28 9 7 16 21 17 31 1 14 3 13 8 -5 5 -17 1 -31 -14
-47z m-41 -16 c4 -24 3 -38 -4 -38 -5 0 -10 19 -10 42 0 49 5 47 14 -4z m-879
-22 c36 -31 38 -35 21 -42 -13 -5 -23 -2 -31 9 -7 10 -17 17 -24 17 -6 0 -11
7 -11 15 0 10 -10 15 -27 15 -23 0 -25 -2 -13 -11 12 -8 10 -9 -7 -4 -15 4
-23 2 -23 -5 0 -7 -7 -9 -17 -6 -9 4 -20 2 -24 -5 -5 -8 -12 -8 -25 -1 -17 9
-16 11 11 17 17 3 41 12 55 20 41 22 72 17 115 -19z m85 14 c-20 -13 -60 -13
-60 0 0 6 17 10 38 10 27 0 33 -3 22 -10z m132 -9 c11 -1 -3 -31 -14 -31 -5 0
-6 5 -3 10 4 6 -8 10 -27 11 -27 0 -29 2 -13 8 11 5 27 7 35 5 8 -1 18 -3 22
-3z m-292 -31 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m256 -3 c3 -3 -3 -10 -13 -15 -12 -6 -30 -4 -57 8 l-39 18 52 -3
c29 -1 54 -5 57 -8z m705 -48 c-11 -24 -20 -36 -20 -26 -1 24 30 95 35 81 3
-6 -4 -31 -15 -55z m-1141 32 c0 -5 -10 -12 -22 -16 l-23 -8 23 -4 c32 -6 27
-21 -5 -17 -16 2 -20 1 -10 -2 25 -8 21 -24 -8 -24 -42 0 -73 -20 -67 -43 5
-18 2 -19 -24 -13 -16 4 -46 10 -67 13 -20 3 -40 9 -42 14 -6 9 8 13 94 26 52
8 54 9 37 26 -10 9 -23 14 -29 10 -6 -4 -30 -8 -52 -9 l-40 -3 40 14 c22 7 49
14 61 14 11 1 24 5 30 10 5 5 27 11 49 14 22 2 43 4 48 5 4 1 7 -2 7 -7z m345
-1 c3 -5 12 -7 19 -4 8 3 17 1 21 -5 4 -7 -7 -11 -29 -11 -34 0 -52 11 -39 24
9 9 21 7 28 -4z m215 2 c0 -4 -6 -14 -12 -20 -10 -10 -10 -15 2 -23 11 -7 5
-8 -21 -4 -31 6 -40 3 -65 -23 -16 -16 -42 -33 -59 -36 -28 -6 -29 -5 -11 9
11 8 24 15 29 15 5 0 18 11 29 25 11 14 25 25 33 25 7 0 23 9 36 20 26 21 39
25 39 12z m-362 -34 c-3 -7 -14 -14 -26 -16 -16 -3 -20 1 -16 12 7 19 48 22
42 4z m117 -7 c-7 -12 -64 -41 -80 -41 -7 0 -19 7 -26 16 -12 15 -11 16 8 10
13 -4 33 -1 50 8 32 17 57 21 48 7z m-565 -27 c0 -6 -77 -26 -155 -39 -72 -12
-76 -13 -35 -15 28 -2 36 -4 21 -7 -30 -6 -141 18 -141 30 0 5 18 7 40 5 22
-2 40 0 40 4 0 5 19 8 43 8 23 0 64 6 92 13 43 11 95 12 95 1z m460 -23 c0
-12 -29 -23 -41 -15 -5 3 -7 10 -4 15 8 12 45 12 45 0z m270 -14 c0 -19 -41
-36 -65 -26 -20 7 -20 8 1 8 11 1 29 8 40 16 24 18 24 18 24 2z m-1007 -24
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m497 -3 c5 8 9 7 14 -5
4 -12 1 -16 -11 -13 -9 2 -18 9 -19 16 -2 8 0 8 3 1 4 -8 7 -8 13 1z m148 2
c9 -6 6 -11 -10 -19 -38 -19 -86 -32 -92 -26 -13 13 -5 23 18 23 14 0 28 5 31
10 4 6 11 8 16 5 5 -4 9 -1 9 4 0 13 10 14 28 3z m-228 -32 c0 -17 -25 -25
-87 -27 -49 -2 -63 0 -63 11 0 16 21 22 98 24 28 1 52 -3 52 -8z m430 2 c0 -8
-43 -32 -55 -32 -13 0 -2 17 18 26 29 15 37 16 37 6z m-323 -27 c-4 -8 -18
-15 -34 -15 l-28 0 30 19 c33 20 40 19 32 -4z m323 -5 c0 -5 -5 -10 -11 -10
-5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m79 -6 c2 -2 -9 -15 -25 -29
-36 -31 -84 -34 -84 -5 0 11 7 20 15 20 9 0 18 7 21 15 4 10 15 12 37 8 18 -3
34 -7 36 -9z m-879 -15 c0 -24 -23 -21 -28 4 -2 10 3 17 12 17 10 0 16 -9 16
-21z m739 3 c-7 -3 -22 -11 -32 -20 -9 -8 -20 -12 -25 -8 -11 12 37 46 55 39
14 -6 14 -7 2 -11z m-824 -37 c27 -24 35 -35 23 -35 -9 0 -21 6 -25 13 -4 6
-20 18 -35 26 -16 8 -28 18 -28 23 0 17 26 6 65 -27z m390 25 c-3 -5 1 -10 9
-10 9 0 16 4 16 9 0 5 21 9 48 9 l47 0 -55 -17 c-78 -26 -130 -34 -130 -21 0
5 10 11 23 11 l22 2 -22 4 c-36 7 -27 23 13 23 21 0 33 -4 29 -10z m592 -21
c-11 -29 -12 -29 -15 -9 -3 18 10 38 25 40 1 0 -4 -14 -10 -31z m41 -10 c-10
-5 -18 -17 -18 -26 0 -12 -3 -14 -10 -7 -16 16 -1 44 24 44 19 -1 19 -2 4 -11z
m-298 -9 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m500 -32 c0 -5 -7 -17 -16 -25 -13 -13 -17 -14 -24 -3 -12 19 7 51 25 44
8 -4 15 -10 15 -16z m-584 -15 c-2 -17 -11 -29 -25 -35 -12 -4 -19 -12 -16
-18 3 -5 -3 -10 -15 -10 -12 0 -18 5 -15 10 3 6 -1 10 -10 10 -9 0 -13 -4 -10
-10 8 -12 -5 -13 -24 -1 -11 7 -10 11 7 20 11 6 32 11 47 11 15 0 28 7 32 18
5 14 21 31 30 32 1 0 0 -12 -1 -27z m61 21 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m573 -14 c0 -5 -7 -10 -15 -10 -10 0 -15 -10 -15 -29 0
-17 -4 -33 -10 -36 -15 -9 -12 53 3 70 14 17 37 20 37 5z m70 1 c0 -6 -4 -13
-10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m17 -23 c-3 -7 -5
-2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m20 -30 c-2 -13 -4 -3 -4 22 0 25
2 35 4 23 2 -13 2 -33 0 -45z m-619 30 c8 -8 9 -15 1 -25 -18 -21 -39 -15 -39
12 0 27 18 33 38 13z m100 -57 c-7 -2 -27 -15 -44 -28 -34 -26 -94 -32 -94 -9
0 8 21 25 47 38 39 21 51 23 75 14 16 -6 23 -13 16 -15z m349 -12 c1 -12 -3
-16 -13 -12 -8 3 -14 16 -14 29 0 20 3 22 13 13 7 -7 14 -20 14 -30z m-727 11
c-9 -18 -40 -29 -40 -15 0 11 25 34 38 35 10 0 10 -5 2 -20z m-85 -45 c-3 -25
17 -35 30 -15 3 6 15 10 26 10 10 0 19 -4 19 -10 0 -5 16 -10 36 -10 31 0 35
2 24 15 -14 16 -6 35 16 35 8 0 14 -10 14 -25 0 -20 5 -25 28 -26 23 0 24 -1
7 -8 -11 -4 -77 -7 -147 -7 -95 1 -128 -2 -128 -11 0 -16 46 -47 59 -39 5 4
12 2 16 -4 3 -5 31 -10 61 -10 30 0 54 -4 54 -10 0 -9 -77 -5 -110 7 -8 3 -29
6 -46 7 -36 3 -81 41 -58 50 23 9 16 25 -8 19 -22 -4 -23 -4 -4 11 23 19 31
19 56 6 13 -7 21 -7 25 0 3 5 1 10 -4 10 -6 0 -11 4 -11 9 0 8 14 15 41 20 3
0 5 -10 4 -24z m289 -40 c6 -15 -21 -35 -48 -35 -10 0 -13 6 -9 17 4 11 8 13
11 5 2 -7 8 -10 12 -7 5 3 7 12 3 20 -3 9 1 15 10 15 8 0 17 -7 21 -15z m53 9
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m233 -31 c0 -8 -4 -12
-9 -9 -5 3 -11 -1 -15 -9 -3 -8 0 -15 6 -15 6 0 9 -3 6 -6 -4 -3 -18 1 -33 9
l-28 14 21 15 c27 18 52 19 52 1z m-390 -38 c-32 -19 -44 -19 -28 0 7 8 22 14
33 14 18 0 17 -1 -5 -14z m490 -15 c13 -8 12 -11 -4 -23 -21 -15 -60 -11 -50
5 3 5 13 7 21 4 11 -4 13 -2 9 9 -6 17 2 19 24 5z m-220 -20 c0 -14 -7 -20
-22 -20 -20 0 -21 1 -3 20 10 11 20 20 22 20 1 0 3 -9 3 -20z m382 -22 c-6 -6
-15 -8 -19 -4 -4 4 -1 11 7 16 19 12 27 3 12 -12z m-412 -20 c8 -3 -2 -5 -22
-5 -31 1 -34 3 -20 13 9 7 19 9 22 5 3 -5 12 -10 20 -13z"/>
<path d="M1840 5620 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2435 5390 c-8 -13 9 -20 24 -11 10 6 9 10 -3 14 -8 3 -18 2 -21 -3z"/>
<path d="M1810 5265 c0 -10 41 -25 52 -19 8 5 5 10 -8 15 -22 9 -44 11 -44 4z"/>
<path d="M2305 4660 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1948 4593 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M2640 4309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7092 6949 c2 -8 15 -16 29 -17 13 -2 30 -9 37 -14 18 -16 28 0 11
17 -21 21 -83 32 -77 14z"/>
<path d="M7120 6905 c0 -8 7 -15 16 -15 14 0 14 3 4 15 -7 8 -14 15 -16 15 -2
0 -4 -7 -4 -15z"/>
<path d="M7182 6892 c-21 -16 -22 -20 -8 -31 12 -10 19 -11 30 -2 11 10 17 7
30 -11 9 -12 16 -28 16 -36 0 -7 5 -10 10 -7 18 11 11 33 -22 69 -32 35 -33
35 -56 18z"/>
<path d="M6800 6742 c0 -5 14 -20 32 -33 17 -13 72 -63 121 -111 84 -81 88
-87 68 -98 -20 -10 -19 -12 25 -55 25 -25 43 -45 39 -45 -3 0 1 -7 10 -17 9
-9 35 -41 58 -72 22 -31 43 -58 47 -61 3 -3 19 -27 35 -55 15 -27 35 -59 42
-70 14 -19 15 -19 8 4 -5 16 -4 21 4 17 6 -4 11 -2 11 4 0 7 -5 10 -12 8 -12
-4 -42 37 -54 75 -4 9 -10 17 -15 17 -5 0 -9 7 -9 15 0 26 17 16 34 -20 9 -19
27 -47 41 -63 17 -20 23 -37 20 -60 -3 -23 12 -63 61 -158 35 -70 64 -120 64
-110 0 20 -41 110 -52 114 -16 7 -8 22 12 22 13 0 20 -7 20 -20 0 -11 5 -20
10 -20 15 0 14 3 -26 77 -74 138 -114 200 -209 326 -92 121 -112 144 -228 259
-102 101 -157 147 -157 130z m280 -278 c0 -6 8 -18 18 -27 10 -9 36 -43 57
-76 22 -33 43 -57 47 -55 4 3 8 -2 8 -10 0 -24 -18 -28 -25 -6 -3 11 -11 20
-16 20 -5 0 -9 5 -9 10 0 6 -11 23 -25 38 -14 15 -25 32 -25 39 0 6 -20 29
-45 51 -28 25 -41 42 -33 44 16 6 48 -13 48 -28z m264 -380 c9 -8 16 -16 16
-17 0 -1 4 -17 9 -35 13 -43 7 -40 -20 8 -13 22 -26 40 -31 40 -4 0 -8 5 -8
10 0 15 16 12 34 -6z"/>
<path d="M3495 6730 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6910 6586 c51 -51 70 -60 70 -33 0 7 -4 6 -10 -3 -7 -12 -10 -11
-16 5 -9 26 -69 85 -86 85 -6 0 12 -24 42 -54z"/>
<path d="M3500 6621 c0 -5 7 -11 15 -15 9 -3 15 0 15 9 0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z"/>
<path d="M3480 6576 c-5 -13 -14 -27 -19 -31 -6 -3 -11 -12 -11 -18 1 -13 60
52 60 65 0 18 -21 6 -30 -16z"/>
<path d="M3868 6559 c-10 -10 -18 -21 -18 -23 0 -2 -25 -29 -55 -61 -30 -31
-55 -60 -55 -64 0 -4 -11 -18 -25 -31 -14 -13 -25 -30 -25 -37 0 -8 -5 -11
-12 -7 -8 5 -9 2 -5 -9 4 -11 1 -17 -8 -17 -8 0 -15 -4 -15 -9 0 -5 -21 -37
-47 -72 -26 -35 -46 -70 -45 -76 2 -7 -1 -13 -7 -13 -16 0 -5 45 20 81 36 51
103 134 112 137 4 2 7 10 7 17 0 7 5 15 12 17 7 3 4 7 -7 12 -11 4 -16 4 -13
0 4 -3 -6 -20 -22 -38 -17 -17 -30 -37 -30 -43 0 -7 -9 -18 -20 -25 -11 -7
-20 -16 -20 -20 0 -5 -15 -29 -34 -55 -40 -58 -82 -131 -138 -240 -41 -81 -42
-83 -23 -97 11 -8 22 -12 25 -10 2 3 -1 8 -7 12 -13 8 -12 23 5 65 7 16 12 33
12 38 0 6 5 7 10 4 6 -3 10 2 10 12 0 11 4 23 10 29 5 5 23 35 39 67 21 41 31
52 35 41 7 -18 -49 -114 -66 -114 -5 0 -6 -5 -3 -11 4 -5 -1 -27 -11 -47 -10
-20 -19 -48 -20 -62 l-2 -25 10 25 c19 44 63 135 68 140 8 8 70 111 70 116 0
3 5 10 10 17 6 7 40 52 75 101 76 104 143 186 197 239 35 35 48 58 31 56 -5 0
-16 -9 -25 -20z"/>
<path d="M7370 6532 c0 -5 9 -17 20 -27 16 -14 20 -15 20 -4 0 16 -19 39 -32
39 -4 0 -8 -4 -8 -8z"/>
<path d="M6985 6510 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1866 6461 c-7 -10 28 -44 37 -35 7 7 -14 44 -25 44 -4 0 -9 -4 -12
-9z"/>
<path d="M9535 6011 c-3 -5 2 -11 10 -15 8 -3 17 -1 20 3 3 5 -2 11 -10 15 -8
3 -17 1 -20 -3z"/>
<path d="M7434 5919 c5 -13 8 -32 9 -41 1 -10 7 -18 15 -18 15 0 7 42 -9 48
-5 2 -7 8 -4 12 3 5 0 12 -6 16 -9 5 -10 0 -5 -17z"/>
<path d="M1790 5875 c7 -8 17 -15 22 -15 6 0 5 7 -2 15 -7 8 -17 15 -22 15 -6
0 -5 -7 2 -15z"/>
<path d="M3602 5853 c19 -43 60 -114 64 -111 7 7 -23 74 -41 92 -15 15 7 16
262 14 l278 -3 -242 -2 c-222 -3 -243 -5 -244 -21 -5 -67 4 -458 10 -462 4 -3
8 -282 9 -620 1 -498 -1 -617 -12 -630 -7 -8 -25 -36 -40 -61 l-26 -46 25 0
c14 0 27 6 30 16 4 14 17 15 82 11 99 -6 95 -25 -5 -25 -39 0 -72 -3 -72 -7 0
-5 -13 -8 -30 -8 -16 0 -30 -4 -30 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -3 -15
-7 0 -5 153 -7 340 -5 187 1 340 6 340 10 0 4 -14 25 -30 46 -17 22 -30 44
-30 49 0 5 -9 21 -20 37 -19 26 -19 49 -22 682 -3 698 -4 692 43 622 11 -17
19 -23 19 -13 0 8 -5 20 -11 26 -6 6 -8 17 -4 23 5 8 14 -1 26 -26 16 -35 16
-40 1 -45 -11 -4 -12 -8 -4 -13 7 -4 28 -31 48 -59 23 -33 33 -43 29 -27 -4
14 -18 40 -32 58 -15 20 -19 32 -11 32 21 -1 50 -48 54 -91 7 -60 159 -284
194 -285 14 -1 33 -5 43 -9 24 -10 120 3 129 18 14 22 8 30 -10 14 -13 -12
-35 -17 -73 -16 -30 1 -40 4 -24 6 59 7 75 14 75 30 0 9 14 32 32 50 34 35 47
83 22 83 -23 0 -18 61 6 67 11 3 20 14 20 24 0 35 25 99 38 99 7 0 10 5 6 11
-6 10 12 29 29 29 17 0 6 -42 -12 -47 -11 -3 -22 -12 -25 -20 -7 -18 12 -56
25 -48 5 4 9 1 9 -4 0 -21 18 -11 29 17 7 15 18 38 26 52 8 14 15 42 15 63 0
22 4 36 10 32 5 -3 7 -13 4 -23 -5 -15 -4 -16 8 -1 17 20 58 34 58 20 0 -6 -7
-11 -15 -11 -9 0 -18 -12 -22 -30 -5 -28 -7 -29 -20 -13 -13 16 -14 16 -8 -10
4 -19 1 -29 -10 -33 -8 -4 -15 -12 -15 -20 0 -20 -49 -88 -65 -90 -8 -1 -15 2
-15 7 0 12 -27 11 -35 -2 -12 -19 -17 -91 -6 -84 6 3 11 17 11 31 0 13 5 24
11 24 14 0 2 -52 -14 -62 -7 -4 -12 -11 -12 -15 -1 -4 -11 -27 -23 -50 -12
-24 -18 -43 -12 -43 6 0 13 8 17 18 3 9 16 30 27 47 12 16 26 37 31 46 43 73
162 257 172 267 7 7 13 18 13 23 0 5 8 9 19 9 17 0 18 -10 19 -127 1 -71 4
-366 8 -658 6 -514 6 -531 -13 -555 -11 -14 -32 -41 -47 -62 l-27 -36 43 5
c24 3 49 13 56 22 12 14 13 13 10 -8 -2 -13 3 -26 10 -28 6 -3 12 0 12 6 0 8
60 11 190 11 l190 0 0 25 c0 14 5 25 11 25 7 0 9 -11 6 -29 -5 -24 -2 -30 16
-35 45 -12 59 -8 52 17 -6 22 -6 22 15 -2 16 -19 18 -26 8 -32 -17 -11 -4 -21
20 -17 17 3 13 12 -32 78 l-51 75 0 783 c0 430 -3 782 -7 782 -5 0 -8 -355 -8
-789 0 -435 -3 -792 -7 -794 -5 -3 -8 354 -8 794 0 750 1 799 17 799 12 0 31
26 57 80 l40 80 -289 0 c-160 0 -290 -4 -290 -8 0 -5 -8 -17 -18 -28 -10 -10
-24 -32 -32 -49 -9 -16 -37 -62 -62 -102 -26 -40 -50 -84 -54 -98 -4 -15 -13
-29 -21 -32 -11 -5 -56 -76 -58 -93 0 -3 -12 -21 -28 -41 -28 -37 -35 -55 -17
-44 16 10 25 -26 9 -37 -11 -7 -11 -11 -1 -15 26 -10 10 -21 -27 -18 -26 2
-41 -1 -45 -10 -7 -20 30 -110 48 -113 23 -5 21 -42 -2 -42 -20 0 -42 33 -44
67 -2 14 -7 19 -18 15 -12 -4 -13 -2 -2 9 6 7 12 20 11 28 0 12 -5 9 -16 -7
l-16 -23 -26 29 c-14 15 -37 48 -50 73 -26 49 -48 75 -38 47 7 -20 -1 -48 -13
-48 -5 0 -12 10 -16 23 -3 12 -15 34 -25 49 -20 28 -24 48 -10 48 5 0 12 -11
15 -25 4 -14 11 -25 17 -25 17 0 2 48 -27 85 -13 17 -24 37 -24 43 0 6 -3 12
-8 14 -4 2 -21 24 -39 51 -22 33 -32 43 -37 32 -3 -8 -15 -15 -26 -15 -14 0
-19 4 -14 12 5 8 2 9 -9 5 -21 -8 -33 14 -42 78 l-8 50 22 -19 c11 -11 21 -27
21 -38 0 -10 5 -18 10 -18 6 0 17 -15 25 -32 12 -28 14 -29 15 -10 0 24 -112
210 -118 193 -2 -5 2 -19 8 -30 12 -23 1 -28 -19 -8 -10 10 -11 7 -6 -15 5
-17 3 -28 -3 -28 -6 0 -10 21 -10 50 1 28 5 50 10 50 4 0 8 7 8 15 0 13 -39
15 -291 15 -160 0 -289 -3 -287 -7z m1912 -74 c-32 -64 -56 -86 -65 -61 -9 24
0 42 17 35 8 -3 14 1 14 8 0 15 51 81 58 75 2 -2 -9 -28 -24 -57z m-508 25
c-3 -9 -6 -22 -6 -30 0 -8 -9 -14 -19 -14 -25 0 -31 -18 -24 -71 5 -36 3 -42
-8 -31 -7 6 -19 12 -26 12 -19 0 -6 -52 18 -69 25 -18 26 -54 2 -49 -11 2 -19
14 -21 31 -5 43 -44 33 -55 -14 -4 -15 -13 -29 -21 -32 -12 -5 -13 -1 -3 26
11 34 47 99 58 107 4 3 21 32 38 65 17 33 31 53 32 45 0 -8 5 -3 11 13 5 15
14 27 20 27 6 0 7 -7 4 -16z m27 -24 c-8 -29 -21 -25 -15 5 2 14 8 23 12 20 5
-3 6 -14 3 -25z m-859 -85 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4
15 2 0 6 -7 10 -15z m18 -110 c6 -13 19 -27 28 -30 17 -6 64 -70 75 -104 7
-24 34 -35 51 -20 11 9 14 7 14 -10 0 -14 9 -25 25 -31 14 -5 25 -14 25 -20 0
-16 -47 -12 -54 5 -3 8 -18 15 -34 15 -23 0 -31 7 -48 43 -10 23 -19 49 -19
57 0 8 -12 21 -28 28 -15 7 -27 19 -27 26 0 8 -9 16 -20 19 -12 3 -20 14 -20
26 0 29 18 26 32 -4z m148 -17 c0 -6 8 -20 18 -31 16 -19 16 -19 -6 -13 -15 5
-24 3 -28 -8 -3 -9 -10 -13 -15 -10 -13 8 -11 24 4 22 8 -2 11 8 9 30 -1 21 2
31 8 27 5 -3 10 -11 10 -17z m574 -37 c6 -9 -16 -21 -40 -21 -8 0 -11 6 -8 15
7 16 39 20 48 6z m66 -41 c0 -16 -4 -30 -10 -30 -5 0 -10 7 -10 15 0 9 -7 18
-15 21 -20 8 -9 24 16 24 14 0 19 -7 19 -30z m58 -12 c5 -24 6 -47 3 -53 -2
-5 -4 -185 -4 -400 0 -369 0 -378 -8 -165 -11 332 -15 660 -7 660 4 0 11 -19
16 -42z m-848 7 c0 -16 -6 -25 -15 -25 -15 0 -21 31 -8 43 13 14 23 6 23 -18z
m910 -24 c0 -28 5 -53 10 -56 6 -4 10 -104 10 -267 0 -144 -2 -259 -5 -256 -3
2 -8 117 -13 255 -6 214 -10 254 -25 277 -15 23 -16 33 -7 62 16 49 30 42 30
-15z m-710 27 c0 -6 8 -20 18 -31 16 -20 16 -20 -1 -14 -10 4 -23 7 -28 7 -5
0 -9 11 -9 25 0 14 5 25 10 25 6 0 10 -5 10 -12z m406 -45 c-3 -4 -6 -1 -6 6
0 7 -5 9 -10 6 -8 -5 -7 -11 1 -21 16 -19 -12 -60 -45 -66 -33 -7 -34 9 -1 24
17 8 25 19 25 38 0 35 21 56 33 34 5 -8 6 -18 3 -21z m-1046 -122 c0 -22 -5
-41 -12 -43 -8 -3 -13 -66 -18 -204 l-7 -199 -1 181 c-2 215 5 304 24 304 10
0 14 -13 14 -39z m1330 -126 c0 -97 -4 -155 -10 -155 -5 0 -10 22 -10 48 0 68
12 262 17 262 1 0 3 -70 3 -155z m-670 70 c0 -13 -7 -25 -15 -29 -9 -3 -15 0
-15 10 0 9 -9 18 -20 21 -25 7 -26 38 -2 52 21 13 52 -19 52 -54z m103 4 c-5
-27 2 -34 29 -30 4 0 12 -7 17 -17 17 -31 -26 -24 -49 8 -23 32 -26 50 -8 50
9 0 9 3 0 12 -17 17 -15 33 3 18 9 -8 12 -22 8 -41z m-156 -31 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m39 -47 c0 -15 -3 -17 -13 -9 -7 6
-13 14 -13 19 0 5 6 9 13 9 7 0 13 -9 13 -19z m-644 -156 c2 -88 1 -137 -3
-110 -7 51 -12 282 -5 274 2 -2 5 -76 8 -164z m708 150 c0 -9 -6 -12 -15 -9
-8 4 -12 10 -9 15 8 14 24 10 24 -6z m22 -133 c4 -12 2 -22 -3 -22 -5 0 -9 7
-9 17 0 9 -11 31 -26 50 -14 18 -22 38 -18 44 7 11 46 -51 56 -89z m68 -22 c0
-5 0 -12 0 -15 0 -14 -13 -8 -16 8 -1 9 2 17 7 17 5 0 9 -4 9 -10z m-30 -20
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-776 -545 c-1 -137 1 -214 6 -190 7 34 7 32 4 -19 -2 -32 -4 -75 -4 -94 0
-19 -3 -32 -7 -28 -4 3 -6 25 -5 47 4 58 -12 73 -19 19 -3 -25 -6 117 -5 315
0 340 1 347 6 120 l6 -240 3 245 c4 201 5 228 11 150 4 -52 6 -198 4 -325z
m1333 143 c-2 -24 -4 -5 -4 42 0 47 2 66 4 43 2 -24 2 -62 0 -85z m-1300 -5
c-2 -21 -4 -4 -4 37 0 41 2 58 4 38 2 -21 2 -55 0 -75z m1275 -383 c7 -14 21
-31 31 -38 16 -12 25 -72 10 -72 -14 0 -43 34 -43 51 0 10 -4 21 -10 24 -6 4
-9 83 -8 213 l3 207 2 -180 c2 -119 7 -188 15 -205z m46 183 c-1 -145 -2 -151
-9 -73 -11 127 -10 230 1 230 6 0 9 -58 8 -157z m-1341 -25 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m43 -59 c0 -51 -4 -78 -10 -74 -5 3 -10 40
-10 81 0 43 4 74 10 74 6 0 10 -34 10 -81z m1320 -88 c0 -6 -4 -13 -10 -16 -5
-3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-1339 -98 c-9 -16 -10 -16
-11 4 0 12 3 30 7 40 7 16 8 16 11 -4 2 -12 -1 -30 -7 -40z m-70 -46 c-6 -6
-14 -7 -18 -2 -5 4 -2 10 5 12 6 3 13 14 13 26 2 20 2 20 6 -2 3 -13 0 -28 -6
-34z m1332 -12 c-6 -10 -9 -2 -10 28 -1 38 0 40 9 15 5 -16 6 -34 1 -43z
m-1203 25 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9
10 -20z m-80 -5 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z
m430 -36 c0 -9 7 -22 15 -29 16 -13 20 -30 7 -30 -10 0 -52 81 -52 101 0 19
29 -22 30 -42z m840 31 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m460 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m-480 -41 c-7 -11 -14 -19 -16 -16 -7 7 7 37 17 37 6
0 5 -9 -1 -21z m-862 -1 c-5 -13 -39 -14 -220 -7 l-83 3 85 6 c160 12 222 11
218 -2z m-478 -18 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m570 -48 c0 -4 -137 -7 -305 -8 -168 -1 -303 1 -301 4 17 16
606 21 606 4z"/>
<path d="M4935 5690 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3723 4310 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z"/>
<path d="M3655 5830 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5660 5832 c0 -4 10 -19 23 -34 12 -15 33 -44 47 -63 14 -20 31 -42
38 -50 9 -11 12 -184 12 -798 l0 -784 -32 -38 c-18 -22 -41 -47 -50 -57 -10
-10 -14 -18 -9 -18 12 0 61 47 61 59 0 6 91 10 242 11 179 0 244 4 250 13 5 8
8 6 8 -6 0 -10 16 -33 35 -52 l34 -35 -277 -1 c-322 -2 -370 -3 -379 -13 -4
-3 150 -6 341 -6 326 1 347 2 334 18 -7 9 -32 39 -55 67 l-43 50 0 236 c0 181
3 239 13 242 9 4 8 8 -3 16 -13 9 -13 11 3 11 9 0 17 -4 17 -10 0 -5 9 -10 20
-10 37 0 22 29 -17 33 -21 2 0 4 47 4 78 1 82 0 43 -10 -24 -5 -43 -14 -43
-18 0 -10 37 -4 135 22 39 10 83 20 100 23 34 6 136 45 150 58 22 20 -41 0
-107 -33 -10 -5 -34 -9 -53 -9 -19 0 -35 -4 -35 -9 0 -5 -16 -11 -35 -13 -19
-2 -35 -1 -35 2 0 7 87 34 112 35 9 0 20 4 24 8 4 4 18 7 31 7 14 0 21 4 18
10 -3 6 -26 10 -51 10 -25 0 -56 7 -70 17 l-25 16 32 14 c30 13 35 12 49 -5 8
-11 27 -22 42 -26 14 -3 34 -9 44 -13 10 -4 15 -2 11 3 -3 6 22 10 64 9 38 0
73 5 77 10 10 14 63 50 87 59 16 6 17 9 5 17 -12 8 -10 9 6 4 14 -4 24 0 28
10 3 8 10 15 16 15 15 0 110 92 110 106 0 21 -48 23 -66 3 -10 -11 -21 -19
-26 -19 -14 0 -9 44 8 68 12 18 19 20 35 12 10 -6 19 -18 19 -27 0 -10 6 -14
20 -10 11 3 18 1 14 -4 -3 -5 -1 -9 3 -9 13 0 65 76 91 133 20 43 31 92 49
209 11 77 -41 225 -116 325 -59 80 -212 189 -276 198 -11 2 -24 5 -30 6 -6 2
-11 0 -13 -6 -1 -5 10 -11 25 -13 15 -2 38 -13 50 -24 13 -12 29 -18 38 -14
10 3 13 2 10 -4 -4 -6 -3 -9 2 -8 8 2 47 -21 92 -54 l24 -18 -27 0 c-16 0 -25
4 -21 10 3 6 -3 10 -15 10 -11 0 -23 6 -26 14 -7 17 -81 49 -95 41 -5 -4 -9
-2 -9 3 0 6 -9 8 -20 5 -11 -3 -20 -1 -20 5 0 6 -9 14 -20 17 -11 4 -20 12
-19 18 3 23 -1 37 -11 37 -5 0 -7 -7 -4 -15 5 -13 -11 -15 -115 -15 -102 0
-121 2 -121 15 0 14 -47 16 -410 14 -225 0 -410 -4 -410 -7z m62 -19 c10 -9
18 -22 18 -30 0 -7 4 -13 8 -13 4 0 8 -9 9 -21 2 -20 1 -20 -18 -4 -10 10 -19
24 -19 30 0 7 -7 18 -15 25 -15 13 -20 30 -8 30 3 0 15 -7 25 -17z m1062 -83
c11 -11 -32 -33 -50 -26 -8 3 -14 15 -14 26 0 17 4 19 28 14 15 -4 31 -10 36
-14z m130 -50 c-10 -4 -24 -2 -30 4 -19 18 -5 26 22 14 23 -11 24 -13 8 -18z
m-96 -13 c4 -19 -15 -22 -38 -7 -13 9 -13 11 0 20 19 13 34 8 38 -13z m-1008
-1 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z
m30 10 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m432 -7 c2 -7 61 -10 181 -9 163 1 181 -1 210 -19 46 -30 70 -58 103 -126
27 -55 29 -68 28 -168 0 -68 -5 -112 -12 -119 -7 -7 -12 -19 -12 -27 0 -9 -7
-29 -15 -45 -8 -15 -15 -37 -15 -47 0 -11 -5 -19 -10 -19 -6 0 -14 -8 -17 -17
-6 -16 -13 -25 -70 -85 -44 -47 -105 -95 -133 -103 -14 -4 -27 -11 -30 -15 -3
-5 -21 -15 -40 -23 -34 -15 -35 -15 -7 -16 15 -1 27 4 27 10 0 6 7 9 15 5 8
-3 20 0 27 7 22 22 131 97 140 97 4 0 8 -9 8 -19 0 -20 17 -31 38 -24 6 3 12
11 12 19 0 8 5 14 10 14 15 0 12 -17 -7 -40 -21 -24 -55 -26 -73 -5 -17 21
-33 19 -61 -8 -13 -13 -32 -27 -42 -30 -12 -4 -17 -15 -15 -29 2 -17 -3 -22
-25 -25 -15 -2 -35 0 -44 6 -12 6 -24 4 -40 -6 -18 -12 -29 -13 -46 -5 -21 10
-20 11 11 11 17 1 32 6 32 12 0 6 -8 8 -20 4 -10 -3 -42 -10 -71 -14 -28 -5
-55 -14 -59 -22 -5 -7 -8 188 -7 433 1 245 -1 444 -5 441 -5 -2 -8 1 -8 6 0
14 37 14 42 0z m426 -5 c12 -8 22 -12 22 -7 0 10 27 -35 36 -60 5 -15 9 -16
15 -5 7 11 11 10 19 -2 5 -8 10 -22 10 -30 0 -13 -1 -13 -10 0 -7 11 -10 12
-10 2 0 -7 7 -20 15 -28 18 -18 20 -54 4 -54 -6 0 -9 9 -6 19 3 11 -1 22 -8
25 -7 2 -23 25 -35 49 -43 86 -59 91 -270 97 l-185 6 190 2 c159 2 194 -1 213
-14z m-870 -761 c0 -412 -3 -733 -5 -715 -4 31 -5 33 -18 15 -12 -15 -14 83
-15 701 0 634 2 717 15 712 10 -4 15 1 15 14 0 11 2 20 5 20 2 0 4 -336 3
-747z m1224 700 c-5 -27 -5 -27 -14 -4 -8 24 -2 45 11 36 5 -2 6 -17 3 -32z
m-192 18 c0 -17 -12 -41 -21 -41 -5 0 -9 11 -9 25 0 16 6 25 15 25 8 0 15 -4
15 -9z m226 -33 c3 -13 15 -31 25 -41 11 -10 19 -29 19 -42 0 -13 5 -27 11
-31 6 -3 9 -15 6 -25 -3 -11 -2 -17 3 -14 10 6 20 -19 20 -52 0 -21 -3 -23
-14 -14 -11 9 -15 9 -20 -1 -3 -7 -4 -3 -2 9 2 12 -2 27 -10 34 -11 9 -15 5
-20 -20 -8 -36 -31 -36 -26 0 2 13 12 43 23 66 l20 42 -24 33 c-26 35 -41 78
-27 78 5 0 12 -10 16 -22z m-18 -69 c-6 -4 -15 -13 -20 -20 -7 -10 -8 -8 -5 9
3 12 1 22 -3 22 -4 0 -6 9 -3 20 5 19 6 19 23 -3 11 -14 14 -24 8 -28z m-201
-71 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-23 -73 c20 -30 20
-49 2 -64 -12 -10 -17 -6 -25 26 -19 67 -8 85 23 38z m-924 -180 c0 -97 -4
-155 -10 -155 -5 0 -10 22 -10 48 0 68 12 262 17 262 1 0 3 -70 3 -155z m1229
68 c0 -10 -4 -27 -9 -38 -7 -16 -9 -13 -9 18 -1 20 4 37 9 37 6 0 10 -8 9 -17z
m25 -25 c-10 -81 -12 -86 -54 -183 -10 -23 -14 -26 -17 -13 -3 10 0 25 5 34 9
15 5 40 -13 102 -11 33 8 27 24 -9 21 -47 33 -21 40 89 0 12 6 22 11 22 6 0 8
-17 4 -42z m-354 -8 c0 -20 -5 -30 -15 -30 -17 0 -18 4 -9 38 9 32 24 27 24
-8z m240 -70 c0 -16 5 -32 11 -35 6 -4 9 -26 8 -49 -2 -23 0 -47 4 -52 3 -6 0
-20 -9 -30 -14 -18 -15 -18 -9 4 7 23 -10 32 -18 10 -2 -7 -2 -4 1 7 6 25 5
75 -4 139 -4 34 -3 47 5 42 6 -4 11 -20 11 -36z m84 25 c-4 -8 -8 -15 -10 -15
-2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m-350 -61 c-11 -58 -32 -71
-31 -20 1 36 10 47 21 24 3 -7 3 5 0 27 -4 32 -3 38 7 28 9 -9 10 -25 3 -59z
m44 -64 c-2 -8 -14 -17 -27 -18 -19 -3 -22 0 -17 13 3 9 6 23 6 31 0 12 3 12
20 2 11 -7 19 -20 18 -28z m-93 -10 c3 -5 -3 -10 -15 -10 -17 0 -20 -4 -15
-20 11 -35 -3 -59 -35 -58 -19 1 -27 5 -23 14 3 8 9 14 14 14 5 0 12 8 16 18
8 21 33 52 44 52 4 0 11 -4 14 -10z m-875 -170 c0 -136 -2 -160 -15 -160 -13
0 -15 24 -15 153 0 85 3 157 7 160 19 20 23 -7 23 -153z m1034 151 c8 -13 -4
-42 -15 -35 -12 7 -12 44 0 44 6 0 12 -4 15 -9z m116 -105 c0 -2 -7 -9 -15
-16 -12 -10 -15 -10 -15 4 0 9 7 16 15 16 8 0 15 -2 15 -4z m-50 -35 c0 -11
-14 -28 -34 -40 -40 -25 -47 -26 -40 -6 4 8 14 15 23 15 10 0 24 11 31 25 16
30 20 31 20 6z m-353 -7 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m287 -2 c6 -11 -61 -35 -74 -27 -19 12 -10 24 23 28 17 2 35 5 39 5 4 1 9 -2
12 -6z m-144 -43 c0 -11 -2 -19 -4 -19 -2 0 -11 -3 -20 -6 -12 -5 -16 1 -16
23 0 16 3 33 7 36 10 11 33 -13 33 -34z m-425 -48 c3 -5 -4 -27 -15 -48 -16
-31 -26 -39 -50 -41 -24 -2 -30 2 -30 18 0 11 5 20 10 20 6 0 10 -4 10 -10 0
-25 18 -6 29 30 11 37 32 52 46 31z m512 -2 c-14 -8 -22 -8 -30 0 -8 8 -3 11
19 11 25 -1 27 -2 11 -11z m-47 -33 c-11 -5 -27 -5 -37 -2 -16 7 -16 9 6 21
18 9 28 10 37 1 10 -9 8 -13 -6 -20z m-349 4 c5 0 9 -4 9 -10 0 -5 -13 -10
-30 -10 -29 0 -38 9 -23 24 4 3 13 4 21 1 7 -3 18 -5 23 -5z m-534 -157 c-2
-21 -4 -4 -4 37 0 41 2 58 4 38 2 -21 2 -55 0 -75z m557 87 c-14 -14 -65 -27
-87 -22 -18 4 -18 4 0 18 21 16 103 20 87 4z m-594 -285 c0 -97 -4 -155 -10
-155 -6 0 -10 58 -10 155 0 97 4 155 10 155 6 0 10 -58 10 -155z m40 -245 c0
-44 -4 -80 -8 -80 -5 0 -9 36 -9 80 -1 44 3 80 8 80 5 0 9 -36 9 -80z m-87
-65 c6 -18 5 -18 -5 -3 -11 15 -13 15 -26 0 -13 -16 -13 -16 -8 1 3 10 6 31 6
45 l0 27 13 -25 c7 -14 16 -34 20 -45z m34 43 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z"/>
<path d="M5807 5204 c1 -218 4 -399 7 -402 4 -3 6 175 6 396 0 221 -3 402 -8
402 -4 0 -6 -178 -5 -396z"/>
<path d="M4241 5654 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8583 5633 c-13 -2 -23 -10 -23 -18 0 -7 -12 -16 -27 -20 -30 -7 -53
-23 -53 -35 0 -8 80 29 90 41 3 4 15 9 28 13 20 7 33 28 15 25 -5 -1 -18 -4
-30 -6z"/>
<path d="M9536 5578 c-9 -12 -16 -27 -16 -32 0 -5 -17 -24 -37 -41 -21 -18
-35 -37 -30 -41 7 -8 76 54 77 69 0 5 9 22 21 38 11 16 16 29 10 29 -5 0 -17
-10 -25 -22z"/>
<path d="M2778 5403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4672 5328 c-13 -13 -16 -38 -4 -38 4 0 12 11 19 25 12 27 5 33 -15
13z"/>
<path d="M1340 5171 c0 -5 10 -11 23 -14 12 -3 32 -9 45 -12 12 -4 22 -3 22 2
0 6 -17 15 -39 21 -46 14 -51 15 -51 3z"/>
<path d="M2220 5160 c-23 -7 -22 -8 10 -9 19 -1 53 -4 75 -7 27 -4 35 -2 25 4
-23 14 -82 21 -110 12z"/>
<path d="M1406 5115 c4 -8 15 -15 25 -15 10 0 19 7 19 15 0 9 -9 15 -25 15
-18 0 -23 -4 -19 -15z"/>
<path d="M2764 4850 c-36 -14 -35 -26 3 -26 20 0 31 2 24 4 -9 3 -9 7 0 18 13
16 4 17 -27 4z"/>
<path d="M1863 4793 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M6720 4699 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1737 4564 c-11 -11 3 -24 18 -18 8 4 12 10 9 15 -6 11 -18 12 -27 3z"/>
<path d="M8705 4450 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2920 4340 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M3153 4343 c-7 -3 -13 -15 -13 -28 0 -13 -5 -25 -12 -27 -14 -5 -4
-38 12 -38 5 0 10 13 10 28 0 15 5 33 11 39 14 14 8 31 -8 26z"/>
<path d="M3290 4309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6574 4271 c-27 -20 -44 -43 -52 -69 -14 -47 -16 -122 -3 -122 5 0 9
19 9 43 -1 64 21 113 64 142 21 14 42 25 47 25 22 0 69 -36 78 -59 9 -23 11
-24 18 -8 12 27 -33 67 -81 73 -32 5 -46 0 -80 -25z"/>
<path d="M7000 4282 c-50 -25 -74 -79 -74 -167 0 -76 22 -141 25 -75 1 24 3
28 6 13 2 -13 11 -23 19 -23 8 0 14 -7 14 -16 0 -10 -6 -14 -15 -11 -28 11
-25 -11 6 -37 40 -34 91 -35 135 -1 32 25 33 26 28 95 -3 47 0 70 7 70 7 0 10
-16 8 -44 -2 -24 -1 -42 4 -39 12 7 8 125 -7 168 -15 43 -64 85 -100 85 -12 0
-37 -8 -56 -18z m111 -22 c14 -8 18 -17 13 -35 -5 -19 -2 -25 10 -25 10 0 16
-8 16 -21 0 -19 -2 -20 -16 -8 -15 13 -16 8 -12 -48 3 -46 1 -60 -8 -56 -9 3
-11 23 -7 74 4 48 2 73 -7 84 -19 23 -32 18 -19 -7 12 -21 14 -165 2 -193 -9
-25 -55 -15 -70 15 -13 26 -15 103 -4 155 4 21 13 31 28 33 23 3 38 28 22 38
-5 3 -17 3 -27 -1 -14 -5 -14 -7 -2 -15 13 -8 13 -10 -2 -10 -9 0 -21 -14 -28
-32 l-12 -33 -12 35 c-7 19 -12 36 -12 36 1 1 18 11 40 22 39 21 54 20 107 -8z
m-141 -77 c0 -16 -7 -46 -15 -68 l-14 -40 0 45 c-1 53 8 90 20 90 5 0 9 -12 9
-27z m17 -105 c-2 -18 -4 -6 -4 27 0 33 2 48 4 33 2 -15 2 -42 0 -60z m120
-40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-76 -38 c13 -8 25
-7 40 0 11 7 23 10 25 7 2 -2 -8 -10 -24 -18 -15 -8 -30 -18 -34 -23 -3 -5
-20 -2 -40 8 l-33 17 40 -6 c39 -6 40 -6 15 9 -28 17 -19 22 11 6z m99 3 c-1
-18 -17 -32 -45 -38 l-30 -6 32 16 c18 10 30 21 27 26 -3 5 0 9 5 9 6 0 11 -3
11 -7z"/>
<path d="M8008 4283 c-4 -57 -4 -58 33 -61 23 -2 45 -12 59 -28 13 -13 31 -24
41 -24 10 0 24 -8 31 -18 7 -9 26 -26 41 -35 40 -26 25 -34 -17 -9 -21 12 -37
17 -43 11 -6 -6 -24 4 -47 26 -21 20 -48 35 -61 35 -50 0 -41 -46 10 -56 23
-4 35 -13 39 -29 5 -18 15 -24 58 -29 34 -5 54 -4 56 3 2 6 22 11 45 12 23 0
35 3 27 6 -22 9 -184 134 -193 150 -5 9 -17 12 -30 8 -16 -5 -18 -4 -8 3 11 8
9 14 -8 31 -26 26 -31 26 -33 4z m22 -23 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11
10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m175 -180 c-3 -5 -16 -10 -28 -10 -18 0
-19 2 -7 10 20 13 43 13 35 0z"/>
<path d="M6620 4260 c0 -5 -7 -10 -15 -10 -8 0 -14 -3 -13 -7 4 -16 -14 -53
-25 -53 -8 0 -8 -3 1 -12 7 -7 12 -41 12 -84 0 -49 4 -74 13 -77 6 -2 9 -8 5
-12 -4 -4 -14 -2 -23 5 -18 15 -20 -1 -3 -18 9 -9 9 -12 0 -12 -7 0 -17 12
-22 26 -14 35 -13 44 6 44 12 0 14 5 8 18 -4 9 -10 40 -14 67 l-7 50 -2 -52
c0 -28 -5 -55 -11 -58 -14 -9 -12 -32 6 -71 27 -57 103 -80 163 -49 32 16 49
45 26 45 -8 0 -15 5 -15 11 0 6 7 8 15 5 19 -7 32 23 33 77 1 40 4 39 -75 41
-24 1 -43 -2 -43 -6 0 -5 23 -8 50 -8 30 0 50 -4 50 -11 0 -7 -18 -10 -50 -7
-28 1 -50 0 -50 -4 0 -5 23 -8 50 -8 47 0 50 -2 50 -25 0 -15 -7 -28 -17 -32
-28 -10 -33 -26 -15 -39 9 -7 14 -16 10 -19 -9 -10 -38 19 -38 38 0 8 11 23
25 33 33 24 32 29 -8 30 -17 1 -26 -1 -20 -3 23 -8 -14 -63 -42 -63 -32 0 -36
9 -42 93 -6 90 6 127 43 127 15 0 33 -8 40 -17 7 -10 16 -15 20 -11 4 4 2 14
-4 21 -7 8 -8 17 -4 22 4 4 -9 5 -28 2 -19 -3 -29 -3 -22 0 17 7 15 23 -3 23
-8 0 -15 -4 -15 -10z m35 -270 c-3 -5 -14 -10 -23 -10 -14 0 -15 -2 -2 -10 18
-11 7 -25 -15 -17 -8 4 -15 16 -15 27 0 16 7 20 31 20 17 0 28 -4 24 -10z m25
-20 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z"/>
<path d="M6561 4224 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6562 4135 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3100 4068 c0 -8 7 -15 15 -15 18 0 15 19 -3 25 -7 2 -12 -3 -12 -10z"/>
<path d="M2728 4063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3160 4060 c0 -17 -40 -33 -54 -21 -7 6 -29 8 -50 4 -33 -5 -37 -9
-32 -29 5 -19 2 -23 -21 -27 -24 -3 -22 -4 8 -6 34 -1 98 15 109 28 3 4 17 14
33 24 27 17 36 37 17 37 -5 0 -10 -4 -10 -10z m-70 -44 c0 -9 -30 -14 -35 -6
-4 6 3 10 14 10 12 0 21 -2 21 -4z"/>
<path d="M5770 4032 c-5 -2 -10 -12 -10 -23 0 -18 10 -19 249 -19 138 0 252 4
255 8 2 4 1 14 -4 22 -8 13 -50 15 -245 15 -129 0 -239 -1 -245 -3z"/>
<path d="M6822 4033 c-7 -3 -18 -17 -23 -30 -7 -20 -6 -30 7 -44 18 -20 54
-26 54 -9 0 6 -10 10 -22 10 -17 0 -19 3 -10 12 8 8 15 8 26 -1 8 -7 20 -10
26 -6 16 10 12 35 -7 36 -12 0 -13 2 -5 6 10 4 9 8 -5 19 -20 15 -22 15 -41 7z
m32 -28 c-4 -8 -10 -15 -14 -15 -4 0 -10 7 -14 15 -4 10 1 15 14 15 13 0 18
-5 14 -15z m-34 -15 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0
4 -4 4 -10z"/>
<path d="M4978 3993 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5218 3993 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M4914 3981 c-2 -2 -4 -7 -4 -10 0 -3 140 -8 310 -10 220 -2 310 0
310 8 0 7 -93 11 -306 13 -168 2 -308 1 -310 -1z"/>
<path d="M7397 3940 c-30 -53 -29 -94 0 -88 16 3 71 92 72 116 1 6 -6 12 -15
12 -8 0 -13 -4 -9 -9 5 -9 -26 -75 -46 -95 -16 -18 -10 13 12 55 30 60 19 67
-14 9z"/>
<path d="M3395 3954 c-12 -9 -16 -18 -10 -22 6 -4 12 -16 13 -27 5 -27 27 -68
78 -146 24 -36 44 -71 44 -77 0 -6 3 -12 8 -14 4 -1 20 -22 36 -46 16 -23 31
-40 33 -38 5 5 -42 91 -68 125 -10 13 -19 30 -19 37 0 8 -5 14 -10 14 -6 0
-20 22 -32 50 -12 27 -25 47 -30 44 -4 -3 -8 2 -8 10 0 34 29 6 55 -51 8 -18
18 -33 23 -33 6 0 13 -12 16 -26 4 -14 24 -48 46 -75 22 -27 51 -72 66 -99 15
-28 33 -50 41 -50 15 0 17 -22 3 -36 -7 -7 -2 -20 15 -40 15 -16 40 -46 57
-66 17 -20 35 -35 40 -32 5 3 -16 33 -47 66 -54 61 -64 78 -45 78 6 0 10 -3
10 -8 0 -14 103 -122 116 -122 8 0 14 -4 14 -10 0 -5 -8 -7 -17 -4 -16 5 -16
4 -3 -6 12 -9 13 -12 1 -17 -9 -4 -2 -16 25 -40 22 -19 65 -58 97 -86 32 -29
73 -60 90 -69 17 -10 27 -18 22 -18 -15 0 84 -68 180 -125 28 -16 52 -32 55
-35 9 -10 190 -100 190 -95 0 3 10 -1 23 -10 42 -27 268 -106 343 -119 l39 -7
-30 17 c-16 9 -59 22 -94 30 -36 8 -67 18 -70 23 -3 4 -22 12 -41 16 -19 4
-40 11 -45 16 -6 4 -23 10 -39 13 -15 3 -39 12 -52 21 -13 8 -32 15 -44 15
-11 0 -20 4 -20 9 0 5 -12 14 -27 19 -39 14 -73 41 -52 42 8 0 19 -4 25 -8 5
-5 50 -26 99 -46 50 -20 99 -41 110 -46 11 -5 45 -16 75 -26 30 -9 60 -20 65
-25 6 -4 41 -13 79 -20 43 -8 62 -15 50 -19 -31 -11 6 -17 66 -10 l55 6 -60
13 c-140 29 -252 63 -345 104 -25 11 -54 23 -65 28 -11 4 -41 17 -67 28 -26
12 -52 21 -59 21 -7 0 -9 3 -6 6 4 4 -15 18 -41 32 -71 37 -169 99 -244 154
-37 27 -73 47 -80 45 -7 -3 1 -11 20 -18 32 -14 43 -29 19 -29 -21 0 -72 43
-75 65 -3 16 -1 18 9 8 7 -7 18 -13 23 -13 6 0 -1 8 -14 17 -14 10 -71 63
-129 118 -148 143 -244 264 -376 475 -48 78 -55 90 -55 104 0 7 -8 23 -17 35
-16 18 -20 19 -38 5z m35 -20 c0 -9 -5 -14 -12 -12 -18 6 -21 28 -4 28 9 0 16
-7 16 -16z m4 -39 c-4 -8 -10 -15 -15 -15 -5 0 -9 7 -9 15 0 8 7 15 15 15 9 0
12 -6 9 -15z m489 -623 c7 5 60 -63 53 -69 -3 -3 -13 3 -23 15 -10 11 -41 40
-69 64 -28 24 -48 50 -46 56 2 7 20 -4 42 -28 21 -23 41 -40 43 -38z m210
-167 c22 -19 44 -35 49 -35 14 0 9 -18 -8 -24 -8 -3 -13 -2 -10 3 6 9 -40 45
-72 55 -22 7 -27 17 -15 29 10 11 12 9 56 -28z m97 -66 c0 -5 5 -7 10 -4 6 3
10 1 10 -5 0 -6 11 -15 25 -20 14 -5 25 -14 25 -19 0 -12 -67 15 -86 34 -18
18 -18 25 1 25 8 0 15 -5 15 -11z m129 -75 c2 -2 -5 -4 -16 -4 -12 0 -23 7
-27 15 -5 13 -2 14 17 4 12 -6 24 -13 26 -15z"/>
<path d="M7704 3901 c-10 -33 -22 -57 -26 -55 -11 7 -10 -19 1 -50 7 -17 7
-26 -2 -30 -7 -2 -2 -5 10 -5 12 -1 23 -7 26 -13 3 -10 7 -10 19 -1 8 7 18 10
21 6 4 -3 7 0 7 8 0 11 2 11 9 1 7 -11 44 58 78 144 6 17 -48 28 -58 12 -13
-20 -11 -56 3 -60 10 -4 10 -10 1 -31 -11 -23 -19 -27 -52 -27 -44 0 -58 18
-39 53 9 17 12 19 16 7 13 -41 47 -32 50 13 1 18 3 41 4 52 2 11 -2 20 -9 20
-6 0 -15 3 -19 8 -15 14 -21 6 -40 -52z m47 26 c-1 -4 -1 -26 -2 -49 0 -33 -2
-38 -9 -23 -13 29 -12 87 1 83 6 -2 10 -7 10 -11z m69 -31 c0 -8 -4 -18 -10
-21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z"/>
<path d="M3080 3928 c-96 -41 -90 -36 -78 -66 16 -37 32 -53 25 -24 -6 24 8
31 16 7 4 -8 1 -17 -4 -21 -11 -6 -3 -54 9 -64 5 -4 64 -117 67 -127 0 -1 13
-5 29 -9 29 -7 29 -6 22 27 -6 32 -6 33 11 16 15 -14 16 -22 6 -47 -6 -17 -8
-30 -3 -30 4 0 11 -11 14 -25 4 -14 11 -25 16 -25 6 0 10 -10 10 -22 -1 -21
-2 -20 -17 7 -9 16 -22 35 -29 42 -7 7 -14 18 -17 25 -2 7 -8 10 -12 6 -6 -6
46 -101 65 -118 3 -3 21 -30 40 -60 19 -30 40 -62 47 -70 7 -8 19 -24 28 -35
13 -17 16 -18 25 -5 13 19 9 43 -8 46 -7 1 -8 0 -2 -3 5 -2 10 -12 10 -21 -1
-18 -11 -8 -55 54 -11 15 -28 36 -37 46 -20 20 -23 38 -8 38 6 0 10 -5 10 -11
0 -6 18 -29 40 -51 34 -35 40 -37 40 -20 0 12 -9 26 -20 32 -11 6 -18 15 -15
20 3 6 -1 7 -9 4 -11 -4 -16 0 -16 11 0 9 5 13 10 10 6 -3 10 1 10 10 0 9 -4
13 -10 10 -5 -3 -10 1 -10 10 0 9 4 14 9 11 14 -9 21 20 8 34 -9 10 -8 12 6 7
21 -8 22 6 2 23 -8 7 -15 16 -15 21 0 4 -11 24 -24 44 -13 19 -53 92 -87 162
-54 109 -64 124 -70 103 -6 -23 -7 -23 -14 -6 -6 13 -3 23 10 32 22 16 10 17
-25 2z m-3 -65 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-42 17
c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m124
-70 c12 -28 21 -56 18 -63 -2 -6 -12 6 -22 28 -15 35 -18 37 -24 20 -15 -37
-22 -107 -11 -100 5 3 10 4 10 1 0 -2 3 -11 6 -20 4 -11 1 -16 -10 -16 -9 0
-16 5 -16 10 0 6 -8 28 -19 50 -15 32 -16 40 -5 44 8 3 14 14 14 23 0 10 7 28
15 39 8 12 11 24 8 28 -3 3 -2 6 4 6 5 0 20 -23 32 -50z m103 -192 c20 -18 14
-25 -9 -10 -20 13 -60 77 -55 90 2 7 13 -4 24 -25 11 -21 29 -46 40 -55z m-32
-14 c0 -2 -7 -4 -15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z
m46 -59 c4 -8 3 -22 0 -31 -8 -20 -31 -4 -39 26 -4 15 0 20 14 20 11 0 22 -7
25 -15z"/>
<path d="M3095 3750 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7349 3848 c-7 -13 -21 -38 -31 -56 -21 -38 -22 -42 -7 -42 5 0 8 3
5 8 -6 10 22 47 30 39 4 -4 2 -13 -5 -21 -9 -11 -8 -15 3 -19 11 -4 21 5 32
31 27 57 27 58 6 35 -20 -23 -32 -21 -32 4 0 8 5 11 10 8 6 -3 10 3 10 14 0
27 -6 26 -21 -1z"/>
<path d="M7650 3791 c-5 -11 -10 -25 -10 -31 0 -6 -7 -18 -15 -26 -8 -9 -15
-25 -15 -36 0 -12 -3 -18 -6 -15 -6 6 -27 -25 -57 -83 -6 -13 -24 -38 -39 -54
-15 -16 -25 -32 -23 -35 3 -2 -2 -13 -12 -23 -9 -10 -14 -20 -11 -23 3 -3 16
10 29 29 18 26 27 32 36 24 16 -13 17 -68 2 -68 -5 0 -8 -4 -5 -8 3 -5 -7 -7
-22 -5 -31 4 -52 -11 -69 -49 -14 -31 -6 -35 23 -13 25 19 45 19 38 0 -4 -8
-1 -22 6 -30 10 -12 9 -15 -4 -15 -8 0 -18 -11 -22 -25 -8 -33 -26 -47 -44
-33 -13 8 -12 9 2 5 12 -5 18 2 23 26 4 18 12 38 17 45 8 9 6 12 -6 12 -9 0
-16 -5 -16 -11 0 -6 -9 -21 -20 -33 -10 -11 -28 -32 -39 -46 -16 -21 -21 -23
-35 -11 -15 13 -18 12 -23 -7 -3 -12 -12 -22 -19 -22 -12 0 -12 4 -2 23 21 37
40 57 49 51 5 -3 9 -10 9 -15 0 -6 -5 -7 -12 -3 -7 4 -8 3 -4 -4 10 -17 12
-16 36 23 13 22 19 42 15 55 -4 13 -1 22 9 26 9 3 16 11 16 17 0 6 7 19 17 29
9 10 11 18 6 18 -6 0 -18 -11 -27 -25 -9 -14 -28 -38 -41 -53 -64 -72 -117
-143 -111 -150 7 -7 12 -8 38 -11 6 0 1 -12 -12 -26 -12 -13 -25 -23 -29 -22
-3 0 -16 -4 -28 -11 -34 -19 -36 2 -2 22 16 9 29 24 29 32 0 23 -13 16 -60
-31 -35 -36 -44 -52 -42 -74 2 -16 -1 -32 -7 -36 -21 -12 -11 -25 20 -25 21 0
28 -4 23 -12 -4 -7 5 -2 19 11 15 14 27 19 27 12 0 -6 -12 -23 -27 -36 -16
-13 -26 -26 -23 -29 4 -3 24 14 47 37 39 40 80 81 102 102 6 6 34 39 63 75 29
35 58 71 66 80 7 8 33 42 57 75 25 33 48 65 52 71 26 34 153 250 153 259 0 5
7 17 17 27 22 25 8 36 -17 13 -11 -10 -18 -20 -15 -23 2 -2 -3 -18 -11 -34 -9
-17 -18 -27 -21 -25 -3 3 -1 11 3 18 14 23 15 72 2 81 -22 14 -28 9 -28 -23 0
-19 -4 -28 -11 -23 -6 3 -15 0 -20 -7 -4 -7 -11 -11 -14 -8 -8 8 16 60 25 54
4 -2 7 1 7 8 0 7 4 24 8 40 8 31 0 36 -15 8z m37 -93 c-3 -7 -5 -2 -5 12 0 14
2 19 5 13 2 -7 2 -19 0 -25z m-70 -84 c1 -43 6 -70 14 -75 11 -6 9 -13 -9 -32
-12 -13 -22 -27 -22 -31 0 -4 -15 -26 -32 -49 -18 -23 -34 -44 -35 -46 -1 -2
-9 2 -18 9 -8 7 -12 17 -9 22 4 6 10 5 15 -3 6 -10 9 -10 9 -2 0 7 13 27 28
45 46 54 61 110 35 136 -11 11 -13 10 -13 -7 0 -11 -4 -23 -10 -26 -15 -10
-12 -23 8 -29 10 -3 6 -4 -8 -3 -35 3 -41 17 -22 46 32 49 42 66 52 89 5 12
11 22 12 22 2 0 4 -30 5 -66z m68 21 c0 -24 -5 -48 -10 -52 -4 -4 -5 0 -1 11
3 10 1 24 -5 32 -6 7 -8 22 -4 34 11 35 22 22 20 -25z m-36 -52 c-1 -22 -2
-23 -6 -8 -3 11 -10 23 -17 27 -7 5 -5 8 7 8 12 0 17 -7 16 -27z m-254 -379
c-25 -25 -45 -53 -45 -61 0 -8 -13 -20 -30 -26 -16 -6 -30 -17 -30 -23 0 -7
-7 -14 -15 -18 -8 -3 -15 -1 -15 4 0 6 -7 10 -15 10 -8 0 -15 7 -15 15 0 8 -6
15 -14 15 -8 0 -17 4 -21 9 -7 12 51 -4 68 -18 8 -6 17 -4 25 5 7 8 9 14 5 14
-4 0 7 9 25 20 37 23 51 48 32 60 -7 4 -9 13 -5 20 6 9 11 6 20 -10 l12 -23 7
27 c5 19 13 26 31 26 23 0 22 -3 -20 -46z m-175 -128 c0 -7 -9 -16 -20 -19
-23 -6 -26 3 -8 21 16 16 28 15 28 -2z"/>
<path d="M7322 3733 c-9 -13 -14 -15 -18 -5 -10 21 -21 13 -55 -40 -19 -29
-39 -60 -46 -68 -91 -117 -149 -188 -167 -202 -11 -9 -17 -20 -13 -24 4 -4 7
-2 7 4 0 6 7 9 15 6 8 -4 15 -2 15 3 0 5 12 23 28 40 15 17 43 50 61 74 19 23
37 37 39 31 5 -15 -112 -148 -153 -175 -16 -10 -21 -16 -11 -13 15 5 13 1 -8
-19 -30 -28 -42 -31 -49 -12 -2 7 -8 8 -13 3 -5 -5 -4 -11 4 -13 6 -3 12 -14
12 -26 -1 -20 -2 -21 -14 -4 -14 18 -32 22 -38 9 -1 -4 -27 -27 -56 -52 -46
-38 -51 -46 -38 -56 9 -6 16 -13 16 -16 0 -13 -104 -68 -112 -60 -6 6 2 14 21
20 17 6 31 16 31 21 0 6 7 11 15 11 17 0 20 16 5 25 -5 3 -10 2 -10 -3 0 -5
-31 -31 -70 -57 -72 -49 -98 -83 -34 -46 40 24 44 25 44 11 0 -6 -16 -18 -35
-27 -19 -9 -35 -21 -35 -25 0 -5 -17 -17 -37 -26 -66 -31 -84 -43 -77 -54 4
-6 9 -6 13 -1 3 6 33 25 66 43 33 18 66 39 73 47 7 7 16 13 20 13 4 0 21 12
37 28 17 15 46 38 65 52 89 63 279 248 332 323 23 31 45 57 51 57 5 0 6 7 3
15 -3 8 -9 22 -13 32 -5 12 -3 14 7 8 9 -6 12 -4 8 6 -3 8 3 25 13 38 11 13
19 19 19 13 0 -6 3 -18 7 -28 5 -13 3 -15 -8 -9 -8 4 -11 5 -7 0 4 -4 1 -14
-7 -22 -8 -8 -15 -20 -15 -26 1 -7 12 2 26 18 19 23 23 38 20 64 -5 41 28 97
40 67 3 -9 -1 -28 -11 -42 -9 -14 -15 -28 -12 -30 7 -7 69 94 65 106 -1 5 -9
2 -16 -7z m-382 -453 c0 -5 -11 -17 -25 -26 -13 -9 -27 -24 -30 -34 -7 -21
-35 -34 -47 -22 -5 5 8 21 31 36 22 15 42 34 45 42 6 16 26 19 26 4z"/>
<path d="M3620 3555 c0 -15 18 -40 24 -34 3 3 -1 14 -9 25 -8 10 -14 15 -15 9z"/>
<path d="M3320 3503 c0 -17 18 -35 28 -29 5 3 13 -1 16 -10 9 -24 7 -27 -9
-14 -9 7 -18 10 -22 6 -10 -10 4 -18 32 -18 30 0 31 -3 5 -30 -28 -30 -26 -39
17 -82 29 -28 34 -39 24 -45 -17 -11 -4 -34 15 -27 16 6 19 -10 3 -19 -5 -4
-19 1 -30 11 -10 9 -19 13 -19 7 0 -14 34 -43 51 -43 12 0 12 -2 0 -9 -8 -5
-9 -11 -3 -15 5 -3 17 5 26 19 21 32 30 31 51 -1 21 -32 12 -43 -15 -19 -11
10 -20 14 -20 9 1 -5 11 -17 23 -26 72 -57 73 -57 54 -58 -10 0 -32 13 -50 30
-45 42 -58 37 -16 -7 42 -46 80 -77 94 -79 34 -4 38 -7 17 -13 l-24 -6 23 -20
c19 -17 22 -18 16 -2 -7 18 8 24 18 7 3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6
-8 10 -17 10 -15 0 -16 2 -3 10 13 8 12 10 -3 10 -10 0 -22 11 -26 24 -5 13
-18 26 -27 30 -16 6 -16 8 -4 16 12 7 8 16 -20 45 -19 19 -35 46 -35 58 0 17
-5 21 -22 20 -16 -2 -21 2 -17 12 4 10 0 15 -11 15 -9 0 -14 4 -11 8 3 5 -8
23 -25 40 -17 17 -28 36 -24 42 10 17 25 11 34 -12 5 -13 37 -53 72 -90 85
-89 109 -118 109 -130 0 -6 -7 -8 -14 -5 -10 4 -13 1 -10 -8 3 -7 14 -15 25
-18 11 -3 23 -15 26 -26 5 -21 23 -29 23 -11 0 6 7 10 15 10 8 0 15 -4 15 -10
0 -5 8 -10 17 -10 11 0 -17 35 -74 93 -130 131 -147 150 -195 215 -24 31 -45
59 -48 62 -3 3 -20 24 -39 48 -30 39 -51 53 -51 35z m93 -114 c11 -8 1 -29
-14 -29 -6 0 -7 5 -4 10 3 6 -1 10 -9 10 -14 0 -14 3 -4 20 9 13 14 16 17 8 2
-7 8 -16 14 -19z m177 -309 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z"/>
<path d="M7506 3484 c-8 -22 -4 -25 11 -9 7 8 9 16 4 19 -5 3 -12 -1 -15 -10z"/>
<path d="M6980 3360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3350 3281 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M7111 3076 c-20 -21 -29 -36 -22 -40 15 -10 33 3 25 17 -4 7 -4 9 1
5 4 -4 16 3 26 16 38 48 16 49 -30 2z"/>
<path d="M6607 3061 c-27 -17 -27 -31 0 -31 8 0 12 4 8 9 -3 6 3 14 12 20 10
5 15 12 13 15 -3 3 -18 -3 -33 -13z"/>
<path d="M3725 3041 c-11 -5 -27 -7 -35 -6 -10 2 -15 -7 -16 -26 -1 -16 4 -29
10 -29 6 0 17 -9 24 -20 7 -11 19 -20 27 -20 8 0 15 -6 15 -14 0 -15 25 -39
44 -42 34 -6 88 -37 102 -59 18 -28 40 -34 30 -8 -6 15 -5 15 13 -1 26 -24 49
-11 26 14 -9 10 -14 27 -11 37 4 13 -5 26 -33 46 -22 15 -42 27 -45 27 -3 0
-16 10 -29 23 -51 48 -95 87 -99 86 -1 0 -12 -4 -23 -8z m53 -38 c7 -7 10 -17
7 -23 -3 -5 1 -10 9 -10 19 0 11 -26 -11 -35 -23 -8 -42 13 -23 25 11 7 9 11
-13 19 -25 10 -35 30 -21 44 8 7 35 -3 52 -20z m117 -93 c21 -12 31 -26 33
-46 4 -28 4 -29 -17 -10 -11 10 -18 22 -16 26 3 4 -2 13 -9 19 -11 9 -19 9
-36 -1 -17 -11 -22 -11 -30 2 -20 32 23 38 75 10z"/>
<path d="M7120 3028 c0 -7 7 -26 16 -43 15 -30 15 -30 -23 -62 -36 -31 -52
-30 -39 2 3 8 14 15 25 15 27 0 34 15 16 34 -10 9 -11 17 -5 21 6 4 8 11 4 16
-4 6 -14 2 -26 -11 -11 -12 -26 -19 -34 -16 -8 3 -14 2 -14 -3 0 -5 10 -12 23
-15 21 -6 21 -7 5 -20 -21 -15 -24 -62 -5 -69 15 -5 120 77 110 87 -3 3 0 13
8 22 12 14 10 19 -10 35 -27 22 -51 25 -51 7z"/>
<path d="M6490 2990 c-36 -22 -72 -42 -80 -46 -58 -24 -79 -33 -107 -45 -32
-13 -44 -29 -23 -29 16 0 11 -14 -10 -27 -10 -7 -27 -10 -36 -6 -15 5 -14 8 7
16 18 8 20 11 8 14 -8 2 -29 -1 -45 -8 -26 -10 -27 -12 -10 -18 19 -7 19 -8 1
-15 -10 -4 -21 -2 -25 4 -9 14 -117 -19 -125 -38 -4 -11 1 -13 21 -8 15 4 23
3 20 -3 -11 -17 -68 -24 -77 -10 -5 8 -3 17 4 22 7 6 4 6 -8 2 -11 -4 -50 -14
-87 -23 -63 -14 -68 -17 -64 -39 4 -18 0 -23 -17 -23 -18 0 -19 2 -7 10 8 5
10 10 5 10 -6 0 -21 -7 -33 -16 -16 -11 -47 -15 -108 -16 -47 0 -88 3 -91 6
-4 3 21 6 55 6 35 0 62 4 62 10 0 6 17 10 38 11 20 1 48 5 62 10 28 10 -40 4
-150 -13 -130 -20 -510 -9 -637 18 -22 4 -33 3 -33 -5 0 -6 18 -11 43 -12 l42
-1 -35 -10 c-26 -7 -42 -6 -66 5 -21 10 -42 13 -65 8 l-34 -8 40 -7 c22 -4 57
-11 77 -17 21 -5 49 -7 64 -3 18 5 25 3 21 -3 -10 -15 49 -21 273 -29 198 -6
252 -2 495 40 87 15 198 44 285 74 44 15 50 20 32 24 -12 4 -22 2 -22 -3 0
-11 -41 -12 -58 0 -21 13 52 21 90 10 22 -6 34 -6 36 1 2 5 15 10 28 10 13 0
32 8 42 18 10 10 23 17 30 14 14 -4 212 97 212 109 0 5 -7 9 -16 9 -8 0 -12
-4 -9 -10 3 -5 -1 -10 -9 -10 -29 0 -26 19 6 29 40 14 90 51 68 51 -8 0 -44
-18 -80 -40z m-10 -50 c0 -5 -7 -10 -15 -10 -20 0 -85 -32 -85 -42 0 -4 -7 -8
-16 -8 -26 0 2 27 50 50 46 22 66 25 66 10z m-145 -60 c3 -5 -3 -10 -14 -10
-12 0 -21 5 -21 10 0 6 6 10 14 10 8 0 18 -4 21 -10z m-335 -121 c0 -5 -12
-11 -27 -15 -16 -3 -40 -9 -54 -14 -14 -4 -32 -4 -39 0 -16 10 -1 19 45 25 21
2 39 6 41 8 7 7 34 4 34 -4z m-845 -49 c-4 -6 9 -10 32 -9 21 0 54 -4 73 -10
19 -6 87 -10 150 -9 l115 2 -110 6 c-101 6 -104 6 -34 8 42 1 118 -1 170 -5
l94 -8 -70 -6 c-111 -10 -277 -10 -313 1 -18 5 -60 10 -92 11 -44 2 -57 5 -53
16 3 7 14 13 25 13 10 0 16 -4 13 -10z"/>
<path d="M7030 3004 c-24 -21 -28 -29 -18 -36 9 -6 7 -8 -8 -3 -14 3 -29 -3
-47 -19 -15 -14 -35 -30 -44 -35 -16 -9 -16 -12 -3 -21 21 -14 30 -12 24 5
-12 29 14 15 26 -15 8 -18 20 -30 31 -30 24 0 25 17 2 23 -10 2 -3 4 15 4 17
0 32 -3 32 -6 0 -9 -86 -81 -96 -81 -4 0 -15 -7 -25 -16 -10 -9 -24 -14 -31
-11 -9 4 -6 8 7 12 11 3 29 19 40 35 11 16 22 27 26 24 4 -2 5 5 2 15 -3 12
-11 17 -19 14 -7 -3 -21 2 -29 11 -21 20 -44 21 -51 1 -4 -8 -12 -15 -20 -15
-8 0 -14 -4 -14 -10 0 -14 23 -13 38 2 9 9 12 5 12 -19 0 -17 6 -34 13 -36 6
-3 3 -6 -9 -6 -30 -1 -75 22 -69 37 7 18 -3 15 -44 -16 -20 -15 -56 -38 -81
-51 -25 -13 -49 -28 -55 -34 -15 -14 37 3 55 18 8 7 29 15 45 17 19 2 30 9 30
18 0 18 21 33 35 25 18 -11 10 -44 -12 -51 -13 -3 -31 -15 -41 -25 -14 -15
-22 -17 -38 -9 -15 8 -23 7 -35 -4 -10 -10 -25 -13 -43 -10 -21 4 -46 -2 -88
-24 -87 -44 -161 -76 -233 -102 -36 -13 -69 -26 -75 -30 -9 -6 -42 -17 -80
-25 -49 -12 -184 -49 -200 -56 -33 -14 -320 -50 -405 -51 -44 -1 -78 -3 -75
-6 4 -4 86 -15 223 -29 34 -3 62 -10 62 -14 0 -14 -144 -11 -158 3 -9 9 -28 8
-83 -1 -52 -10 -74 -10 -82 -2 -13 13 -139 15 -153 2 -5 -5 -44 -12 -88 -15
-62 -6 -77 -4 -74 6 3 7 22 14 44 16 21 1 55 5 74 9 l35 7 -40 6 -40 7 43 2
c23 0 42 -3 42 -7 0 -4 11 -8 24 -8 15 0 22 5 19 13 -3 9 -25 13 -67 13 -73 0
-206 15 -296 34 -89 19 -339 84 -351 91 -12 8 -49 -4 -49 -15 0 -4 17 -7 38
-5 20 1 27 0 14 -2 -17 -3 -21 -9 -17 -24 9 -26 -1 -24 -34 9 -52 49 -147 97
-126 62 3 -6 -4 -10 -17 -10 -18 0 -20 2 -9 9 12 8 12 12 2 25 -8 9 -11 16 -9
16 15 0 77 -26 100 -42 28 -19 58 -24 58 -9 0 5 -12 12 -27 16 -15 3 -45 15
-67 26 -22 10 -46 19 -53 19 -7 0 -13 5 -13 10 0 6 -5 10 -11 10 -16 0 -149
63 -149 71 0 4 -11 10 -25 13 -13 3 -41 17 -61 31 -20 14 -43 25 -50 25 -7 0
-14 3 -16 8 -3 8 -114 82 -123 82 -16 0 -1 -22 20 -30 22 -8 24 -14 19 -37 -9
-37 -9 -37 -36 -19 -14 9 -27 13 -31 10 -7 -8 36 -44 52 -44 6 0 11 -4 11 -10
0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 -6 10 -13 10 -7 0 -23 9 -35 20
-12 11 -26 20 -32 20 -5 0 -10 6 -10 14 0 8 -9 18 -20 21 -11 3 -20 10 -20 15
0 5 -8 6 -17 3 -13 -5 -15 -2 -10 11 5 12 3 16 -5 14 -20 -7 -15 -29 7 -42 11
-6 22 -13 25 -16 14 -15 89 -70 95 -70 3 0 13 -7 22 -16 9 -8 22 -13 30 -10 8
3 11 2 8 -3 -5 -9 117 -87 195 -125 19 -9 49 -25 65 -34 17 -10 50 -26 75 -36
25 -10 54 -22 65 -27 79 -34 256 -97 285 -102 19 -3 44 -1 55 4 15 7 12 8 -15
4 -31 -4 -32 -4 -11 5 13 6 38 8 56 4 18 -3 35 -1 39 4 4 7 24 5 59 -4 68 -18
120 -17 57 1 -42 12 -43 13 -14 14 17 1 46 -2 65 -6 28 -5 30 -7 14 -14 -17
-6 -15 -8 8 -8 36 -1 31 -25 -6 -29 -15 -2 -27 -8 -27 -13 0 -5 -10 -7 -22 -4
-13 3 -33 7 -45 8 -12 0 -19 5 -16 10 3 5 23 7 44 4 22 -3 39 -1 39 4 0 5 -19
9 -43 9 -24 0 -48 5 -53 10 -7 7 -29 6 -69 -3 l-59 -13 45 -12 c78 -21 251
-51 374 -66 153 -17 401 -21 410 -6 4 6 20 8 38 5 18 -3 85 1 150 9 65 9 120
16 123 16 3 0 3 4 0 9 -8 13 39 41 69 41 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 5 -25 10 0 14 11 13 53 -5 25 -10 38 -11 42 -4 9 14 7 15 -47 27
-27 5 -48 6 -48 1 0 -12 -37 -11 -45 1 -4 6 -23 8 -43 5 -36 -5 -36 -5 -7 5
17 6 45 9 63 6 18 -3 30 -1 27 3 -2 5 16 5 41 0 25 -5 48 -8 52 -7 18 4 64
-16 72 -31 7 -15 5 -17 -21 -13 -16 2 -29 0 -29 -6 0 -6 -26 -16 -57 -23 -35
-8 -49 -14 -37 -17 19 -3 113 17 237 49 20 5 37 16 37 23 0 8 11 21 24 30 14
8 27 25 31 36 7 24 43 27 51 5 4 -10 14 -14 28 -10 18 5 17 4 -4 -9 -32 -20
-44 -20 -36 -1 4 10 -1 15 -14 15 -22 0 -27 -16 -7 -23 7 -2 -1 -11 -18 -20
-84 -45 91 13 234 79 66 30 79 39 70 51 -17 20 15 44 61 47 8 1 16 11 18 24 2
14 10 22 24 22 11 0 37 10 58 21 29 17 42 19 56 10 17 -9 17 -10 -7 -11 -14 0
-35 -9 -47 -20 -12 -11 -28 -20 -37 -20 -8 0 -15 -4 -15 -9 0 -5 -20 -18 -45
-30 -41 -19 -60 -41 -35 -41 6 0 10 4 10 8 0 5 16 15 35 22 20 8 41 20 47 27
6 7 17 13 25 13 7 0 26 8 41 19 15 10 70 47 122 82 127 83 183 130 170 143
-13 13 -13 63 1 71 6 5 4 12 -8 21 -17 13 -16 15 12 38 17 14 25 25 18 26 -6
0 -26 -12 -43 -26z m-3 -80 c2 -6 -12 -15 -32 -21 -27 -9 -35 -8 -35 1 0 31
58 48 67 20z m-2947 -154 c0 -5 3 -9 8 -8 7 2 84 -33 117 -54 63 -41 75 -51
69 -60 -4 -7 -10 -4 -16 6 -5 9 -14 14 -20 10 -5 -3 -23 3 -39 15 -15 11 -33
21 -39 21 -5 0 -10 7 -10 16 0 12 -6 15 -26 10 -14 -4 -23 -3 -21 1 3 4 -6 9
-20 10 -39 2 -60 43 -23 43 11 0 20 -4 20 -10z m2753 -49 c-25 -21 -74 -18
-53 3 8 8 13 7 20 -4 8 -12 10 -12 16 3 3 9 14 17 23 17 13 -1 12 -4 -6 -19z
m-2783 -16 c7 -9 21 -13 35 -9 13 3 26 0 30 -6 10 -15 -13 -30 -34 -22 -21 7
-67 52 -54 52 6 0 16 -7 23 -15z m2585 -15 c3 -5 -5 -12 -19 -16 -16 -4 -23
-12 -21 -23 2 -10 -5 -23 -16 -29 -21 -14 -25 -3 -6 17 10 11 5 12 -28 6 -48
-8 -42 -1 22 31 55 27 60 28 68 14z m-2457 -50 c-14 -21 -33 -26 -43 -11 -6
10 22 30 43 31 10 0 10 -4 0 -20z m2462 10 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15
10 0 2 7 4 15 4 8 0 15 -4 15 -10z m-2305 -10 c-3 -5 0 -10 7 -10 8 0 15 -11
16 -25 1 -15 -3 -25 -12 -25 -7 0 -19 -6 -25 -14 -10 -11 -8 -17 6 -25 26 -15
11 -13 -28 4 -19 8 -29 15 -24 15 6 1 1 5 -10 10 -18 8 -18 9 5 10 14 2 28 2
32 1 13 -2 19 40 8 54 -10 12 -9 15 9 15 12 0 19 -4 16 -10z m-98 -16 c3 -9 0
-20 -7 -24 -15 -9 -50 -1 -50 12 0 6 7 7 16 4 12 -4 15 -2 10 9 -3 9 0 15 10
15 8 0 17 -7 21 -16z m2261 -13 c28 -24 32 -77 5 -66 -10 4 -27 9 -38 11 -11
2 -8 -1 8 -7 15 -6 25 -15 21 -20 -3 -5 -10 -7 -15 -4 -5 3 -19 -3 -31 -14
-27 -26 -49 -27 -42 -3 5 13 4 14 -4 3 -6 -8 -17 -11 -27 -7 -27 10 2 39 31
32 44 -11 38 26 -6 39 -24 7 -24 7 -5 -9 19 -14 18 -15 -12 -4 -37 14 -43 28
-14 28 11 0 23 5 26 10 3 6 17 10 30 10 13 0 27 5 30 10 9 14 17 12 43 -9z
m-164 -56 c-4 -9 -15 -20 -25 -25 -25 -14 -36 7 -13 25 25 19 45 19 38 0z
m-1941 -12 c-21 -8 -15 -20 20 -37 26 -12 28 -16 11 -16 -12 0 -27 7 -34 15
-7 8 -19 15 -26 15 -8 0 -14 4 -14 9 0 11 18 19 40 19 10 0 11 -1 3 -5z m108
-13 c23 -13 25 -26 5 -33 -13 -5 -56 23 -56 37 0 9 29 7 51 -4z m1731 -9 c-7
-4 -12 -12 -11 -17 4 -22 -2 -34 -18 -34 -17 1 -17 1 0 14 16 13 16 14 1 20
-10 4 -20 2 -24 -4 -4 -6 -14 -8 -23 -4 -9 3 -14 10 -11 15 3 5 10 7 15 4 5
-4 17 -1 26 4 22 13 65 15 45 2z m-1519 -21 c57 -11 59 -24 2 -15 -27 5 -63 8
-79 7 -27 -1 -28 -1 -10 13 10 8 26 12 34 8 8 -3 32 -9 53 -13z m1380 -7 c-20
-7 -15 -20 9 -27 18 -4 20 -8 11 -17 -9 -9 -19 -9 -42 2 -30 15 -30 31 -1 42
18 7 40 7 23 0z m53 -9 c18 -14 18 -14 -6 -3 -31 14 -36 19 -24 19 6 0 19 -7
30 -16z m-1588 -10 c12 -4 22 -12 22 -20 0 -8 6 -11 15 -8 8 4 13 10 10 14 -9
14 16 23 28 11 9 -9 8 -14 -3 -21 -18 -11 -7 -23 28 -28 40 -5 40 -12 -1 -12
-20 0 -39 5 -42 10 -3 6 -16 10 -28 10 -13 0 -33 7 -46 17 -13 9 -38 19 -55
24 -30 7 -30 7 9 8 22 0 50 -2 63 -5z m367 -24 c33 -5 76 -14 95 -20 19 -5 60
-10 90 -11 49 -1 52 -2 25 -10 -32 -9 -135 4 -168 22 -10 6 -58 9 -107 8 -93
-2 -139 10 -85 22 31 8 47 6 150 -11z m1247 -42 c-4 -4 -23 -11 -42 -14 -32
-7 -34 -6 -21 9 7 9 22 17 32 17 11 0 19 6 19 13 0 8 3 7 10 -3 5 -8 6 -18 2
-22z m-419 5 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-80 -10
c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m194 1 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1007 -34 c0 -5 -11 -10 -25 -10 -14
0 -25 5 -25 10 0 6 11 10 25 10 14 0 25 -4 25 -10z m863 -31 c91 -1 113 -3 87
-10 -19 -5 -48 -6 -65 -3 -21 4 -26 2 -16 -4 10 -7 -6 -12 -54 -17 -50 -6 -69
-4 -75 5 -6 10 -18 10 -53 1 -52 -13 -312 -14 -372 -2 -23 5 31 6 131 3 169
-5 250 1 239 18 -6 10 24 19 43 14 8 -2 68 -4 135 -5z m-293 -9 c0 -6 -32 -10
-75 -10 -43 0 -75 4 -75 10 0 6 32 10 75 10 43 0 75 -4 75 -10z m-307 -17 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M6467 2607 c-5 -13 -14 -16 -34 -12 -27 6 -27 6 -9 -9 20 -16 67 -22
65 -8 0 4 -4 16 -8 26 -7 18 -8 18 -14 3z"/>
<path d="M3633 2983 c9 -9 19 -14 23 -11 10 10 -6 28 -24 28 -15 0 -15 -1 1
-17z"/>
<path d="M3710 2910 c30 -27 60 -50 65 -50 6 0 3 5 -5 10 -8 5 -11 10 -7 10
11 0 -11 22 -45 44 -15 10 -25 22 -22 27 3 5 -5 9 -18 9 -20 0 -15 -7 32 -50z"/>
<path d="M6791 2784 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5360 2402 c0 -4 20 -8 44 -8 25 0 42 4 40 8 -3 5 -23 8 -45 8 -21 0
-39 -3 -39 -8z"/>
<path d="M6640 1973 c-14 -2 -49 -13 -77 -25 -47 -18 -52 -23 -47 -44 5 -20 2
-24 -15 -24 -12 0 -21 5 -21 11 0 6 -11 -3 -25 -19 -14 -17 -25 -33 -25 -38 0
-4 -7 -15 -15 -24 -21 -23 -45 -121 -45 -182 0 -58 27 -175 44 -194 6 -7 24
-31 40 -53 41 -57 139 -106 212 -106 29 0 53 4 53 9 0 5 5 12 11 16 21 13 -1
32 -32 28 -21 -4 -28 -1 -24 9 3 8 -4 17 -17 22 -17 7 -10 9 30 9 28 1 60 6
71 13 21 13 52 6 52 -11 0 -6 -11 -8 -27 -4 -22 4 -25 3 -13 -5 8 -6 29 -8 45
-5 28 6 29 5 11 -9 -15 -11 -28 -12 -50 -6 -17 5 -38 9 -46 9 -21 -1 30 -29
55 -31 13 -1 10 -3 -9 -8 -16 -3 -31 -12 -34 -19 -3 -9 3 -10 23 -2 44 17 94
60 88 76 -3 8 0 14 6 14 6 0 11 -6 12 -12 0 -7 4 -4 9 7 4 11 6 29 4 40 -2 11
3 34 11 50 15 30 15 19 -1 -37 -5 -18 -4 -20 5 -12 27 25 56 213 34 214 -241
12 -241 12 -239 -46 l1 -44 62 0 c90 0 95 -3 77 -52 -8 -22 -14 -41 -14 -42 0
-1 8 -2 19 -2 16 0 18 6 13 40 -4 28 -2 37 6 32 26 -16 4 -116 -25 -116 -7 0
-18 10 -23 23 -5 12 -9 16 -9 10 -2 -36 -149 -60 -201 -33 -14 7 -41 30 -62
51 -48 52 -67 143 -50 239 12 63 52 140 74 140 6 0 8 3 4 7 -10 10 72 33 120
33 46 0 104 -17 104 -31 0 -5 5 -8 11 -6 9 2 21 -13 36 -41 3 -7 48 43 46 50
-2 4 5 4 14 1 25 -10 -20 50 -58 78 -30 21 -140 60 -162 58 -7 -1 -23 -4 -37
-6z m113 -33 c21 -11 42 -18 46 -15 5 3 11 -2 15 -10 7 -19 -48 -21 -65 -2 -8
8 -36 12 -78 11 l-66 -1 60 7 c61 7 66 13 8 10 -18 -1 -33 3 -33 9 0 18 73 12
113 -9z m-126 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-64
-37 c-4 -20 -1 -22 32 -21 43 2 35 -11 -12 -21 -31 -6 -33 -5 -33 23 0 24 7
42 15 42 1 0 0 -10 -2 -23z m140 -4 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25
0 35 -2 23 -4z m167 -37 c-12 -12 -40 0 -40 18 0 11 5 12 24 3 15 -7 21 -16
16 -21z m-147 17 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m96
-17 c14 -15 30 -22 40 -19 14 5 14 4 -1 -12 -17 -18 -18 -18 -42 8 -14 15 -30
27 -36 27 -6 0 -8 5 -5 10 10 15 19 12 44 -14z m-333 -33 c-3 -4 -6 -1 -6 7 0
10 -3 10 -15 0 -18 -15 -20 1 -3 18 9 9 14 9 21 -4 5 -8 6 -18 3 -21z m30 -22
c-10 -11 -22 -35 -26 -53 -7 -31 -8 -31 -9 -6 -1 37 30 101 43 88 6 -6 3 -16
-8 -29z m-50 -49 c-11 -10 -14 3 -8 33 l7 30 3 -28 c2 -16 1 -31 -2 -35z m-30
11 c-11 -11 -17 4 -9 23 7 18 8 18 11 1 2 -10 1 -21 -2 -24z m-22 -9 c3 -8 2
-12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m-15 -81 c-8 -8 -8 -23 -1 -45
6 -18 7 -53 3 -78 l-7 -45 -7 68 c-8 74 0 148 14 126 4 -7 3 -19 -2 -26z m66
-29 c-7 -40 -22 -44 -31 -9 -4 16 0 35 12 53 16 23 19 25 22 9 2 -11 1 -34 -3
-53z m465 -83 c0 -28 -5 -53 -11 -57 -8 -5 -10 1 -5 19 3 14 2 36 -3 49 -9 20
-10 18 -11 -17 -1 -22 -5 -49 -10 -60 -6 -13 -7 -5 -3 24 4 31 3 42 -5 37 -6
-4 -9 -13 -5 -21 6 -18 -4 -19 -35 -1 l-24 13 31 13 c17 7 31 16 31 21 0 11
-73 11 -125 -1 -32 -8 -27 -8 30 -4 86 6 80 -6 -8 -15 -53 -6 -64 -4 -64 9 -3
37 5 40 112 40 l105 0 0 -49z m-452 -38 c17 -37 15 -45 -7 -34 -21 12 -34 38
-25 52 9 15 18 10 32 -18z m-22 -95 c-8 -5 -7 -8 1 -8 6 0 14 4 17 8 9 15 45
7 68 -15 13 -12 38 -30 57 -41 l34 -21 -26 -6 c-14 -4 -30 -4 -36 -1 -5 4 -12
2 -16 -3 -3 -5 3 -14 12 -20 16 -10 15 -10 -5 -8 -12 2 -28 9 -34 15 -7 7 -20
12 -31 12 -10 0 -15 5 -12 10 3 6 1 10 -4 10 -6 0 -11 6 -11 14 0 7 -7 19 -15
26 -9 7 -23 29 -32 49 -9 20 -19 40 -22 46 -3 5 11 -6 30 -25 23 -22 32 -38
25 -42z m184 -98 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4
-4 4 -10z m-12 -37 c6 -19 -25 -16 -32 3 -4 11 -1 14 11 12 9 -2 18 -9 21 -15z
m52 -9 c0 -2 -9 -4 -20 -4 -11 0 -20 4 -20 9 0 5 9 7 20 4 11 -3 20 -7 20 -9z"/>
<path d="M6500 1381 c0 -22 25 -35 47 -22 9 4 25 7 37 5 12 -3 3 4 -19 15 -51
25 -65 25 -65 2z"/>
<path d="M7460 1973 c-47 -9 -125 -51 -162 -87 -39 -40 -78 -106 -78 -134 0
-10 -5 -23 -12 -30 -7 -7 -11 -46 -11 -99 0 -74 4 -97 27 -147 37 -83 114
-155 197 -184 68 -24 114 -28 124 -12 4 6 -10 10 -34 10 -21 0 -60 9 -85 20
-25 10 -46 17 -46 14 0 -15 -51 32 -56 52 -3 13 -16 26 -27 30 -36 12 -61 54
-32 54 8 0 15 -7 15 -15 0 -8 8 -15 18 -15 10 0 33 -16 52 -35 19 -19 30 -35
25 -35 -5 0 -3 -6 3 -12 10 -10 15 -10 22 2 13 20 12 30 0 30 -15 0 -60 46
-60 61 0 6 13 -3 29 -20 33 -35 73 -53 98 -44 11 4 15 2 10 -3 -5 -5 -23 -14
-40 -20 -21 -8 -27 -13 -17 -16 8 -3 35 -11 60 -19 45 -14 110 -7 110 13 0 4
8 8 18 8 11 0 13 3 5 8 -17 11 -11 46 10 54 9 4 17 15 17 24 0 13 3 14 13 5 8
-6 17 -8 20 -4 4 3 7 -6 7 -20 0 -15 -4 -27 -10 -27 -5 0 -7 8 -3 18 6 15 4
15 -15 -2 -20 -18 -21 -43 -1 -31 20 13 8 -5 -23 -34 -40 -37 -23 -41 28 -6
71 48 104 91 129 170 25 76 33 159 16 169 -5 3 -8 -22 -7 -57 1 -57 -17 -127
-33 -127 -3 0 -17 9 -30 19 -20 16 -22 23 -16 60 9 52 28 49 35 -5 5 -37 6
-38 18 -17 14 25 17 77 3 54 -5 -8 -11 -9 -15 -3 -3 6 1 17 9 25 17 18 20 43
5 52 -6 4 -8 10 -5 15 3 5 2 21 -1 37 -6 24 -8 25 -14 8 -5 -13 -9 -2 -13 35
-4 30 -16 67 -27 82 -11 16 -17 28 -12 28 16 0 52 -47 52 -68 0 -12 5 -22 10
-22 6 0 10 -10 10 -23 0 -26 21 -77 33 -77 4 0 7 1 7 3 -1 9 -30 97 -41 122
-14 31 -90 115 -104 115 -5 0 -16 7 -26 16 -17 16 -112 45 -139 43 -8 -1 -26
-4 -40 -6z m149 -39 c34 -15 61 -31 61 -35 0 -17 -47 -9 -65 11 -19 21 -65 28
-65 10 0 -5 9 -10 20 -10 11 0 20 -6 20 -14 0 -8 5 -16 12 -18 7 -3 -29 -2
-80 0 l-92 4 -31 -33 c-22 -24 -33 -29 -38 -20 -6 8 -10 2 -14 -19 -6 -26 -10
-30 -28 -25 -26 9 -59 -20 -59 -52 0 -12 -4 -23 -10 -23 -14 0 -12 40 3 70 7
14 19 25 27 25 8 0 17 11 19 25 4 35 65 92 80 77 7 -7 41 -13 77 -14 55 -1 66
2 75 19 11 21 -1 37 -27 38 -23 0 -24 -14 -2 -27 22 -12 20 -12 -17 -11 -22 0
-44 -1 -50 -2 -22 -5 -34 1 -29 14 16 43 126 48 213 10z m11 -80 c25 -3 50 -6
56 -6 12 -1 19 -68 7 -68 -4 0 -19 16 -33 35 -14 19 -32 35 -40 35 -8 0 1 -15
23 -38 20 -21 37 -44 37 -51 0 -6 4 -10 9 -7 23 15 29 -184 6 -237 -42 -101
-133 -150 -231 -126 -87 22 -154 121 -154 229 0 70 35 155 82 200 53 52 64 54
238 34z m-307 -105 c-12 -66 -32 -131 -37 -119 -12 30 -11 65 2 63 11 -3 16
22 15 65 -1 7 4 11 11 9 6 -2 10 -10 9 -18z m-36 -11 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m447 0 c9 -34 7 -60 -3 -54 -11 7 -20 76 -10 76
4 0 9 -10 13 -22z m-494 -48 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m520 -30 c-5 -21 -9 -27 -10 -15 -1 16 -4 14 -15 -10
l-14 -30 -1 33 c0 22 5 32 15 32 9 0 18 7 22 15 10 27 12 12 3 -25z m-518 -58
c6 -6 12 -33 13 -60 4 -60 5 -61 15 -37 8 19 9 19 9 -2 1 -12 -3 -24 -9 -28
-19 -12 -38 42 -43 120 -3 44 -3 67 0 50 3 -16 9 -36 15 -43z m84 -111 c15
-34 8 -51 -21 -51 -5 0 -3 5 5 10 13 8 13 12 -1 37 -8 15 -16 46 -17 68 l-1
40 10 -35 c6 -19 17 -50 25 -69z m412 -54 c29 3 28 -6 -3 -36 -24 -23 -51 -23
-28 0 13 13 -15 52 -30 42 -11 -6 -2 12 17 36 9 11 12 8 16 -15 5 -23 11 -28
28 -27z m-138 -67 c0 -13 -11 -13 -30 0 -12 8 -11 10 8 10 12 0 22 -4 22 -10z
m-45 -10 c4 -6 -7 -10 -27 -9 -27 0 -30 2 -13 9 27 12 33 12 40 0z"/>
<path d="M7354 1869 c3 -13 3 -18 0 -11 -6 14 -34 17 -34 3 0 -5 10 -11 22
-14 33 -9 51 30 19 41 -9 3 -11 -3 -7 -19z"/>
<path d="M7306 1833 c-9 -9 -7 -33 3 -33 12 0 22 28 12 34 -5 3 -11 3 -15 -1z"/>
<path d="M7660 1831 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M3070 1888 c-7 -40 -12 -99 -12 -131 1 -32 -3 -55 -7 -52 -5 2 -14
-46 -21 -108 -6 -61 -17 -154 -24 -204 l-13 -93 49 0 c26 0 48 1 49 3 5 13 12
197 8 197 -4 0 -9 -37 -13 -82 -3 -46 -6 -89 -7 -95 -3 -18 -39 -16 -39 2 0 8
-6 14 -12 13 -8 -2 -12 5 -11 17 2 11 5 54 8 95 9 104 14 128 26 109 13 -20
11 -60 -6 -126 -17 -66 -19 -91 -6 -77 5 5 12 25 14 44 2 19 3 10 2 -20 -2
-36 0 -48 5 -35 5 11 9 40 9 65 1 25 4 62 7 83 5 30 9 35 20 26 10 -8 15 -8
18 2 10 33 25 247 20 284 -4 27 -3 36 4 26 8 -11 10 -11 15 0 2 8 8 1 11 -16
30 -129 35 -143 47 -124 7 11 9 10 9 -3 0 -9 -4 -18 -10 -20 -6 -2 -2 -25 9
-58 29 -88 50 -162 57 -207 4 -24 10 -43 15 -43 5 0 9 -13 9 -30 0 -25 4 -30
25 -30 14 0 28 -1 31 -2 6 -3 59 161 67 207 2 11 9 33 15 48 7 16 12 35 12 42
0 8 7 35 16 61 27 81 44 176 33 195 -5 11 -5 20 0 24 5 3 12 -5 14 -17 l4 -23
2 23 c2 35 18 26 18 -10 -1 -18 0 -71 3 -118 3 -82 3 -83 9 -30 l6 55 9 -43
c4 -23 11 -40 16 -37 5 3 11 -1 13 -7 3 -7 6 16 7 52 2 61 3 58 16 -50 15
-128 43 -301 50 -312 3 -5 1 -8 -5 -8 -5 0 -13 21 -18 48 l-8 47 -6 -40 c-6
-34 -6 -32 -4 13 2 29 1 50 -3 48 -4 -2 -6 24 -4 57 4 65 -11 123 -28 112 -14
-9 -13 -112 1 -121 9 -5 10 9 5 52 -5 42 -4 55 3 44 14 -20 14 -123 1 -115 -6
4 -10 -12 -9 -42 0 -36 3 -43 9 -28 7 16 9 13 9 -17 1 -55 10 -68 43 -68 24 0
29 3 24 16 -6 17 -13 63 -58 407 -12 92 -24 167 -28 167 -3 0 -4 -35 -2 -77 4
-80 -2 -96 -11 -33 -13 81 -27 110 -53 110 -25 0 -27 -2 -40 -55 -5 -22 -8
-25 -11 -12 -3 10 1 32 7 48 12 29 24 33 103 32 9 0 17 4 17 9 0 4 -26 8 -58
8 -53 0 -60 -2 -70 -25 -7 -14 -12 -33 -12 -41 0 -8 -4 -22 -9 -32 -17 -29
-71 -237 -71 -270 0 -17 -5 -32 -12 -34 -7 -3 -13 -18 -14 -34 -3 -59 -4 -64
-14 -64 -5 0 -12 10 -14 23 -3 12 -12 56 -22 96 -14 63 -19 71 -26 52 -5 -16
-3 -33 7 -54 8 -16 15 -55 15 -85 0 -42 3 -53 14 -49 10 4 13 -5 12 -36 -1
-37 1 -42 22 -41 12 0 22 -5 22 -12 0 -7 -8 -10 -20 -7 -11 3 -20 0 -20 -6 0
-6 7 -11 15 -11 8 0 15 -5 15 -11 0 -6 -10 -9 -22 -7 -19 2 -24 11 -29 49 -4
26 -12 55 -17 65 -6 11 -8 33 -6 49 2 17 1 24 -2 17 -9 -19 -24 -3 -24 26 0
21 3 23 10 12 17 -27 20 -3 5 40 -18 51 -19 87 -3 92 9 3 9 12 1 34 -6 16 -19
56 -28 89 -10 33 -22 73 -28 88 -5 15 -11 38 -12 50 -1 12 -3 25 -4 30 0 4
-28 7 -60 7 l-59 0 -12 -72z m85 42 l-40 -5 -2 -74 c-1 -40 -7 -87 -12 -104
-13 -37 -14 -67 -2 -67 5 0 13 25 16 55 4 31 10 54 12 51 5 -5 -3 -75 -23
-204 -3 -20 -9 -30 -16 -26 -7 4 -8 28 -4 65 3 36 2 59 -4 59 -11 0 -6 213 5
242 8 19 36 25 80 18 29 -4 28 -5 -10 -10z m15 -65 c0 -8 -7 -15 -15 -15 -9 0
-12 6 -9 15 4 8 10 15 15 15 5 0 9 -7 9 -15z m50 -75 c5 -3 16 -31 24 -63 13
-49 13 -62 1 -90 l-14 -32 1 54 c1 30 -3 56 -8 58 -6 2 -9 16 -6 32 2 16 -1
32 -7 35 -7 5 -11 -3 -11 -20 0 -15 -3 -25 -6 -22 -3 3 -2 34 3 69 7 57 8 60
11 24 2 -22 7 -42 12 -45z m257 -1 c12 -15 12 -21 -1 -50 l-14 -34 -1 30 c-1
23 -4 26 -11 15 -5 -8 -12 -11 -17 -7 -4 4 -2 17 5 27 7 11 13 32 13 47 1 21
3 23 6 8 3 -11 12 -27 20 -36z m86 14 c6 -34 3 -53 -7 -42 -9 10 -16 79 -8 79
5 0 12 -17 15 -37z m24 -90 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0
-35z m-161 -35 c-17 -24 -22 -73 -7 -64 17 11 17 -17 -1 -52 -9 -19 -15 -39
-12 -44 6 -10 -17 -78 -27 -78 -14 0 -19 37 -9 61 10 24 10 24 7 -3 -3 -16 0
-28 4 -28 5 0 9 6 10 13 0 6 3 35 5 62 2 28 4 64 4 80 -1 17 4 39 9 50 5 11
13 29 17 40 6 18 8 18 11 3 3 -10 -3 -28 -11 -40z m20 -46 c-12 -11 -18 7 -10
30 l8 23 4 -23 c2 -13 1 -26 -2 -30z m-193 -52 c3 -11 1 -18 -4 -14 -5 3 -9
12 -9 20 0 20 7 17 13 -6z m107 -150 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M3086 1844 c-10 -25 -7 -84 4 -84 6 0 10 23 10 50 0 52 -4 61 -14 34z"/>
<path d="M3752 1699 l3 -264 2 220 c2 126 8 224 13 229 7 7 10 -86 10 -266 l0
-277 33 -4 c30 -4 29 -4 -8 -6 -22 -1 -47 -6 -55 -11 -24 -16 7 -31 55 -28
l40 3 0 163 -1 162 23 0 c13 0 31 4 41 9 9 5 28 14 42 19 22 9 19 11 -27 11
-82 1 -80 -1 -75 111 4 96 3 98 -17 93 -15 -4 -21 -1 -21 11 0 25 27 30 55 12
13 -9 32 -16 42 -16 14 0 14 3 -5 20 -29 27 -28 32 4 20 19 -7 23 -7 18 2 -5
8 -2 9 10 5 10 -4 27 -7 37 -7 12 0 17 -4 13 -11 -3 -6 0 -16 7 -21 9 -6 3 -7
-18 -3 -42 8 -42 1 -1 -19 36 -19 41 -20 33 -6 -3 6 -2 10 2 10 16 0 15 -146
-1 -162 -8 -8 -12 -25 -9 -39 4 -24 1 -26 -51 -38 -31 -7 -58 -15 -60 -19 -5
-9 27 -82 36 -82 5 0 8 12 8 26 0 14 -4 23 -9 20 -5 -3 -12 1 -15 10 -9 22 -8
23 13 17 10 -3 22 0 25 6 14 23 36 11 36 -19 0 -16 -4 -30 -10 -30 -5 0 -10 5
-10 10 0 6 -4 10 -10 10 -5 0 -10 -11 -10 -24 0 -14 -4 -27 -9 -31 -7 -4 72
-181 95 -213 1 -2 29 -5 62 -7 55 -3 59 -2 55 16 -3 11 -13 25 -22 32 -9 7
-16 17 -16 22 -1 10 -34 78 -81 165 -31 58 -32 90 -1 90 12 0 32 8 43 18 39
31 73 133 54 162 -7 11 -10 6 -10 -20 0 -20 -7 -46 -16 -58 -9 -13 -13 -30
-10 -38 4 -9 -6 -22 -27 -34 -35 -22 -47 -25 -47 -12 0 5 11 14 25 20 14 6 25
18 25 26 0 8 7 22 16 31 9 9 14 25 11 35 -3 11 -1 18 4 14 5 -3 9 14 8 38 0
33 -2 38 -9 23 -7 -16 -9 -17 -9 -3 -1 9 -12 30 -26 46 -25 30 -32 52 -17 52
12 0 52 -44 52 -58 0 -7 5 -12 10 -12 15 0 11 23 -8 46 -39 46 -80 58 -214 62
l-128 4 2 -263z m251 235 c10 -6 -33 -8 -111 -7 -148 2 -135 1 -127 13 8 12
214 7 238 -6z m34 -116 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m24 -75 c-10 -9 -17 -10 -21 -2 -10 14 0 60 11 53 5 -3 10 1 10 8 1 7 5 0 8
-17 6 -21 3 -34 -8 -42z m-21 -53 c0 -14 -6 -20 -22 -19 -17 1 -19 2 -5 6 9 2
17 11 17 19 0 8 2 14 5 14 3 0 5 -9 5 -20z m-160 -44 c0 -2 -9 -6 -20 -9 -11
-3 -20 -1 -20 4 0 5 9 9 20 9 11 0 20 -2 20 -4z m134 -122 c4 -10 1 -14 -6
-12 -15 5 -23 28 -10 28 5 0 13 -7 16 -16z m-41 -19 c-3 -9 1 -23 9 -33 8 -9
15 -30 15 -47 1 -27 -1 -24 -23 22 -24 50 -25 73 -4 73 5 0 7 -7 3 -15z m49
-28 c16 -6 68 -95 68 -117 0 -5 -6 -10 -13 -10 -14 0 -47 60 -47 86 0 8 -4 12
-10 9 -5 -3 -10 1 -10 9 0 9 -4 16 -10 16 -5 0 -10 8 -10 18 0 10 3 13 8 6 4
-6 15 -13 24 -17z m18 -127 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m66 -23 c8 3 14 -1 14 -11 0 -17 -15 -22 -24 -7 -3 5
-15 7 -25 4 -10 -3 -22 1 -26 8 -6 9 -2 11 13 7 13 -4 22 -1 23 5 0 9 2 9 5 0
3 -7 12 -9 20 -6z m-283 -14 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2
23 -4z"/>
<path d="M4210 1631 l0 -329 23 -6 c12 -3 36 -6 54 -6 l32 0 3 138 c2 75 0
139 -4 140 -5 2 -8 8 -8 13 0 6 5 7 10 4 6 -3 10 -1 10 5 0 6 5 8 11 5 6 -4 8
-13 5 -21 -8 -21 63 -18 117 5 41 18 106 83 89 89 -5 1 -27 -15 -50 -36 -50
-46 -142 -80 -142 -52 0 6 11 10 24 10 27 0 96 37 96 51 0 5 7 9 15 9 8 0 15
7 15 15 0 9 9 15 25 15 20 0 25 6 31 35 12 66 -4 140 -39 178 -47 51 -99 67
-217 67 l-100 0 0 -329z m243 294 c13 -8 27 -13 30 -10 4 3 3 0 -2 -6 -6 -7
-18 -6 -39 5 -19 10 -56 16 -97 16 -63 0 -65 -1 -65 -25 0 -29 7 -31 32 -8 17
15 19 15 34 -3 13 -16 15 -16 10 -2 -7 23 12 34 21 12 6 -16 7 -16 14 2 7 18
8 18 8 -3 1 -12 -5 -23 -11 -26 -7 -2 -1 -8 14 -12 40 -10 60 -48 56 -106 -1
-27 0 -49 5 -49 4 0 7 -9 7 -20 0 -14 5 -18 18 -13 15 6 15 5 3 -10 -7 -9 -19
-17 -26 -17 -7 0 -15 -6 -18 -14 -3 -9 -20 -14 -43 -14 -20 0 -40 -6 -45 -13
-7 -11 -9 -11 -9 -1 0 7 -7 12 -16 10 -10 -2 -19 3 -21 10 -3 7 1 10 11 6 19
-7 126 15 126 26 0 5 3 17 7 27 5 15 2 14 -19 -5 -32 -29 -99 -46 -111 -29 -4
7 -6 65 -4 130 2 66 0 109 -5 97 -4 -12 -13 -18 -18 -15 -6 4 -17 1 -25 -5
-13 -11 -15 -7 -15 24 0 27 -4 36 -16 36 -14 0 -16 -8 -10 -52 3 -29 6 -155 6
-279 l0 -227 32 2 c23 1 33 -4 36 -16 3 -15 -4 -18 -35 -19 l-38 -2 40 -6 c29
-4 35 -8 22 -13 -30 -13 -64 8 -71 42 -8 45 -7 562 2 576 11 17 26 19 117 17
56 -2 93 -8 108 -18z m-2 -41 c17 -15 19 -15 34 0 18 18 35 13 35 -9 0 -8 8
-19 18 -24 9 -6 12 -11 6 -11 -6 0 -19 9 -29 20 -16 18 -20 19 -27 5 -10 -16
-38 -21 -38 -6 0 5 -9 13 -20 16 -11 4 -18 11 -15 16 9 13 16 11 36 -7z m26
-66 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m75 -85 l-10 -28 -7
28 c-4 15 -4 39 0 55 l7 27 10 -27 c6 -18 6 -38 0 -55z m-75 -5 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M4640 1953 c0 -5 17 -8 37 -8 175 -2 285 -6 281 -10 -3 -3 -76 -6
-162 -6 l-156 0 0 -294 0 -295 163 -2 162 -2 -165 -6 -165 -6 168 -2 167 -2 0
40 0 41 -115 -2 c-109 -1 -115 -2 -110 -20 4 -11 2 -19 -4 -19 -6 0 -11 5 -11
11 0 6 -7 17 -15 26 -14 14 -18 14 -34 -1 -24 -22 -31 -20 -31 10 0 27 5 28
73 8 15 -5 17 3 17 80 0 80 -10 115 -24 80 -8 -21 -8 -26 3 -20 4 3 9 -21 10
-54 1 -49 -2 -58 -14 -54 -17 7 -20 30 -5 39 19 12 10 30 -22 46 -35 17 -48
39 -24 39 9 0 18 11 22 24 4 18 15 26 42 31 21 4 42 11 49 16 7 7 14 6 18 -1
3 -5 39 -10 80 -10 41 0 77 -4 80 -10 4 -6 -28 -10 -89 -10 -57 0 -96 -4 -96
-10 0 -6 40 -10 100 -10 l100 0 0 50 0 50 -85 0 c-47 0 -85 -4 -85 -8 0 -4 32
-8 70 -7 39 1 76 -2 83 -7 6 -4 -29 -8 -80 -8 -75 0 -91 3 -87 14 7 18 -55 34
-66 16 -5 -8 -9 -7 -14 5 -4 11 -12 14 -26 10 -16 -5 -20 -2 -20 14 0 19 35
61 51 61 4 0 10 15 12 33 3 17 4 4 3 -31 -1 -84 12 -80 16 6 l3 67 113 3 112
3 0 49 0 50 -165 0 c-91 0 -165 -3 -165 -7z m240 -43 l-85 -6 85 -4 85 -4 -82
-3 c-46 -2 -83 -8 -83 -13 0 -6 -5 -10 -11 -10 -8 0 -8 5 1 15 10 13 9 15 -9
15 -11 0 -21 4 -21 8 0 5 46 9 103 9 l102 0 -85 -7z m-125 -20 c3 -5 -3 -10
-14 -10 -14 0 -19 -5 -15 -15 4 -10 0 -13 -15 -10 -41 8 -46 18 -16 31 37 16
52 17 60 4z m-75 -80 c0 -10 -5 -22 -10 -25 -13 -8 -24 20 -16 41 8 20 26 9
26 -16z m-14 -155 c-9 -9 -16 -13 -16 -8 0 12 22 34 28 28 2 -2 -3 -11 -12
-20z m274 -279 c14 -2 -22 -3 -80 -4 -58 0 -97 2 -88 6 16 7 112 5 168 -2z"/>
<path d="M5224 1933 c-3 -15 -12 -41 -19 -58 -7 -16 -25 -70 -40 -120 -15 -49
-32 -99 -37 -109 -15 -30 -68 -197 -68 -216 0 -9 -4 -20 -10 -26 -14 -14 -40
-104 -29 -104 5 0 9 6 9 13 0 8 14 11 46 9 25 -2 43 -7 39 -10 -3 -3 -21 -5
-40 -4 -19 2 -35 -2 -35 -8 0 -5 18 -10 39 -10 40 0 50 11 56 60 1 8 7 28 14
43 6 16 11 33 11 38 0 6 50 8 128 7 l127 -3 16 -47 c8 -26 19 -51 22 -55 4 -3
7 -13 7 -22 0 -12 12 -16 51 -17 45 0 49 1 33 13 -10 7 -14 13 -8 13 5 0 2 5
-6 10 -12 8 -11 10 6 10 15 0 24 -8 28 -26 4 -15 10 -24 13 -21 6 7 -50 191
-75 247 -15 32 -45 130 -59 190 -3 14 -9 27 -13 30 -3 3 -20 48 -37 100 l-30
95 -66 3 c-65 3 -66 3 -73 -25z m116 7 c23 -7 22 -8 -10 -9 -20 -1 -41 -6 -48
-13 -9 -9 -12 -9 -12 0 0 7 -4 12 -8 12 -5 0 -8 -14 -7 -32 1 -19 -5 -38 -17
-50 -10 -10 -18 -24 -19 -30 0 -7 -4 -4 -9 7 -5 13 -3 24 6 31 8 6 15 26 17
44 6 48 41 61 107 40z m33 -62 c9 -24 20 -60 26 -81 8 -33 8 -37 -5 -26 -8 6
-14 23 -14 38 0 14 -5 33 -10 41 -7 12 -10 8 -11 -15 l-1 -30 -9 33 c-11 37
-24 41 -33 10 -9 -35 -7 -66 5 -74 8 -4 9 3 4 24 -3 18 -2 33 4 37 7 4 11 -8
11 -32 1 -37 1 -37 17 -18 13 17 15 17 10 2 -4 -10 0 -34 7 -55 8 -20 17 -54
21 -74 5 -31 3 -37 -10 -32 -8 4 -18 18 -21 33 -17 73 -26 102 -31 97 -7 -6
27 -147 39 -161 4 -5 8 -19 8 -32 0 -13 5 -23 10 -23 7 0 9 15 6 38 l-6 37 11
-35 c6 -19 15 -39 20 -45 13 -13 11 -25 -3 -23 -6 2 -18 -9 -25 -24 -12 -24
-20 -28 -54 -28 -40 0 -43 4 -42 56 0 7 -17 10 -47 9 l-48 -3 3 36 c4 41 -4
61 -16 41 -4 -7 -11 -10 -16 -6 -4 4 -2 17 5 27 7 11 12 30 13 42 0 22 1 22 8
3 5 -11 6 -25 4 -31 -2 -7 0 -15 5 -18 12 -7 50 126 59 204 5 52 8 58 21 48
13 -11 15 -10 10 10 -5 18 -2 22 18 22 13 0 24 5 24 10 0 25 19 6 33 -32z
m-116 -52 c-3 -8 -13 -50 -22 -93 -9 -42 -18 -79 -19 -81 -2 -2 -4 20 -4 47 0
28 4 48 9 45 5 -3 9 7 9 23 0 39 11 73 22 73 6 0 8 -7 5 -14z m-57 -71 c-7 -8
-10 -24 -7 -36 3 -12 0 -22 -8 -25 -7 -3 -16 -21 -20 -40 -3 -19 -10 -32 -16
-29 -5 4 -6 13 0 23 5 9 12 38 16 65 4 26 11 47 16 47 5 0 9 8 9 17 0 13 3 14
12 5 8 -8 8 -15 -2 -27z m246 -129 c-4 -19 -3 -26 3 -17 13 21 20 -9 8 -36 -8
-18 -12 -19 -22 -9 -12 12 -18 83 -8 99 11 18 25 -8 19 -37z m-286 -30 c0 -2
-3 -11 -6 -20 -9 -23 -24 -20 -24 4 0 11 7 20 15 20 8 0 15 -2 15 -4z m-42
-51 c-4 -17 -1 -25 8 -25 8 0 14 -4 14 -10 0 -5 -4 -10 -9 -10 -17 0 -30 26
-24 48 7 30 16 28 11 -3z m372 -4 c0 -10 3 -21 7 -25 4 -4 6 -23 4 -42 -2 -24
2 -36 13 -40 9 -3 16 -15 16 -26 0 -11 5 -28 10 -39 7 -11 7 -19 1 -19 -5 0
-17 -3 -26 -6 -13 -5 -15 -2 -10 18 9 35 -35 88 -58 70 -13 -11 -16 -10 -22 9
-8 24 7 39 39 39 16 0 18 6 14 45 -3 24 -2 42 3 39 5 -3 9 -13 9 -23z m-245
-41 c-4 -6 -1 -17 5 -25 11 -13 5 -15 -41 -15 l-54 1 30 24 c30 25 72 35 60
15z m-112 -53 c10 -4 13 -15 10 -31 -8 -42 -22 -40 -29 4 -4 22 -10 40 -15 40
-19 0 -17 -20 6 -65 13 -27 22 -52 19 -57 -3 -4 -23 -8 -45 -8 -43 0 -49 12
-23 48 8 12 17 32 18 45 2 12 7 35 11 51 l8 29 13 -25 c7 -14 20 -28 27 -31z
m355 -104 c-2 -12 3 -17 15 -15 9 2 17 -2 17 -8 0 -5 -11 -10 -24 -10 -17 0
-25 7 -28 25 -4 17 -1 25 9 25 8 0 13 -8 11 -17z"/>
<path d="M5333 1532 c-20 -3 -21 -6 -11 -28 13 -28 41 -28 46 0 5 23 -7 33
-35 28z"/>
<path d="M5100 1350 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5630 1631 l0 -329 55 -4 55 -3 0 159 c0 138 -2 160 -17 171 -16 12
-16 14 0 38 14 22 17 51 17 162 0 133 0 135 -22 135 -21 -1 -21 -2 -5 -11 15
-9 17 -27 17 -139 0 -92 -3 -130 -12 -130 -8 0 -9 33 -5 120 3 66 3 120 -1
121 -4 0 -17 2 -29 5 l-23 4 0 -305 c0 -266 2 -304 15 -299 12 5 15 31 15 145
0 76 3 139 8 139 4 0 6 -60 6 -132 l-2 -133 9 124 c5 68 11 126 14 129 3 3 5
-54 5 -126 0 -111 -2 -132 -15 -132 -8 0 -15 -6 -15 -13 0 -7 -11 -14 -25 -15
-20 -1 -25 3 -25 21 0 12 0 152 0 312 1 283 4 316 32 299 4 -3 8 0 8 5 0 6
-13 11 -30 11 l-29 0 -1 -329z m47 -133 c-1 -79 -3 -15 -3 142 0 157 2 221 3
143 2 -79 2 -207 0 -285z m20 210 c-1 -40 -3 -8 -3 72 0 80 2 112 3 73 2 -40
2 -106 0 -145z"/>
<path d="M5950 1945 c0 -9 -7 -18 -15 -21 -8 -4 -15 -10 -15 -15 0 -5 -29 -54
-65 -109 -70 -105 -91 -151 -81 -175 9 -25 27 -17 20 9 -5 21 -3 24 15 19 21
-5 30 -23 12 -23 -6 0 -3 -12 5 -27 19 -37 18 -53 -2 -25 -8 12 -19 22 -24 22
-9 0 20 -58 79 -160 18 -30 35 -63 38 -72 3 -10 9 -18 14 -18 5 0 9 -6 9 -12
0 -26 17 -38 58 -44 27 -4 42 -2 42 5 0 6 -16 11 -35 11 -24 0 -37 6 -45 19
-16 30 -12 36 10 16 28 -25 47 -7 28 26 -18 33 -50 69 -60 69 -4 0 -6 -19 -3
-42 5 -43 5 -43 -30 27 -19 38 -35 76 -35 83 0 8 -6 12 -14 9 -10 -4 -12 0 -9
14 8 30 19 23 42 -30 11 -28 26 -53 33 -57 8 -5 9 -2 3 12 -7 17 -22 91 -28
139 -1 11 -8 30 -15 42 -13 24 -2 51 41 98 10 11 27 36 38 55 22 41 81 127
103 153 14 15 14 17 1 17 -8 0 -15 -4 -15 -10 0 -5 -5 -10 -11 -10 -5 0 -7 5
-4 10 4 6 -12 10 -39 10 -35 0 -46 -4 -46 -15z m61 -9 c12 7 0 -46 -14 -63 -5
-7 -13 -10 -18 -7 -14 8 -10 24 6 24 8 0 15 9 15 20 0 11 -4 20 -10 20 -5 0
-10 -7 -10 -15 0 -9 -9 -15 -21 -15 -12 0 -19 5 -17 13 6 17 52 39 56 27 2 -6
8 -7 13 -4z m-71 -87 c0 -5 -6 -9 -14 -9 -8 0 -31 -33 -50 -73 -37 -75 -43
-97 -27 -97 5 0 11 -8 14 -17 2 -10 8 -25 11 -33 5 -11 1 -16 -14 -16 -14 0
-19 5 -15 15 7 18 -4 53 -15 46 -4 -3 -13 2 -20 9 -9 11 -9 20 1 38 8 15 14
18 17 10 2 -7 -2 -19 -8 -27 -7 -9 -8 -15 -2 -15 14 0 23 28 16 55 -4 16 0 27
14 36 10 8 29 33 41 56 14 29 26 41 36 37 8 -4 15 -10 15 -15z m2 -56 c-16
-29 -22 -34 -27 -22 -4 10 3 26 17 42 30 34 34 27 10 -20z m-32 -43 c0 -11 -7
-20 -15 -20 -17 0 -18 2 -9 24 9 23 24 20 24 -4z m-30 -60 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M5730 1644 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M3554 1560 c12 -147 28 -255 40 -262 18 -12 86 -9 86 3 0 8 -14 10
-40 6 -22 -3 -40 -1 -40 3 0 5 17 11 38 13 35 4 35 4 -5 3 l-42 -1 -5 50 c-3
27 -8 82 -11 120 -8 98 -14 137 -21 144 -4 3 -4 -32 0 -79z"/>
<path d="M5923 1530 c-1 -45 18 -90 38 -90 8 0 8 4 2 13 -13 17 -34 67 -28 67
2 0 12 -15 21 -32 10 -19 19 -27 22 -20 2 7 -7 27 -21 45 -13 18 -26 39 -29
47 -3 8 -5 -5 -5 -30z"/>
<path d="M5980 1444 c0 -11 45 -84 53 -84 11 0 9 5 -14 50 -18 34 -39 53 -39
34z"/>
<path d="M7022 1403 c-35 -30 -37 -66 -7 -99 15 -16 35 -27 44 -26 34 7 82 92
51 92 -5 0 -10 7 -10 15 0 14 -29 35 -48 35 -5 0 -19 -8 -30 -17z m63 -20 c11
-24 6 -55 -10 -72 -20 -20 -65 10 -65 44 0 40 60 62 75 28z"/>
<path d="M7031 1369 c-6 -11 -9 -24 -6 -28 9 -15 45 0 45 19 0 26 -24 31 -39
9z m19 -9 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z"/>
<path d="M3752 1370 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M6039 1341 c11 -7 9 -10 -10 -14 -23 -4 -23 -4 -1 -6 12 0 22 -8 22
-16 0 -8 7 -15 15 -15 8 0 15 3 15 8 0 14 -31 52 -43 52 -8 0 -7 -4 2 -9z"/>
<path d="M4640 1300 c0 -6 60 -10 159 -10 96 0 162 4 166 10 4 7 -52 10 -159
10 -104 0 -166 -4 -166 -10z"/>
</g>
</svg>

      
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
